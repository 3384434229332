define('admin/initializers/ajax-configuration', ['exports', 'jquery', 'ember', 'admin/utils/system-log', 'admin/services/auth-manager'], function (exports, jQuery, Ember, SystemLog, AuthManager) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    //  application.deferReadiness();
    jQuery['default'].ajaxSetup({
      dataType: "json",
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true
    });
    jQuery['default'](window).ajaxSend((function () {
      application.set("isLoading", application.get("isLoading") + 1);
    }).bind(application));
    jQuery['default'](window).ajaxComplete((function () {
      application.set("isLoading", application.get("isLoading") - 1);
    }).bind(application));
  }

  exports['default'] = {
    name: "ajax-configuration",
    initialize: initialize
  };

});