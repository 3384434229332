define('admin/components/field-pinpicker', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    classNames: ["ui", "inline", "dropdown"],
    key: null,
    model: null,
    name: null,
    addEmpty: false,

    didInsertElement: function didInsertElement() {
      this.$().dropdown("set selected", this.get("model." + this.get("name")));
      this.$().dropdown({
        //action: "combo",
        onChange: (function (value) {
          //, text, $choice
          var val = typeof value === "undefined" ? this.get("model." + this.get("name")) : value;
          this.get("model").set(this.get("name"), val);
        }).bind(this) });
    }
  });

});