define('admin/models/shop/salegroup/delivery', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    delivery: DS['default'].belongsTo("shop/delivery", { async: false }),
    salegroup: DS['default'].belongsTo("shop/salegroup", { async: false }),
    isDefault: DS['default'].attr("boolean")
  });

});