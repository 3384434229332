define('admin/components/field-search', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["ui", "item", "category", "search"],
    tagName: "div",
    modelName: null,
    readonly: false,
    isLoading: false,
    minLength: 3,
    model: [],
    search: (function () {
      return this.get("model");
    }).property("model"),
    didInsertElement: function didInsertElement() {
      //this.$().data('modelId', this.get('model.id'));
      //
      this.$("input[name=search]").on("keyup", (function (event) {
        var term = jQuery['default'](event.currentTarget).val();
        if (term.length >= this.get("minLength")) {
          this.load(term);
        }
      }).bind(this)).on("blur", (function (event) {
        this.$(".results").fadeOut();
      }).bind(this));
    },
    onLoading: (function () {
      if (this.get("isLoading")) {
        this.$(".icon").removeClass("search").addClass("notched circle loading");
      } else {
        this.$(".icon").removeClass("notched circle loading").addClass("search");
      }
    }).observes("isLoading"),
    load: function load(term) {
      this.set("isLoading", true);
      var store = this.get("targetObject.store");
      store.adapterFor(this.get("modelName")).cancelQuery();
      store.query(this.get("modelName"), { filter: { term: term } }).then((function (data) {
        this.set("model", []);
        this.set("model", data.toArray()); //data.content
        this.set("meta", data.meta);
        this.$(".results").fadeIn();
        this.set("isLoading", false);
      }).bind(this), (function () {
        this.set("isLoading", false);
      }).bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().remove();
    } });

});