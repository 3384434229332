define('admin/utils/application', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].RESTSerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(modelName) {
      if (modelName === "delivery" || modelName === "deliveries") {
        return "shop/delivery";
      }
      return this._super(modelName);
    }
  });

});