define('admin/utils/system-log', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    title: null,
    message: null,
    type: "information", //notification, warning, error, success
    date: null,
    color: (function () {
      switch (this.get("type")) {
        case "information":
          return "blue";
        case "warning":
          return "orange";
        case "notification":
          return "teal";
        case "error":
          return "red";
        case "success":
          return "green";
      }
    }).property("type"),
    icon: (function () {
      switch (this.get("type")) {
        case "information":
          return "fa-info-circle";
        case "warning":
          return "fa-exclamation-circle";
        case "notification":
          return "fa-info";
        case "error":
          return "fa-exclamation-triangle";
        case "success":
          return "fa-bell";
      }
    }).property("type"),
    init: function init() {
      this._super();
      this.set("date", moment());
    }
  });

});