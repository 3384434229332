define('admin/controllers/map', ['exports', 'ember', 'jquery', 'admin/utils/openlayers/layer'], function (exports, Ember, jQuery, Layer) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    addDefaultLayer: true,
    // kolekcja warstw
    layerCollection: [],
    init: function init() {
      // załadowanie openlayers
      this.get("openlayers").load();
      this._super();
    },
    searchEnabled: true,

    beforeMapInit: null,

    destroyMap: function destroyMap() {
      this.set("mapa", null);
    },
    mapInit: function mapInit(target, strLonLat) {
      if (this.get("mapa")) {
        return;
      }
      if (typeof strLonLat === "undefined") {
        var strLonLat = [0, 0];
      }
      this.set("layerCollection", []);
      if (this.get("addDefaultLayer")) {
        this.get("layerCollection").pushObject(Layer['default'].create({
          layer: new ol.layer.Tile({
            name: "OpenStreetMap",
            source: new ol.source.OSM({
              attributions: [ol.source.OSM.ATTRIBUTION],
              crossOrigin: null,
              url: location.protocol + "//{a-b}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            })
          })
        }));
      }

      if (typeof this.beforeMapInit === "function") {
        this.beforeMapInit();
      }

      var layers = [];
      this.get("layerCollection").forEach(function (layer) {
        layers.push(layer.get("layer"));
      });
      this.set("mapa", new ol.Map({
        target: target,
        layers: layers,
        view: new ol.View({
          maxZoom: 18,
          center: strLonLat,
          zoom: 6 })
      }));
    },
    layers: (function () {
      return this.get("layerCollection");
    }).property("layerCollection"),
    mapa: null,
    openlayersInitialized: (function () {
      return this.get("openlayers.isLoaded");
    }).property("openlayers.isLoaded"),
    actions: {
      goToLonLat: function goToLonLat(lon, lat) {
        var lonlat = ol.proj.transform([parseFloat(lon), parseFloat(lat)], "EPSG:4326", "EPSG:3857");
        var size = this.get("mapa").getSize();
        this.get("mapa").getView().centerOn(lonlat, size, [size[0] / 2, size[1] / 2]);
      },
      goToBBox: function goToBBox(bbox) {
        var extent = ol.proj.transformExtent([parseFloat(bbox[0]), parseFloat(bbox[1]), parseFloat(bbox[2]), parseFloat(bbox[3])], "EPSG:4326", "EPSG:3857");
        var size = this.get("mapa").getSize();
        extent, bbox;
        this.get("mapa").getView().fit([extent[0], extent[2], extent[1], extent[3]], size);
      },
      goToFeature: function goToFeature(feature) {
        feature.getGeometry().transform("EPSG:4326", "EPSG:3857");
        var size = this.get("mapa").getSize();
        this.get("mapa").getView().fit(feature.getGeometry().getExtent(), size);
        if (screen.height >= 900 && screen.height <= 1280) {
          this.get("mapa").getView().setZoom(this.get("mapa").getView().getZoom() - 1);
        }
        Ember['default'].run.later((function () {
          this.set("isLoading", false);
        }).bind(this), 2000);
      }
    }

  });

  //          projection: 'EPSG:4326'
  //          projection: 'EPSG:3857'

});