define('admin/components/system-information', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["item"],
    application: null,
    didInsertElement: function didInsertElement() {},
    messages: (function () {
      return this.get("application.messages").sortBy("date");
    }).property("application.messages.length")
  });

  //    this.$().popup({
  //      hoverable: true,
  //      position : 'bottom left',
  //      popup: this.$().find('.popup'),
  //      prefer: 'opposite',
  //      delay: {
  //        show: 300,
  //        hide: 800
  //      }
  //    });

});