define('admin/components/ui-amcharts', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        isLoading: false,
        noData: false,
        chart: null,
        //    init: function () {
        //      this.get('amcharts').load();
        //      this._super();
        //    },
        classNames: ["ui", "segment"],
        didRender: function didRender() {
            this.$().css({
                width: "100%",
                height: "500px",
                padding: "0px"
            });
            this.onChart();
        },
        onChart: (function () {
            if (this.get("chart")) {
                if (this.get("chart").dataProvider.length > 0) {
                    this.set("noData", false);
                } else {
                    this.set("noData", true);
                }
                this.get("chart").write(this.$(".chart")[0]);
            }
        }).observes("chart")

        //    onLibLoaded: function () {
        //      if (this.get('amcharts.isLoaded')) {
        //           
        ////var chart = new AmCharts.AmSerialChart();
        ////chart.dataProvider = chartData;
        ////chart.categoryField = "country";
        ////var graph = new AmCharts.AmGraph();
        ////graph.valueField = "visits";
        ////graph.type = "column";
        ////chart.addGraph(graph);
        ////var categoryAxis = chart.categoryAxis;
        ////categoryAxis.labelRotation = 45;
        ////chart.write(this.$().context);
        //      }
        //    }.observes('amcharts.isLoaded')
    });

});