define('admin/controllers/technika/station/edit', ['exports', 'admin/controllers/edit', 'ember-data', 'ember'], function (exports, Edit, DS, Ember) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/station",
    redirect: "technika.station.edit",
    redirectList: "technika.station.index",
    allowDelete: null,
    timezoneFilter: {
      "filter[category]": "timezone"
    },
    dataTypeFilter: {
      "filter[category]": "dataType"
    },
    mapController: Ember['default'].inject.controller("map"),
    companyList: Ember['default'].inject.controller("admin/company/list"),
    maintenanceList: Ember['default'].inject.controller("technika/station/maintenance/list"),
    maintenanceEdit: Ember['default'].inject.controller("technika/station/maintenance/edit"),
    maintenanceButtons: [{
      title: "app.save",
      action: "saveMaintenance"
    }],
    //needs: ['admin/company/list'],
    companyModalVisible: false,
    sensorList: Ember['default'].inject.controller("technika/station/sensorList"),
    sensorEdit: Ember['default'].inject.controller("technika/sensor/edit"),
    sensorAddModalVisible: false,
    sensorAddButtons: [{
      title: "app.save",
      action: "sensorSave"
    }],
    deleted: [],
    save: function save(redirect, callback) {
      this._super(redirect, (function () {
        this.set("isLoading", true);
        var arr = [];
        this.get("model.images").forEach((function (el) {
          if (!el.get("id")) {
            el.set("object", "\\Cyfra\\Technika\\StationBundle\\Model\\Station");
            el.set("objectId", this.get("model.id"));
            arr.push(el.get("_internalModel"));
          }
        }).bind(this));
        this.get("deleted").forEach(function (el) {
          arr.push(el.get("_internalModel"));
        });
        var ra = DS['default'].RecordArray.create({ content: Ember['default'].A(arr) });
        ra.save().then((function () {
          this.set("isLoading", false);
          this.set("deleted", []);
          if (typeof this.get("afterSave") !== "undefined") {
            var transit = this.get("afterSave");
            this.set("afterSave", undefined);
            this.transitionToRoute(transit);
          }
        }).bind(this), (function () {
          this.set("isLoading", false);
        }).bind(this));
      }).bind(this));
    },
    //Ember.inject.controller()
    init: function init() {
      //(this.get('authManager').get('isAuthenticated'));
      //      this.set('control', this.controllerFor('admin/company/list'))
      this._super();

      /*
      this.get('navigation').pushObject({
        route: 'technika.station.index',
        text: "technika.station.navigation.list"
      });
      this.get('navigation').pushObject({
        route: 'technika.station.map',
        text: "technika.station.navigation.map"
      });
      if (this.get('authManager').get('ROLE_OWNER')) {
        this.get('navigation').pushObject({
          route: 'technika.station.new',
          text: "technika.station.navigation.new"
        });
      }
      */
      if (this.get("authManager").get("ROLE_TECHNIKA_ADMIN")) {
        this.get("operations").pushObject({
          title: "app.saveAndLeave",
          action: "saveAndList"
        });
        this.get("operations").pushObject({
          title: "app.cancel",
          action: "cancel"
        });
      }
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("operations").pushObject({
          title: "app.saveAndLeave",
          action: "saveAndList"
        });
        this.get("operations").pushObject({
          title: "technika.station.edit.actions.clone",
          action: "clone"
        });
        this.get("operations").pushObject({
          title: "technika.station.edit.actions.add.sensor",
          action: "addSensor"
        });
        this.get("operations").pushObject({
          title: "technika.station.edit.actions.add.maintenance",
          action: "addMaintenance"
        });
        this.get("operations").pushObject({
          title: "app.delete",
          action: "remove"
        });
        this.get("operations").pushObject({
          title: "app.cancel",
          action: "cancel"
        });
      }
    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "technika.station.new.title";
      } else {
        return "technika.station.edit.title";
      }
    }).property("model"),
    currencyMask: (function () {
      //(this.get('model.locale'));
      //(NumberFormat.locale[this.get('model.locale')].CURRENCY_PATTERN);
      if (this.get("model.currencyLocale")) {
        return NumberFormat.locale[this.get("model.currencyLocale")].CURRENCY_PATTERN2;
      }"admin/company/list";
    }).property("model.locale"),
    sortProperties: ["date"],
    sortAscending: false,
    sortedMaintenances: (function () {
      var content;
      content = this.get("model.maintenances") || [];
      return Ember['default'].ArrayProxy.extend(Ember['default'].SortableMixin, {
        content: content.toArray(),
        sortProperties: this.get("sortProperties"),
        sortAscending: this.get("sortAscending")
      }).create();
    }).property("model.maintenances.[]", "sortProperties", "sortAscending"),

    onModel: (function () {}).observes("model", "amcharts.isLoaded", "isLoading"),
    actions: {
      saveAndList: function saveAndList() {
        this.set("clearWarnings", true);
        this.set("afterSave", "technika.station.index");
        this.save();
      },
      showCompanyModal: function showCompanyModal() {
        var controller = this.get("companyList");
        controller.setSingleselect(true);
        controller.set("singleselectType", "company");
        controller.set("singleselectTarget", this);
        controller.load();
        this.set("companyModalVisible", true);
      },
      clearCompany: function clearCompany() {
        this.get("model").set("company", null);
      },
      singleselect: function singleselect(model, type) {
        this.get("model").set("company", model);
        this.set("companyModalVisible", false);
      },
      addMaintenance: function addMaintenance() {
        var controller = this.get("maintenanceEdit");
        controller.load(0).then((function () {
          controller.get("model").set("station", this.get("model.id"));
        }).bind(this));
        this.set("maintenanceModalVisible", true);
      },
      saveMaintenance: function saveMaintenance() {
        var controller = this.get("maintenanceEdit");
        controller.save(false, (function (state) {
          if (state !== "error") {
            this.set("maintenanceModalVisible", false);
            this.load(this.get("model.id"));
          }
        }).bind(this));
      },
      editMaintenance: function editMaintenance(a) {
        var controller = this.get("maintenanceEdit");
        controller.load(a.param.get("id"));
        this.set("maintenanceModalVisible", true);
      },
      removeMaintenance: function removeMaintenance(rec) {
        var controller = this.get("maintenanceList");
        controller.send("remove", rec);
        controller.load();
      },
      activateTab: function activateTab(options) {
        if (options.tab === "sensors") {
          if (this.get("model.sensors") && this.get("model.sensors").length <= 0) {
            this.get("sensorList").get("filters").findBy("name", "id").set("value", [0]);
          } else {
            this.get("sensorList").get("filters").findBy("name", "id").set("value", this.get("model.sensors"));
          }
          this.get("sensorList").load();
        }
      },
      addSensor: function addSensor() {
        var controller = this.get("sensorEdit");
        controller.load(0).then((function () {
          controller.get("model").set("station", this.get("model"));
          controller.set("isModal", true);
        }).bind(this));
        this.set("sensorAddModalVisible", true);
      },
      removeSensor: function removeSensor(rec) {
        var controller = this.get("sensorList");
        controller.send("remove", rec);
        controller.load();
      },
      sensorSave: function sensorSave() {
        var controller = this.get("sensorEdit");
        controller.save(false, (function (state, model) {
          if (state !== "error") {
            this.set("sensorAddModalVisible", false);
            this.get("model").get("sensors").push(model.get("id"));
          }
        }).bind(this));
      },
      editSensor: function editSensor(a) {
        var controller = this.get("sensorEdit");
        controller.set("isModal", true);
        controller.load(a.param.get("id"));
        this.set("sensorAddModalVisible", true);
      },
      cancel: function cancel() {
        this.get("store").unloadAll("technika.sensor.warning");
        this.transitionToRoute("technika.station.index");
      },
      clone: function clone() {
        var url = this.get("store").adapterFor(this.get("modelName")).buildURL(this.get("modelName"), this.get("model").get("id"), this.get("model"), null, null, "clone");
        "clone", url;
        this.set("isLoading", true);
        jQuery.ajax({
          url: url,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          error: (function (response) {
            this.set("isLoading", false);
            if (response.responseJSON) {
              //(response.responseJSON);
              this.set("formErrors", response.responseJSON.fields);
              this.get("application").error("app.error.form.save", response.responseJSON.message);
            }
          }).bind(this),
          success: (function (data) {
            //data, status, response
            this.get("application").__container__.lookup("route:application").transitionTo("technika.station.edit", data);
            this.set("isLoading", false);
          }).bind(this)
        });
      }
    }
  });

});