define('admin/instance-initializers/auth-manager', ['exports', 'jquery', 'ember', 'admin/utils/system-log'], function (exports, jQuery, Ember, SystemLog) {

  'use strict';

  exports.initialize = initialize;

  function initialize(app) {}

  ;
  exports['default'] = {
    name: "authManager",
    after: "i18n",
    initialize: initialize
  };

  //Admin.deferReadiness();
  //  app.container.lookup('service:authManager').set('application', Admin);
  //  Admin.set('authManager', app.container.lookup('service:authManager'));
  //  app.container.lookup('service:authManager').loadSession().then(function () {
  //          jQuery('body').removeClass('splash');
  //          jQuery('#splash').remove();
  //        });
  //  application.container.lookup('service:websocket').connect();
  //  app.inject('model', 'i18n', 'service:i18n')
  //application.container.lookup('service:i18n').setLocale("pl");

});