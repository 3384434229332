define('admin/adapters/shop/order', ['exports', 'admin/adapters/application'], function (exports, ApplicationAdapter) {

	'use strict';

	exports['default'] = ApplicationAdapter['default'].extend({});

	//namespace: "system"
	//    headers: {
	//      "Is-Cashbox-Application": true
	//    }

});