define('admin/adapters/mysql', ['exports', 'admin/adapters/application'], function (exports, ApplicationAdapter) {

    'use strict';

    exports['default'] = ApplicationAdapter['default'].extend({
        buildURL: function buildURL(modelName, id, snapshot, requestType, query, operation) {
            var host = this.get("host");
            var prefix = this.urlPrefix();
            return prefix + "/system/mysql";
        }
    });

});