define('admin/models/shop/cart/item', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    quantity: DS['default'].attr("number"),
    product: DS['default'].belongsTo("shop/product", { async: true }),
    cart: DS['default'].belongsTo("shop/cart", { async: false }),
    worth: DS['default'].attr("string"),
    amount: DS['default'].attr("string"),
    locale: DS['default'].attr("string"),
    currency: DS['default'].attr("string") });

});