define('admin/routes/technika/station/service', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        setupController: function setupController(controller, model) {
            controller.load();
        }
    });

});