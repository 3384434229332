define('admin/components/nav-accordion', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: [".ui", ".accordion"],
    title: null,
    ident: null,
    data: null,
    parent: null,
    itemsChecked: null,
    store: Ember['default'].inject.service("store"),
    hevent: Ember['default'].inject.service("hevent"),
    init: function init() {
      this._super();
      this.get("hevent").on("station-details", (function () {
        Ember['default'].run("afterRender", (function () {
          if (this.get("data")) {
            this.get("data").forEach((function (item) {
              item.set("active", "");
            }).bind(this));
          }
        }).bind(this));
      }).bind(this));
    },
    didRender: function didRender() {
      var _this = this;

      if (this.get("data")) {
        if (document.URL.indexOf("technika/sensor/details") != -1) {
          var pathArray = window.location.href.split("/");
          var id = pathArray[pathArray.length - 1];
          var sensor = this.get("store").findRecord("technika/sensor", id).then((function (rec) {
            this.get("data").forEach((function (item) {
              //(id == rec.get('id'),id,rec.get('id'))
              if (item.get("id") == rec.get("station.id")) {
                item.set("active", "active");
              } else {
                item.set("active", "");
              }
            }).bind(this));
          }).bind(this));
        } else if (document.URL.indexOf("technika/station/details") != -1) {
          (function () {
            var pathArray = window.location.href.split("/");
            var id = pathArray[pathArray.length - 1];
            _this.get("data").forEach(function (item) {
              if (item.get("id") == id) {
                item.set("active", "active");
              } else {
                item.set("active", "");
              }
            });
          })();
        } else {
          this.get("data").forEach(function (item) {
            item.set("active", "");
          });
        }
      }
      this.$(".ui.accordion").accordion({
        exclusive: true,
        selector: {
          trigger: ".title .icon"
        }
      });
    },
    actions: {

      activate: function activate(data, stations) {
        this.$(".ui.accordion ul").each((function (index, data) {
          $(data).removeClass("active");
        }).bind(this));
        if (data.get("active") == "active") {
          if (this.get("parent") == "map") {
            this.send("clearOnMap", data);
          }
        } else {
          data.set("active", "active");
          if (this.get("parent") == "map") {
            this.send("showOnMap", data);
          }
        }
      }
    }
  });

});