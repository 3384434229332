define('admin/serializers/technika/station', ['exports', 'admin/serializers/application'], function (exports, ApplicationSerializer) {

	'use strict';

	exports['default'] = ApplicationSerializer['default'].extend({});

	//    serializeHasMany: function(record, json, relationship) {
	//      if (relationship && relationship.key === 'images' && record.hasMany('images')) {
	//        record.hasMany('images').forEach(function (rec,i) {
	//          json['images_' + i] = rec.record.get('file');
	//        });
	//      } else {
	//        return this._super(record, json, relationship);
	//      }
	//    }

});