define('admin/views/account/register', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    blocked: true,
    captcha: null,
    didInsertElement: function didInsertElement() {
      this._super();
      grecaptcha.render("captcha", {
        sitekey: this.get("controller.application.systemInformation.configuration.re-captcha-key"),
        callback: (function (response) {
          this.set("captcha", response);
          this.set("blocked", false);
        }).bind(this) });
    },
    actions: {
      register: function register() {
        if (this.get("blocked") === false) {
          var data = this.$().find("form").serializeArray();
          data["g-recaptcha-response"] = this.get("captcha");
          this.get("controller").register(data);
        }
      }
    }
  });

});