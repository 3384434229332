define('admin/controllers/technika/sensor/type/edit', ['exports', 'admin/controllers/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/sensor/type",
    redirect: "technika.sensor.type.edit",
    redirectList: "technika.sensor.type.index",
    allowDelete: false,
    init: function init() {
      //(this.get('authManager').get('isAuthenticated'));
      //      this.set('control', this.controllerFor('admin/company/list'))
      this._super();
      this.get("navigation").pushObject({
        route: "technika.sensor.index",
        text: "technika.sensor.navigation.list"
      });
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("navigation").pushObject({
          route: "technika.sensor.new",
          text: "technika.sensor.navigation.new"
        });
      }
    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "technika.sensor.type.new.title";
      } else {
        return "technika.sensor.type.edit.title";
      }
    }).property("model"),
    actions: {}
  });

});