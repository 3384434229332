define('admin/tests/unit/utils/system-log-test', ['admin/utils/system-log', 'qunit'], function (systemLog, qunit) {

  'use strict';

  qunit.module("systemLog");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = systemLog['default']();
    assert.ok(result);
  });

});