define('admin/controllers/application', ['exports', 'ember', 'admin/utils/navigation/container', 'admin/utils/navigation/item'], function (exports, Ember, NavMenuContainer, NavMenuItem) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    menu_left: (function () {
      var res = [];
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "menu.technika.stations",
        icon: "fa fa-cube",
        route: "technika.station",
        roles: ["ROLE_TECHNIKA_STATION", "ROLE_TECHNIKA_USER", "ROLE_TECHNIKA_ADMIN"]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "menu.technika.sensors",
        icon: "fa fa-tachometer",
        route: "technika.sensor",
        roles: ["ROLE_TECHNIKA_STATION", "ROLE_TECHNIKA_USER", "ROLE_TECHNIKA_ADMIN"]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "menu.technika.import",
        icon: "fa fa-compress",
        route: "technika.import",
        roles: ["ROLE_TECHNIKA_STATION", "ROLE_TECHNIKA_ADMIN"]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "menu.technika.raport",
        icon: "fa fa-file-text-o",
        route: "technika.raport",
        param: "new",
        roles: ["ROLE_TECHNIKA_STATION", "ROLE_TECHNIKA_USER", "ROLE_TECHNIKA_ADMIN"]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "technika.station.navigation.map",
        icon: "fa fa-map",
        route: "technika.station.map",
        roles: ["ROLE_TECHNIKA_STATION", "ROLE_TECHNIKA_USER", "ROLE_TECHNIKA_ADMIN"]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "technika.menu.contact",
        icon: "mail icon",
        route: "technika.contact",
        roles: ["ROLE_TECHNIKA_USER", "ROLE_TECHNIKA_ADMIN"]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "menu.admin.usermanager",
        toRight: true,
        roles: ["ROLE_USER_MANAGER", "ROLE_SUPER_ADMIN", "ROLE_TECHNIKA_ADMIN"],
        items: [NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.admin.user",
          icon: "fa fa-user",
          route: "admin.user.index",
          roles: ["ROLE_USER_MANAGER", "ROLE_TECHNIKA_ADMIN"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.admin.company",
          icon: "fa fa-building",
          route: "admin.company.index",
          roles: ["ROLE_USER_MANAGER"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.admin.group",
          icon: "fa fa-users",
          route: "admin.group.index",
          roles: ["ROLE_USER_MANAGER"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.admin.role",
          icon: "fa fa-user-secret",
          route: "admin.role.index",
          roles: ["ROLE_SUPER_ADMIN"]
        })]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "technika.station.export",
        items: [NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "technika.station.export",
          icon: "fa fa-book",
          route: "technika.station.export",
          roles: ["ROLE_ADMIN"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "technika.station.files",
          icon: "fa fa-file-text-o",
          route: "technika.station.files",
          roles: ["ROLE_ADMIN"]
        })]
      }));
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: "menu.configuration",
        toRight: true,
        roles: ["ROLE_ADMIN"],
        items: [NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.configuration.dictionary",
          icon: "fa fa-book",
          route: "admin.dictionary",
          roles: ["ROLE_ADMIN"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.configuration.task",
          icon: "fa fa-tasks",
          route: "admin.task",
          roles: ["ROLE_ADMIN"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.configuration.systeminfo",
          icon: "fa fa-info-circle",
          route: "admin.systeminfo",
          roles: ["ROLE_ADMIN"]
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.configuration.systeminfo.mysql",
          icon: "fa fa-info-circle",
          route: "admin.systeminfo.mysql",
          roles: ["ROLE_ADMIN"]
        })]
      }));
      return res;
    }).property("application.authManager.isAuthenticated"),
    menu_right: (function () {
      var res = [];
      res.pushObject(NavMenuContainer['default'].create({
        application: this.get("application"),
        displayText: this.get("application.authManager.user.email"),
        translate: false,
        toRight: true,
        items: [NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "menu.account.edit",
          icon: "fa fa-gear",
          route: "account.edit"
        }), NavMenuItem['default'].create({
          application: this.get("application"),
          displayText: "nav.account.logout",
          icon: "fa fa-lock",
          action: "logout",
          logout: function logout() {
            //(this, this.get('application'));
            this.get("application.authManager").logout().then(function () {
              window.location.reload();
            });
          }
        })]
      }));
      return res;
    }).property("application.authManager.isAuthenticated") });

});