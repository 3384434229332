define('admin/components/field-dictionary', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    classNames: ["ui", "selection", "dropdown"],
    key: null,
    model: null,
    name: null,
    addEmpty: false,
    values: (function () {
      if (this.get("i18n").t(this.get("key"))) {
        var res = this.get("i18n").t(this.get("key"));
        if (this.get("addEmpty") === true) {
          res.unshiftObject({ key: null, value: this.get("i18n").t("app.select.continue") });
        }
        return res;
      }
      return null;
    }).property("key"),
    setValue: (function () {
      var res = this.get("i18n").t(this.get("key"));
      //      (typeof res);
      if (typeof res === "object") {
        res = res.findBy("key", this.get("model." + this.get("name")));
        if (res) {
          this.set("selected", res.value);
          //this.$().dropdown('set value', this.get('model.' + this.get('name')));
          this.$().dropdown("set selected", this.get("model." + this.get("name")));
        }
      }
    }).observes("key", "model"),
    disabled: (function () {
      return this.get("readonly") == true ? "disabled" : "";
    }).property("readolny"),
    didInsertElement: function didInsertElement() {
      this.$().dropdown({
        //action: "combo",
        onChange: (function (value) {
          //, text, $choice
          this.get("model").set(this.get("name"), value);
        }).bind(this) });
    }
  });

});