define('admin/routes/list', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    templateName: "list",
    config: {
      into: null,
      controller: null,
      hasPager: true,
      hasFilter: true,
      hasNavigation: true,
      hasAccordionNavigation: true
    },
    renderTemplate: function renderTemplate() {
      this._super();
      //      (this.get('config'));
      var controller = this.controllerFor(this.get("config.controller"));
      controller.setList();
      controller.load();
      if (this.get("config.hasPager")) {
        this.render("pager", {
          outlet: "pager",
          into: this.get("config.into"),
          controller: controller
        });
      }
      this.render("buttons", {
        outlet: "left2",
        into: this.get("config.into"),
        controller: controller
      });
      if (this.get("config.hasFilter")) {
        this.render("listFilter", {
          outlet: "left3",
          into: this.get("config.into"),
          controller: controller
        });
      }
      if (this.get("config.hasNavigation")) {
        this.render("navigation", {
          outlet: "left1",
          into: this.get("config.into"),
          controller: controller
        });
      }
      if (this.get("config.hasAccoridionNav")) {
        this.render("accordion", {
          outlet: "left4",
          into: this.get("config.into"),
          controller: controller
        });
      }
      this.render(this.get("config.controller"), {
        outlet: "list",
        into: this.get("config.into"),
        controller: controller
      });
    }
  });

});