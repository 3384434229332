define('admin/serializers/file', ['exports', 'admin/serializers/application'], function (exports, ApplicationSerializer) {

  'use strict';

  exports['default'] = ApplicationSerializer['default'].extend({
    attrs: {
      src: { serialize: false },
      uri: { serialize: false } }
  });

});