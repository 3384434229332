define('admin/routes/technika/station/export', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "technika/station/export",
      controller: "technika/station/export",
      hasPager: true,
      hasFilter: true,
      hasNavigation: true,
      hasAccordionNavigation: true
    }
  });

});