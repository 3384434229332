define('admin/routes/admin/tax/new', ['exports', 'admin/routes/new'], function (exports, New) {

  'use strict';

  exports['default'] = New['default'].extend({
    config: {
      into: "admin/tax/new",
      controller: "admin/tax/edit" }
  });

});