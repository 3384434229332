define('admin/controllers/technika/sensor/edit', ['exports', 'admin/controllers/edit', 'admin/utils/filter-element', 'jquery'], function (exports, Edit, FilterElement, jQuery) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/sensor",
    redirect: "technika.sensor.edit",
    redirectList: "technika.sensor.index",
    allowDelete: false,
    mode: "demo",
    isModal: false,
    addFileTypeModalVisible: false,
    addFileTypeButtons: [{
      title: "app.save",
      action: "saveNewType"
    }],
    sensorTypeEdit: Ember.inject.controller("technika/sensor/type/edit"),
    manufacturerFilter: {
      "filter[category]": "manufacturer"
    },
    unitFilter: {
      "filter[category]": "unitOfMeasure"
    },
    typeFilter: {
      "filter[category]": "sensorType"
    },
    stationList: Ember.inject.controller("technika/station/list"),
    stationModalVisible: false,
    init: function init() {
      //(this.get('authManager').get('isAuthenticated'));
      //      this.set('control', this.controllerFor('admin/company/list'))
      this.set("24h", "");
      this.set("7d", "blue");
      this.set("14d", "");
      this.set("30d", "");
      this.set("365d", "");
      this.set("chart3", null);
      this.set("chart1", null);
      this.set("chart2", null);
      this.set("chart3", null);
      this.set("chartdata", []);
      this.get("amcharts").load();
      this._super();
      this.get("navigation").pushObject({
        route: "technika.sensor.index",
        text: "technika.sensor.navigation.list"
      });
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("navigation").pushObject({
          route: "technika.sensor.new",
          text: "technika.sensor.navigation.new"
        });
      }
      this.get("operations").pushObject({
        title: "app.saveAndLeave",
        action: "saveAndList"
      });
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("operations").pushObject({
          title: "technika.sensor.navigation.new.type",
          action: "addNewType"
        });
      }
      this.get("operations").pushObject({
        title: "app.cancel",
        action: "cancel"
      });
    },
    deleted: [],
    save: function save(redirect, callback) {
      this._super(redirect, (function () {
        this.set("isLoading", true);
        var arr = [];
        this.get("model.images").forEach((function (el) {
          if (!el.get("id")) {
            el.set("object", "\\Cyfra\\Technika\\SensorBundle\\Model\\Sensor");
            el.set("objectId", this.get("model.id"));
            arr.push(el.get("_internalModel"));
          }
        }).bind(this));
        this.get("deleted").forEach(function (el) {
          arr.push(el.get("_internalModel"));
        });
        var ra = DS.RecordArray.create({ content: Ember.A(arr) });
        var me = this;
        ra.save().then((function () {
          this.set("isLoading", false);
          this.set("deleted", []);
        }).bind(this), (function (a) {
          me.set("isLoading", false);
        }).bind(this));
      }).bind(this));
    },
    chart1: null,
    chart2: null,
    chart3: null,
    chart1DataLoading: false,
    chart2DataLoading: false,
    chart3DataLoading: false,
    loadCurrentData: function loadCurrentData(isLoading, id) {
      this.set("chartdata", []);
      this.set(isLoading, true);
      return jQuery['default'].ajax({
        url: this.get("application").get("server") + "/technika/sensor/" + id + "/data/current.json",
        complete: (function () {
          this.set(isLoading, false);
        }).bind(this)
      });
    },
    loadHourlyData: function loadHourlyData(isLoading, id, maxTime, minTime) {
      this.set(isLoading, true);
      return jQuery['default'].ajax({
        url: this.get("application").get("server") + "/technika/sensor/" + id + "/data/demo.json",
        data: {
          from: minTime,
          to: maxTime },
        complete: (function () {
          this.set(isLoading, false);
        }).bind(this)
      });
    },
    loadDailyData: function loadDailyData(isLoading, id) {},
    loadMonthlyData: function loadMonthlyData(isLoading, id) {},
    onModel: (function () {
      if (this.get("model") && this.get("amcharts.isLoaded") && this.get("isLoading") === false) {
        if (this.get("isWaterLevelChart")) {
          var chart1 = this.get("amcharts").waterLevelLine(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), "hh", this.get("mode"), this.get("model"), this.get("model"));
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            chart1.dataProvider = data.data;
            this.set("chart1", chart1);
            this.set("table", data.data);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
          var chart2 = this.get("amcharts").waterLevelCylinder(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), this.get("mode"), this.get("model"));
          this.loadCurrentData("chart2DataLoading", this.get("model.id")).then((function (data) {
            chart2.dataProvider = [{
              category: this.get("i18n").t("technika.sensor.edit.chart.waterlevel"),
              value: data.data,
              minValue: this.get("model.minValue"),
              maxValue: this.get("model.maxValue") - data.data
            }];
            this.set("chart2", chart2);
          }).bind(this));
        } else if (this.get("isWaterTemperatureChart")) {
          var chart3 = this.get("amcharts").waterTemperatureLine(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), "hh", this.get("mode"), this.get("model"));
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            chart3.dataProvider = data.data;
            this.set("chart3", chart3);
            this.set("table", data.data);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
        } else if (this.get("isWaterDropChart")) {
          //(data);
          var chart1 = this.get("amcharts").waterDropColumn(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), "hh", this.get("mode"), this.get("model"));
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            chart1.dataProvider = data.data;
            this.set("chart1", chart1);
            this.set("table", data.data);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
          //(data);
        } else if (this.get("isWindSpeedChart")) {
          var chart1 = this.get("amcharts").waterLevelLine(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), "hh", this.get("mode"), this.get("model"));
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            chart1.dataProvider = data.data;
            this.set("chart1", chart1);
            this.set("table", data.data);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
          this.loadCurrentData("chart1DataLoading", this.get("model.id")).then((function (data) {
            var chart2 = this.get("amcharts").windSpeedGauge(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), data.data);
            this.set("chart2", chart2);
          }).bind(this));
        } else if (this.get("isAirTemperatureChart")) {
          var chart1 = this.get("amcharts").waterLevelLine(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), "hh", this.get("mode"), this.get("model"));
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            chart1.dataProvider = data.data;
            this.set("chart1", chart1);
            this.set("table", data.data);
          }).bind(this));
          var chart2 = this.get("amcharts").waterTemperatureBullet(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), this.get("mode"), this.get("model"));
          this.loadCurrentData("chart2DataLoading", this.get("model.id")).then((function (data) {
            chart2.dataProvider = [{
              category: this.get("i18n").t("technika.sensor.edit.chart.temperature"),
              value: data.data,
              minValue: this.get("model.minValue"),
              maxValue: this.get("model.maxValue")
            }];
            this.set("chart2", chart2);
          }).bind(this));
        } else if (this.get("isAirHumidityChart")) {
          var chart1 = this.get("amcharts").waterLevelLine(this.get("model.minValue"), this.get("model.maxValue"), this.get("model").get("warnings"), "hh", this.get("mode"), this.get("model"));
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            chart1.dataProvider = data.data;
            this.set("table", data.data);
            this.set("chart1", chart1);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
        } else if (this.get("isFlotLine")) {
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            this.set("chartdata", data.floatData);
            this.set("table", data.data);
            this.set("period7", "blue");
            this.set("yaxis", data.yaxis);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
        } else if (this.get("isFlotBar")) {
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            this.set("chartdata", data.floatData);
            this.set("table", data.data);
            this.set("period", 7);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
        } else if (this.get("isFlotRadar")) {
          this.loadHourlyData("chart1DataLoading", this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
            this.set("chartdata", data.floatData);
            this.set("table", data.data);
            this.set("period", 7);
            Ember.run("afterrender", function () {
              jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
              jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
            });
          }).bind(this));
        }
      }
    }).observes("model", "amcharts.isLoaded", "isLoading"),
    isWaterLevelChart: (function () {
      return this.get("model.defaultGraphType") === "water-level";
    }).property("model.defaultGraphType"),
    isWaterTemperatureChart: (function () {
      return this.get("model.defaultGraphType") === "water-temperature";
    }).property("model.defaultGraphType"),
    isAirHumidityChart: (function () {
      return this.get("model.defaultGraphType") === "air-humidity";
    }).property("model.defaultGraphType"),
    isWaterDropChart: (function () {
      return this.get("model.defaultGraphType") === "water-drop";
    }).property("model.defaultGraphType"),
    isAirTemperatureChart: (function () {
      return this.get("model.defaultGraphType") === "air-temperature";
    }).property("model.defaultGraphType"),
    isWindSpeedChart: (function () {
      return this.get("model.defaultGraphType") === "wind-speed";
    }).property("model.defaultGraphType"),
    isFlotLine: (function () {
      return this.get("model.defaultGraphType") === "flot-line";
    }).property("model.defaultGraphType"),
    isFlotBar: (function () {
      return this.get("model.defaultGraphType") === "flot-bar";
    }).property("model.defaultGraphType"),
    isFlotRadar: (function () {
      return this.get("model.defaultGraphType") === "flot-radar";
    }).property("model.defaultGraphType"),
    isNotNew: (function () {
      return !this.get("model.isNew");
    }).property("model.isNew"),
    title: (function () {
      if (this.get("model.isNew")) {
        return "technika.sensor.new.title";
      } else {
        return "technika.sensor.edit.title";
      }
    }).property("model"),
    actions: {
      saveAndList: function saveAndList() {
        this.set("clearWarnings", true);
        this.set("afterSave", "technika.sensor.index");
        this.save();
      },
      cancel: function cancel() {
        this.get("store").unloadAll("technika.sensor.warning");
        this.transitionToRoute("technika.sensor.index");
      },
      addNewType: function addNewType() {
        this.get("sensorTypeEdit").load(0);
        this.set("addFileTypeModalVisible", true);
      },
      saveNewType: function saveNewType() {
        this.get("sensorTypeEdit").save(false, (function (type, obj) {
          if (type === "success") {
            this.set("addFileTypeModalVisible", false);
          }
        }).bind(this));
      },
      searchStation: function searchStation() {
        var controller = this.get("stationList");
        controller.get("filters").forEach(function (el) {
          if (el.get("name") == "term") el.set("value", controller.get("term"));
          if (el.get("name") == "companyName") el.set("value", controller.get("companyName"));
        });
        controller.load();
      },
      clearStation: function clearStation() {
        var controller = this.get("stationList");
        controller.get("filters").forEach(function (el) {
          if (el.get("name") == "term") el.set("value", null);
          if (el.get("name") == "companyName") el.set("value", null);
          if (el.get("name") == "limit") el.set("value", 99999);
          if (el.get("name") == "page") el.set("value", 1);
        });
        controller.load();
        controller.load();
      },
      showStationModal: function showStationModal() {
        var controller = this.get("stationList");
        controller.setSingleselect(true);
        controller.set("fastFilter", true);
        controller.set("singleselectType", "station");
        controller.set("singleselectTarget", this);
        controller.get("filters").forEach(function (el) {
          if (el.get("name") == "limit") el.set("value", 99999);
          if (el.get("name") == "page") el.set("value", 1);
        });
        controller.load();
        this.set("stationModalVisible", true);
      },
      singleselect: function singleselect(model, type) {
        this.get("model").set("station", model);
        this.set("stationModalVisible", false);
      },
      addWarningLevel: function addWarningLevel() {
        var record = this.get("store").createRecord("technika/sensor/warning");
        record.set("isActive", true);
        this.get("model").get("warnings").pushObject(record);
      },
      deleteWarningLevel: function deleteWarningLevel(params) {
        params.param.deleteRecord();
      },

      stats24Hours: function stats24Hours(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery['default'](".dateFrom").val(moment().subtract(24, "hours").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("model.id"), moment().format(), moment().subtract(24, "hours").format()).then((function (data) {
          if (chart) {
            chart.dataProvider = data.data;
            chart.validateData();
          }
          this.set("yaxis", data.yaxis);
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
          this.set("24h", "blue");
          this.set("7d", "");
          this.set("14d", "");
          this.set("30d", "");
          this.set("365d", "");
        }).bind(this));
      },
      stats7Days: function stats7Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery['default'](".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("model.id"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
          if (chart) {
            chart.dataProvider = data.data;
            chart.validateData();
          }
          this.set("yaxis", data.yaxis);
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
          this.set("24h", "");
          this.set("7d", "blue");
          this.set("14d", "");
          this.set("30d", "");
          this.set("365d", "");
        }).bind(this));
      },
      stats30Days: function stats30Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery['default'](".dateFrom").val(moment().subtract(30, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("model.id"), moment().format(), moment().subtract(30, "days").format()).then((function (data) {
          if (chart) {
            chart.dataProvider = data.data;
            chart.validateData();
          }
          this.set("yaxis", data.yaxis);
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
          this.set("24h", "");
          this.set("7d", "");
          this.set("14d", "");
          this.set("30d", "blue");
          this.set("365d", "");
        }).bind(this));
      },
      stats365Days: function stats365Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery['default'](".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery['default'](".dateFrom").val(moment().subtract(365, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("model.id"), moment().format(), moment().subtract(365, "days").format()).then((function (data) {
          if (chart) {
            chart.dataProvider = data.data;
            chart.validateData();
          }
          this.set("yaxis", data.yaxis);
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
          this.set("24h", "");
          this.set("7d", "");
          this.set("14d", "");
          this.set("30d", "");
          this.set("365d", "blue");
        }).bind(this));
      },
      statsFull: function statsFull(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        this.loadHourlyData(dataLoading, this.get("model.id")).then((function (data) {
          if (chart) {
            chart.dataProvider = data.data;
            chart.validateData();
          }
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
        }).bind(this));
      },
      statsCustom: function statsCustom(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        var dateTo = jQuery['default'](".dateTo").val();
        var dateFrom = jQuery['default'](".dateFrom").val();
        this.loadHourlyData(dataLoading, this.get("model.id"), dateTo, dateFrom).then((function (data) {
          if (chart) {
            chart.dataProvider = data.data;
            chart.validateData();
          }
          this.set("yaxis", data.yaxis);
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
        }).bind(this));
      },
      statsCustomCSV: function statsCustomCSV(chart, dataLoading) {
        var dateTo = jQuery['default'](".dateTo").val();
        var dateFrom = jQuery['default'](".dateFrom").val();
        //this.loadHourlyData(dataLoading, this.get('model.id'),dateTo,dateFrom).then(function (data) {
        //if (chart) {
        //    chart.dataProvider = data.data;
        //    chart.validateData();
        //}
        //this.set('chartdata', data.floatData);
        //this.set('table', data.data)
        window.open(this.get("application").get("server") + "/technika/senesor/export/" + dateFrom + "/" + dateTo + "/" + this.get("model.id") + ".html");
        //}.bind(this));
      },
      getAlarms: function getAlarms(checked) {
        var dateTo = jQuery['default'](".dateTo").val();
        var dateFrom = jQuery['default'](".dateFrom").val();
        this.loadHourlyData("chart1DataLoading", this.get("model.id"), dateTo, dateFrom).then((function (data) {
          this.set("yaxis", data.yaxis);
          this.set("chartdata", data.floatData);
          this.set("table", data.data);
        }).bind(this));
      } }
  });

  /*
  this.set(isLoading, true);
  return jQuery.ajax({
      url: this.get('application').get('server') + "/technika/sensor/" + id + "/data/daily.json",
      complete: function () {
        this.set(isLoading, false);
      }.bind(this)
  });
  */

  /*
  this.set(isLoading, true);
  return jQuery.ajax({
      url: this.get('application').get('server') + "/technika/sensor/" + id + "/data/monthly.json",
      complete: function () {
        this.set(isLoading, false);
      }.bind(this)
  });
  */

});