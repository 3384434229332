define('admin/routes/admin/dictionary/new', ['exports', 'admin/routes/new'], function (exports, New) {

  'use strict';

  exports['default'] = New['default'].extend({
    config: {
      into: "admin/dictionary/new",
      controller: "admin/dictionary/edit" }
  });

});