define('admin/controllers/technika/raport/single', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    queryParams: ["stations", "dateFrom", "dateTo", "type"],
    stations: null,
    isLoading: false,
    charLoading: false,
    chart: null,
    table: null,
    sensors: null,
    isMonthly: false,
    isDaily: false,
    isYearly: false,
    init: function init() {
      this._super();
      this.get("amcharts").load();
      this.get("navigation").pushObject({
        route: "technika.station.map",
        text: "technika.station.navigation.map"
      });
      this.set("chartdata", []);
    },
    load: (function () {
      //if (this.get('amcharts.isLoaded')) {
      this.set("isLoading", true);
      var chartdata = [];
      this.loadHourlyData("isLoading", this.get("stations"), this.get("dateFrom"), this.get("dateTo"), this.get("type")).then((function (data) {
        this.set("isLoading", false);
        if (this.get("type") == "monthly") {
          this.set("isMonthly", true);
          this.set("raportName", "miesięczny");
        } else if (this.get("type") == "daily") {
          this.set("isDaily", true);
          this.set("raportName", "dzienny");
        } else if (this.get("type") == "yearly") {
          this.set("raportName", "roczny");
          this.set("isYearly", true);
        }
        data.floatData.forEach(function (item) {
          chartdata.push([item]);
        });
        this.set("chartdata", chartdata);
      }).bind(this));
      //}
    }).observes("amcharts.isLoaded"),
    loadHourlyData: function loadHourlyData(isLoading, id, minTime, maxTime, type) {
      this.set("charLoading", true);
      return jQuery.ajax({
        url: this.get("application").get("server") + "/technika/sensor/" + id + "/data/raport.json",
        data: {
          type: type,
          from: minTime,
          to: maxTime,
          ca: "s"
        },
        complete: (function () {
          this.set(isLoading, false);
          this.set("charLoading", false);
        }).bind(this)
      });
    },
    actions: {
      goBack: function goBack() {
        window.history.back();
      },
      stats24Hours: function stats24Hours(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery(".dateFrom").val(moment().subtract(24, "hours").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("stations"), moment().format(), moment().subtract(24, "hours").format()).then((function (data) {
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
        }).bind(this));
      },
      stats7Days: function stats7Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery(".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("stations"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
        }).bind(this));
      },
      stats30Days: function stats30Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery(".dateFrom").val(moment().subtract(30, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("stations"), moment().format(), moment().subtract(30, "days").format()).then((function (data) {
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
        }).bind(this));
      },
      statsCustom: function statsCustom(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        var dateTo = jQuery(".dateTo").val();
        var dateFrom = jQuery(".dateFrom").val();
        this.loadHourlyData(dataLoading, this.get("stations"), dateTo, dateFrom).then((function (data) {
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
        }).bind(this));
      },
      statsCustomCSV: function statsCustomCSV(chart, dataLoading) {
        this.set("stations", this.get("stations"));
        window.open(this.get("application").get("server") + "/technika/senesor/raport/export/" + this.get("dateFrom") + "/" + this.get("dateTo") + "/" + this.get("stations") + "/" + this.get("type") + ".html");
      },
      print: function print() {
        window.print();
      }
    }
  });

});