define('admin/controllers/technika/station/service', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        messages: {},
        init: function init() {
            this._super();
        },
        archive: null,
        crit: true,
        sorts: ["Nazwa stacji", "Chronologicznie", "Typ Błędu", "Archiwalne", "Aktywne"],
        load: function load(station, sort, archive, exp, crit) {
            this.set("isLoading", true);
            return jQuery.ajax({
                data: { station: station, sort: sort, archive: archive, crit: crit },
                url: this.get("application").get("server") + "/log.json",
                complete: (function (result) {
                    this.set("isLoading", false);
                    if (exp) {
                        window.open(this.get("application").get("server") + "/log/export.html");
                    }
                    this.set("messages", Ember['default'].Object.create(result.responseJSON));
                }).bind(this)
            });
        },
        isCheckedObserver: (function () {
            var isChecked = this.get("checkAll");
            jQuery("[name=\"trash[]\"]").each(function (index) {
                $(this).prop("checked", isChecked);
            });
        }).observes("checkAll"),
        actions: {
            search: function search() {
                this.load(this.get("station"), this.get("sort"), this.get("archive"), false, this.get("crit"));
            },
            clear: function clear() {
                this.set("sort", "Chronologicznie");
                this.set("station", null);
                this.load();
            },
            "export": function _export() {
                this.load(this.get("station"), this.get("sort"), true);
            },
            archive: function archive(alert) {
                window.open(this.get("application").get("server") + "/log/archive/" + alert.sensor_id + ".html");
            },
            remove: function remove(alert, prompt) {
                if (confirm(this.get("i18n").t(prompt))) {
                    return jQuery.ajax({
                        method: "DELETE",
                        data: { id: alert.id },
                        url: this.get("application").get("server") + "/log.json",
                        complete: (function (result) {
                            this.set("isLoading", false);
                            this.load(this.get("station"), this.get("sort"), false);
                        }).bind(this)
                    });
                }
            },
            removeMany: function removeMany(prompt) {
                if (confirm(this.get("i18n").t(prompt))) {
                    var alertsc = [];
                    jQuery("[name=\"trash[]\"]:checked").each(function (index) {
                        alertsc.push($(this).val());
                    });
                    return jQuery.ajax({
                        method: "DELETE",
                        data: { id: alertsc.join() },
                        url: this.get("application").get("server") + "/log.json",
                        complete: (function (result) {
                            this.set("isLoading", false);
                            this.load(this.get("station"), this.get("sort"), false);
                        }).bind(this)
                    });
                }
            }
        }
    });

});