define('admin/controllers/list', ['exports', 'ember', 'admin/utils/filter-element'], function (exports, Ember, FilterElement) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    modelName: null,
    page: 1,
    error: false,
    filters: [],
    navigation: [],
    accordion: [],
    operatins: [],
    multiselect: false,
    fastfilter: false,
    singleselect: false,
    singleselectType: null,
    multiselectType: null,
    multiselectCollection: [],
    list: true,
    setMultiselect: function setMultiselect(b) {
      if (b === true) {
        this.set("multiselect", true);
        this.set("singleselect", false);
        this.set("fastfilter", true);
        this.set("list", false);
      } else {
        this.set("multiselect", false);
        this.set("fastfilter", false);
        this.set("list", true);
      }
    },
    setSingleselect: function setSingleselect(b) {
      if (b === true) {
        this.set("singleselect", true);
        this.set("multiselect", false);
        this.set("fastfilter", true);
        this.set("list", false);
      } else {
        this.set("singleselect", false);
        this.set("list", true);
        this.set("fastfilter", false);
      }
    },
    setList: function setList() {
      this.set("list", true);
      this.set("multiselect", false);
      this.set("singleselect", false);
      this.set("fastfilter", false);
    },
    init: function init() {
      this._super();
      this.set("filters", []);
      this.set("navigation", []);
      this.set("accordion", []);
      this.set("operations", []);
      this.get("filters").addObject(FilterElement['default'].create({
        name: "page",
        defaultValue: 1,
        type: "hidden"
      }));
      this.get("filters").addObject(FilterElement['default'].create({
        name: "limit",
        defaultValue: 30,
        type: "hidden"
      }));
    },
    load: function load() {
      this.set("isLoading", true);
      this.set("error", false);
      this.get("store").query(this.get("modelName"), this.getQueryFilter()).then((function (data) {
        this.set("model", data.toArray()); //data.content
        this.set("meta", data.meta);
        this.set("isLoading", false);
      }).bind(this), (function (a, b, c, d) {
        this.set("error", true);
        this.set("isLoading", false);
      }).bind(this));
    },
    getQueryFilter: function getQueryFilter() {
      var filter = {};
      this.get("filters").forEach(function (el) {
        filter[el.get("name")] = el.getValue();
        //        filter.push({
        //          name: 'filter[' + el.get('name') + ']',
        //          value: el.getValue()
        //        });
      });
      return { filter: filter };
    },
    actions: {
      changePage: function changePage(page_no) {
        var page = this.get("filters").findBy("name", "page");
        if (page) {
          page.set("value", page_no);
        }
        this.load();
      },
      remove: function remove(params) {

        var object = params.value;
        var message = params.param;
        if (confirm(this.get("i18n").t(message))) {
          this.set("isLoading", true);
          object.deleteRecord();
          object.save().then((function () {
            this.set("isLoading", false);
            this.load();
          }).bind(this), (function (adapterError) {
            if (adapterError.errors) {
              this.get("application").error("app.error.form.save", adapterError.message);
            }
            this.set("isLoading", false);
          }).bind(this));
        }
      },
      reload: function reload() {
        this.load();
      },
      search: function search() {
        this.load();
      },
      clear: function clear() {
        this.get("filters").forEach(function (filter) {
          filter.set("value", filter.get("defaultValue"));
        });
        this.load();
      }
    }
  });

});