define('admin/routes/technika/sensor/edit', ['exports', 'admin/routes/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    config: {
      into: "technika/sensor/edit",
      controller: "technika/sensor/edit" }
  });

});