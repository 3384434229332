define('admin/tests/unit/helpers/moment-test', ['admin/helpers/moment', 'qunit'], function (moment, qunit) {

  'use strict';

  qunit.module("MomentHelper");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = moment.moment(42);
    assert.ok(result);
  });

});