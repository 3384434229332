define('admin/views/admin/company/edit', ['exports', 'ember', 'jquery'], function (exports, Ember, $) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    didInsertElement: function didInsertElement() {
      this._super();
      if (this.$(".menu .item").length > 0) {
        this.$(".menu .item").tab();
      }
    },
    tabIsContractor: (function () {
      if ($['default'](".menu .item.tab-distributor").length > 0) {
        if (this.get("controller.model.isContractor")) {
          this.$(".menu .item.tab-distributor").tab();
        } else {
          this.$(".menu .item.tab-distributor").tab("destroy");
        }
      }
    }).observes("controller.model.isContractor"),
    tabIsOrganizer: (function () {
      if ($['default'](".menu .item.tab-organizer").length > 0) {
        if (this.get("controller.model.isOrganizer")) {
          this.$(".menu .item.tab-organizer").tab();
        } else {
          this.$(".menu .item.tab-organizer").tab("destroy");
        }
      }
    }).observes("controller.model.isOrganizer")
  });

});