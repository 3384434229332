define('admin/utils/filter-element', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    label: null,
    name: null,
    value: null,
    type: "text", //dictionary
    dictionaryName: null,
    defaultValue: null,
    isText: (function () {
      return this.get("type") === "text" ? true : false;
    }).property("type"),
    isDictionary: (function () {
      return this.get("type") === "dictionary" ? true : false;
    }).property("type"),
    isHidden: (function () {
      return this.get("type") === "hidden" ? true : false;
    }).property("type"),
    getValue: function getValue() {
      if (!this.get("value") || this.get("value").length <= 0) {
        return this.get("defaultValue");
      }
      return this.get("value");
    }
  });

});