define('admin/controllers/admin/tax/edit', ['exports', 'admin/controllers/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "tax",
    redirect: "admin.tax.edit",
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "admin.tax.index",
        text: "admin.tax.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.tax.new",
        text: "admin.tax.navigation.new"
      });
    },
    //    multiselect: function (view) {
    //      view.$().find('input[type=checkbox]:checked').each(function (i, el) {
    //        this.get('model.securityRoles').pushObject(view.get('controller.store').getById('securityrole', jQuery(el).val()));
    //      }.bind(this));
    //    },
    //    singleselect: function (view, value) {
    //      if (view.get('name') === "usercompany") {
    //        this.get('model').set('company', value);
    //      }
    //      view.$().find('input[type=checkbox]:checked').each(function (i, el) {
    //        this.get('model.securityRoles').pushObject(view.get('controller.store').getById('securityrole', jQuery(el).val()));
    //      }.bind(this));
    //    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "admin.tax.new.title";
      } else {
        return "admin.tax.edit.title";
      }
    }).property("model"),
    actions: {}
  });

  //      removeCompany: function (company) {
  //        this.get('model').set('company', null);
  //      }

});