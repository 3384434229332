define('admin/components/ui-map', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    mapController: null,
    didRender: function didRender() {

      this.$().css({
        width: "100%",
        height: screen.height - 280,
        position: "relative"
      });
      if (this.get("mapController.openlayersInitialized")) {
        this.onOpenLayerLoaded();
      }
    },
    onOpenLayerLoaded: (function () {
      if (this._state === "inDOM" && this.get("mapController") && this.get("mapController.openlayersInitialized")) {
        this.get("mapController").mapInit(this.$()[0], [2068940.1538519368, 6892121.669630231]);
      }
    }).observes("mapController.openlayersInitialized"),
    willDestroy: function willDestroy() {
      this.get("mapController").destroyMap();
    },
    actions: {
      mapOnObject: function mapOnObject(object) {
        if (this.get("mapController")) {
          if (object.geojson.type === "Polygon" || object.geojson.type === "LineString") {
            if (object.geojson.type === "Polygon") {
              var feature = new ol.Feature({
                geometry: new ol.geom.Polygon(object.geojson.coordinates) });
              this.get("mapController").send("goToFeature", feature);
            } else if (object.geojson.type === "LineString") {
              var feature = new ol.Feature({
                geometry: new ol.geom.LineString(object.geojson.coordinates) });
              this.get("mapController").send("goToFeature", feature);
            }
          } else if (object.lon && object.lat) {
            this.get("mapController").send("goToLonLat", object.lon, object.lat);
          }
        }
      }
    }
  });

});