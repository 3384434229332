define('admin/components/field-gallery', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    onModel: (function () {
      var _this = this;

      if (this.get("model")) {
        (function () {
          var nb = 0;
          var category = _this.get("category");
          _this.get("model").get(_this.get("name")).forEach(function (data) {
            if (data.get("category") == category) {
              nb++;
            }
          });
          _this.set("il", nb);
          _this.get("model").addObserver(_this.get("name"), (function () {
            this.set("il", this.get("model").get(this.get("name")).length);
          }).bind(_this));
        })();
      }
    }).observes("model"),
    name: null,
    model: null,
    deleted: [],
    didRender: function didRender() {
      Ember['default'].run.scheduleOnce("afterRender", this, "initialize");
    },
    il: 0,
    images: (function () {
      return this.get("model." + this.get("name"));
    }).property("il"),
    initialize: function initialize() {
      this.onModel();
      this.$("input[type=file]").on("change", (function (event) {
        if (this.get("model")) {
          var images = this.get("model").get(this.get("name"));
          var file = event.currentTarget.files[0];
          if (!images.findBy("file", file)) {
            var record = this.get("model.store").createRecord("file", {
              file: file,
              name: file.name,
              size: file.size,
              category: this.get("category")
            });
            this.get("model").get(this.get("name")).addObject(record);
          }

          //(record, file, typeof file,  file instanceof File);
          //this.get('model').set(this.get('name'), event.currentTarget.files[0]);
        }
      }).bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("name"));
      }
      this.$().remove();
    },
    actions: {
      selectFile: function selectFile() {
        this.$("input[type=file]").click();
      },
      deleteImage: function deleteImage(image) {
        image.deleteRecord();
        this.get("deleted").pushObject(image);
      }
    }
  });

});