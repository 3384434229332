define('admin/controllers/technika/contact/index', ['exports', 'admin/controllers/edit', 'ember-data', 'ember'], function (exports, Edit, DS, Ember) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/contact",
    init: function init() {
      this._super();
    },
    actions: {
      send: function send() {
        var data = new FormData($("form")[0]);

        $.ajax({
          url: this.get("application").get("server") + "/technika/contact.json",
          type: "POST",
          data: data,
          cache: false,
          dataType: "json",
          processData: false, // Don't process the files
          contentType: false, // Set content type to false as jQuery will tell the server its a query string request
          success: function success(data, textStatus, jqXHR) {
            noty({
              layout: "topCenter",
              theme: "relax",
              text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Formularz kontaktowy</div></h5> Wiadmość została wysłana",
              type: "information",
              timeout: 5000
            });
            $("input[name=subject]").val("");
            $("textarea[name=message]").val("");
            $("input[name=sender]").val("");
            $("input[name=attachement]").val("");
          },
          error: function error(jqXHR, textStatus, errorThrown) {}
        });
      }
    }
  });

});