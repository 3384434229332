define('admin/helpers/file-category', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.helperfunc = helperfunc;

    function helperfunc(params, options) {
        if (params[0] == params[1]) {
            return true;
        } else {
            return false;
        }
    }

    exports['default'] = Ember['default'].Helper.helper(helperfunc);

});