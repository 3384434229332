define('admin/controllers/account/history', ['exports', 'admin/controllers/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "account/history",
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "account.edit",
        text: "account.navigation.edit"
      });
      this.get("navigation").pushObject({
        route: "account.password",
        text: "account.navigation.password"
      });
      this.get("navigation").pushObject({
        route: "account.history",
        text: "account.navigation.history"
      });
    }
  });

});