define('admin/routes/admin/tax/index', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "admin/tax/index",
      controller: "admin/tax/list",
      hasPager: true,
      hasFilter: true,
      hasNavigation: true
    }
  });

});