define('admin/templates/technika/station/maplist', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["inline","nav-accordion",[],["data",["subexpr","@mut",[["get","model",["loc",[null,[1,21],[1,26]]]]],[],[]],"station_action","showOnMap","itemsChecked",["subexpr","@mut",[["get","checkedSensors",["loc",[null,[1,67],[1,81]]]]],[],[]]],["loc",[null,[1,0],[1,83]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});