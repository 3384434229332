define('admin/controllers/account/edit', ['exports', 'admin/controllers/edit', 'jquery'], function (exports, Edit, jQuery) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "account",
    redirect: "account.edit",
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "account.edit",
        text: "account.navigation.edit"
      });
      this.get("navigation").pushObject({
        route: "account.password",
        text: "account.navigation.password"
      });
      this.get("navigation").pushObject({
        route: "account.history",
        text: "account.navigation.history"
      });
    },
    load: function load() {
      this.set("model", this.get("application.authManager.user"));
    },
    //    multiselect: function (view) {
    //      view.$().find('input[type=checkbox]:checked').each(function (i, el) {
    //        this.get('model.securityRoles').pushObject(view.get('controller.store').getById('securityrole', jQuery(el).val()));
    //      }.bind(this));
    //    },
    title: (function () {
      return "account.edit.title";
    }).property("model"),
    actions: {
      save: function save() {
        this.set("isLoading", true);
        jQuery['default'].ajax({
          url: this.get("application").get("server") + "/account/update.json",
          method: "PUT",
          data: JSON.stringify({ user: this.get("model").serialize() }),
          contentType: "application/json",
          dataType: "json",
          error: (function (response) {
            this.set("isLoading", false);
            if (response.responseJSON) {
              //(response.responseJSON);
              this.set("formErrors", response.responseJSON.fields);
              this.get("application").error("app.error.form.save", response.responseJSON.message);
            }
          }).bind(this),
          success: (function () {
            this.set("isLoading", false);
          }).bind(this)
        });
      }
    }
  });

});