define('admin/components/field-multiselect', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    showModalAction: null,

    controllerName: null,
    into: null,
    outlet: null,
    actions: {
      showModal: function showModal() {
        //controller, into, outlet
        this.triggerAction({
          action: this.get("showModalAction")
          //target: this
        });
      }
    }
  });

});