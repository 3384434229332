define('admin/models/shop/order', ['exports', 'admin/models/shop/cart'], function (exports, Cart) {

  'use strict';

  exports['default'] = Cart['default'].extend({
    state: DS.attr("string"),
    displayState: (function () {
      var res = this.get("i18n").t("shop.order.state").findBy("key", this.get("state"));
      if (res) {
        return res.value;
      }
    }).property("state") });

});