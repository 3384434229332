define('admin/utils/openlayers/layer', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    init: function init() {
      this.set("visible", this.get("layer").get("visible"));
      this.set("name", this.get("layer").get("name"));
    },
    layer: null,
    name: null,
    visible: true,
    setVisibility: function setVisibility(field, checked) {
      this.get("layer").setVisible(checked);
    }
  });

});