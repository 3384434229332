define('admin/helpers/currency-format', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.helperfunc = helperfunc;

  function helperfunc(params, options) {
    var amount = params[0] ? params[0] : "0";
    var locale = params[1];
    if (locale) {
      if (amount) {
        amount = amount.replace(/[, ]/g, "");
      } else {
        amount = 0;
      }
      //(amount, locale);
      var nf = new NumberFormat(locale, NumberFormat.locale[locale].CURRENCY_PATTERN);
      return nf.format(amount);
    }
  }

  exports['default'] = Ember['default'].Helper.helper(helperfunc);

});