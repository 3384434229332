define('admin/models/shop/cart', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    items: DS['default'].hasMany("shop/cart/item", { async: false }),
    worth: DS['default'].attr("string"),
    quantity: DS['default'].attr("number"),
    locale: DS['default'].attr("string"),
    currency: DS['default'].attr("string"),
    expirationDate: DS['default'].attr("string"),
    payments: DS['default'].hasMany("shop/cart/payment", { async: false }),
    createdByUser: DS['default'].belongsTo("user", { async: false }),
    user: DS['default'].belongsTo("user", { async: false }),

    print: DS['default'].attr("boolean"),
    invitation: DS['default'].attr("boolean"),
    description: DS['default'].attr("string"),
    acknowledgment: DS['default'].attr("string"),
    pass: DS['default'].attr("string")
  });

});