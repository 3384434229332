define('admin/models/technika/station', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    description: DS['default'].attr("string"),
    serial: DS['default'].attr("string"),
    lon: DS['default'].attr("number-string"),
    lat: DS['default'].attr("number-string"),
    timezone: DS['default'].attr("string"),
    isMonitored: DS['default'].attr("boolean"),
    ignoreTimezone: DS['default'].attr("boolean"),
    isActive: DS['default'].attr("boolean"),
    notLoged: DS['default'].attr("boolean"),
    inactiveDate: DS['default'].attr("string"),
    inactiveDescription: DS['default'].attr("string"),
    exportAvailable: DS['default'].attr("boolean"),
    exportFrom: DS['default'].attr("string"),
    exportTo: DS['default'].attr("string"),
    ftpServer: DS['default'].attr("string"),
    ftpLogin: DS['default'].attr("string"),
    ftpPassword: DS['default'].attr("string"),
    ftpFilePfx: DS['default'].attr("string"),
    ftpDirectory: DS['default'].attr("string"),
    importer: DS['default'].attr("string"),
    ftpDoneDirectory: DS['default'].attr("string"),
    ftpConfigDirectory: DS['default'].attr("string"),
    dataType: DS['default'].belongsTo("dictionary", { async: true }),
    dataTypeName: DS['default'].attr("string"),
    ownerName: DS['default'].attr("string"),
    company: DS['default'].belongsTo("company", { async: false }),
    maintenances: DS['default'].hasMany("technika/station/maintenance", { async: false }),
    sensors: DS['default'].hasMany("technika/sensor", { async: false }),
    sensors_info: DS['default'].attr(),
    sensors_menu: DS['default'].attr(),
    images: DS['default'].hasMany("file", { async: false }),
    imageUri: DS['default'].attr("string"),
    transmision: DS['default'].attr("string"),
    imageUrl: (function () {
      if (this.get("imageUri") && this.get("imageUri").length > 0) {
        return Admin.get("static") + this.get("imageUri");
      }
      return null;
    }).property("imageUri"),
    hasImages: (function () {
      return this.get("images").length > 0 ? true : false;
    }).property("images"),
    hasSerial: (function () {
      return this.get("serial") !== null ? true : false;
    }).property("serial")
  });

});