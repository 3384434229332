define('admin/components/ui-flotradarcharts', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    isLoading: false,
    data: [],
    didRender: function didRender() {
      var d1 = this.get("data");
      if (!Em.isEmpty(d1)) {
        //var d1 = [ [1,10], [1,20], [2,80], [3,70], [4,60], [3,70], [4,60], [5,20] ];
        var data = [{ label: "Kierunek wiatru", color: "#1E90FF", data: d1,
          spider: { show: true, lineWidth: 1 } }];
        var options = {
          series: {
            editMode: "v", editable: true,
            spider: {
              scaleMode: "all",
              active: true,
              lineWidth: 1,
              highlight: { mode: "area" },
              legs: {
                data: [{ label: "E" }, { label: "Se" }, { label: "S" }, { label: "Sw" }, { label: "W" }, { label: "Nw" }, { label: "N" }, { label: "Ne" }],
                legScaleMax: 1,
                legScaleMin: 0.8
              },
              spiderSize: 0.8
            }
          },
          grid: {
            //hoverable: true,
            //clickable: true,
            //editable:true,
            tickColor: "rgba(0,0,0,0.01)", mode: "radar"
          }
        };
        var p = $.plot($("#chart"), data, options);
      }
    }
  });

});