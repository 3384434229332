define('admin/components/field-file', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    accept: null,
    onModel: (function () {
      if (this.get("model") && this.get("name") in this.get("model")) {
        this.setValue(this.get("model." + this.get("name")));
        this.get("model").addObserver(this.get("name"), (function () {
          this.setValue(this.get("model." + this.get("name")));
        }).bind(this));
      } else {
        this.addObserver("val", (function () {
          this.setValue(this.get("val"));
        }).bind(this));
      }
    }).observes("model", "val"),
    setValue: function setValue(file) {
      if (file instanceof File) {
        this.set("value", file.name);
        this.set("icon", this.typeToIcon(file.type));
      }
    },
    name: null,
    model: null,
    readonly: false,
    value: null,
    onReadonly: (function () {
      if (this.get("readonly")) {
        this.$().attr("readonly", true);
      } else {
        this.$().attr("readonly", false);
      }
    }).observes("readonly").on("didRender"),
    didRender: function didRender() {
      Ember['default'].run.scheduleOnce("afterRender", this, "initialize");
    },
    icon: "fa-file",
    initialize: function initialize() {
      this.onModel();
      this.$("input[type=file]").on("change", (function (event) {
        if (this.get("model")) {
          this.get("model").set(this.get("name"), event.currentTarget.files[0]);
        }
      }).bind(this));
      this.$().data("modelId", this.get("model.id"));
      //    this.$().on(this.get('onmethod'), function (event) {
      //      if (this.get('model')) {
      //        this.get('model').set(this.get('name'), jQuery(event.target).val());
      //      }
      //    }.bind(this));
    },
    typeToIcon: function typeToIcon(type) {
      //(type);
      switch (type) {
        case "image/png":
          return "fa-file-image-o";
        case "application/x-php":
          return "fa-file-code-o";
        case "q":
          return "file-archive-o";
        case "q":
          return "file-audio-o";
        case "q":
          return "file-excel-o";
        case "q":
          return "file-pdf-o";
        case "q":
          return "file-powerpoint-o";
        case "q":
          return "file-text";
        case "q":
          return "file-video-o";
        case "q":
          return "file-word-o";
        case "q":
          return "file-archive-o";
        default:
          return "fa-file-o";
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("name"));
      }
      this.$().remove();
    },
    actions: {
      selectFile: function selectFile() {
        this.$("input[type=file]").click();
      }
    }
  });

});