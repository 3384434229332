define('admin/components/form-field-top', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["field"],
    errors: null,
    field: null,
    label: null,
    classNameBindings: ["isRequired"],
    required: false,
    isRequired: (function () {
      if (this.get("required")) return "required";
      return null;
    }).property("required"),
    error: (function () {
      if (this._state === "inDOM") {
        var error = this.get("errors." + this.get("field"));
        //(this.get('field'), this.get('errors'), error);
        //(this.get('errors'), error, this.get('field'));
        if (error && error.errors) {
          this.$().addClass("error");
          return error.errors;
        }
        this.$().removeClass("error");
      }
      return null;
    }).property("errors")
  });

});