define('admin/controllers/technika/sensor/stats', ['exports', 'admin/controllers/technika/sensor/list', 'admin/utils/filter-element', 'admin/utils/accordion'], function (exports, List, FilterElement, Accoridion) {

  'use strict';

  exports['default'] = List['default'].extend({
    operations: [],
    selectedSensors: [],
    modelName: "technika/sensor",
    sensorDetatils: Ember.inject.controller("technika/sensor/details"),
    init: function init() {
      this._super();
      this.set("24h", "");
      this.set("7d", "blue");
      this.set("14d", "");
      this.set("30d", "");
      this.set("navigation", []);
      this.get("navigation").pushObject({
        route: "technika.sensor.index",
        text: "technika.sensor.navigation.index"
      });
      this.get("operations").pushObject({
        title: "technika.sensor.navigation.go.stats",
        action: "showStats"
      });
    },
    actions: {
      addSensor: function addSensor(sensor) {
        var toAdd = this.get("selectedSensors").findBy("id", sensor.get("id"));
        if (Ember.isEmpty(toAdd)) {
          this.get("selectedSensors").pushObject(sensor);
        }
      },
      removeSensor: function removeSensor(sensor) {
        var toRemove = this.get("selectedSensors").findBy("id", sensor.get("id"));
        this.get("selectedSensors").removeObject(toRemove);
      },
      showStats: function showStats() {
        var selectedSensors = [];
        this.get("selectedSensors").forEach(function (item) {
          selectedSensors.push(item.get("id"));
        });
        if (this.get("selectedSensors").length > 6) {
          noty({
            layout: "topCenter",
            theme: "relax",
            text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Formularz kontaktowy</div></h5> Możesz wybrać maksymalnie  6 czujników",
            type: "error",
            timeout: 5000
          });
          return false;
        }
        if (this.get("selectedSensors").length == 0) {
          noty({
            layout: "topCenter",
            theme: "relax",
            text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Formularz kontaktowy</div></h5> Wybierz przynajmniej jeden czujnik",
            type: "error",
            timeout: 5000
          });
          return false;
        }
        this.transitionToRoute("technika.stats.chart", { queryParams: { stations: selectedSensors } });
        //this.set('selectedSensors', []);
      } }
  });

});