define('admin/components/field-date', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["ui", "action", "input"],
    tagName: "div",
    name: null,
    val: null,
    value: null,
    onmethod: "change",
    onModel: (function () {
      this.addObserver("val", (function () {
        this.set("value", this.get("val"));
      }).bind(this));
    }).observes("val"),
    didRender: function didRender() {
      Ember['default'].run.scheduleOnce("afterRender", this, "initialize");
    },
    initialize: function initialize() {
      this.onModel();
      this.$(".datefield").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        format: "DD.MM.YYYY",
        opens: "left"
      });
    }
  });

});