define('admin/transforms/array-to-object', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember['default'].isArray(serialized)) {
        var arr = [];
        serialized.forEach(function (el) {
          arr.push(Ember['default'].Object.create(el));
        });
        return arr;
      }
      return serialized;
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

});