define('admin/controllers/technika/sensor/details', ['exports', 'admin/controllers/technika/sensor/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/sensor",
    config: {
      into: null,
      controller: null },
    isLoading: false,
    charLoading: false,
    mode: "prod",
    init: function init() {
      this._super();
      this.set("navigation", []);
      this.set("operations", []);
      this.set("accordion", []);
      this.set("allowSave", false);
      this.get("accordion").pushObject({
        text: "technika.sensor.navigation.list",
        data: this.get("store").find("technika/station")
      });
    },
    loadHourlyData: function loadHourlyData(isLoading, id, maxTime, minTime) {
      var timeFrom = moment(new Date(minTime.split(".").reverse().join("-")));
      var timeTo = moment(new Date(maxTime.split(".").reverse().join("-")));
      var days = timeTo.diff(timeFrom, "days");
      if (days > 365) {
        noty({
          layout: "topCenter",
          theme: "relax",
          text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Zakres pomiaru</div></h5> Różnica pomiędzy datami może wynosić maksymalnie 365 dni. Zmieniono zakres dat",
          type: "error",
          timeout: 5000
        });
        maxTime = timeTo.subtract(days - 365, "d").format("DD.MM.YYYY");
        jQuery(".dateTo").val(maxTime);
      }
      this.set(isLoading, true);
      this.set("charLoading", true);
      var action = this.get("isFlotRadar") ? "wind" : "hourly";
      return jQuery.ajax({
        url: this.get("application").get("server") + "/technika/sensor/" + id + "/data/" + action + ".json",
        data: {
          from: minTime,
          to: maxTime,
          show_alarms: jQuery(".show_alarms")[0].checked
        },
        complete: (function () {
          this.set(isLoading, false);
          this.set("charLoading", false);
        }).bind(this)
      });
    } });

});