define('admin/templates/components/field-file', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui negative button");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["clear"],[],["loc",[null,[7,36],[7,54]]]],
          ["inline","t",["app.delete"],[],["loc",[null,[7,55],[7,73]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 6
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui fluid action labeled input");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ui label");
        var el3 = dom.createElement("i");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2,"type","text");
        dom.setAttribute(el2,"readonly","readonly");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2,"id","file-upload");
        dom.setAttribute(el2,"type","file");
        dom.setAttribute(el2,"style","display: none; position:absolute; top:-100px;");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ui primary button");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1, 0]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element1, [5]);
        var element5 = dom.childAt(element1, [7]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createAttrMorph(element3, 'value');
        morphs[2] = dom.createAttrMorph(element4, 'accept');
        morphs[3] = dom.createElementMorph(element5);
        morphs[4] = dom.createMorphAt(element5,0,0);
        morphs[5] = dom.createMorphAt(element1,9,9);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["fa ",["get","icon",["loc",[null,[2,41],[2,45]]]]]]],
        ["attribute","value",["concat",[["get","value",["loc",[null,[3,32],[3,37]]]]]]],
        ["attribute","accept",["concat",[["get","accept",["loc",[null,[4,104],[4,110]]]]]]],
        ["element","action",["selectFile"],[],["loc",[null,[5,35],[5,58]]]],
        ["inline","t",["app.select.continue"],[],["loc",[null,[5,59],[5,86]]]],
        ["block","if",[["get","hasValue",["loc",[null,[6,10],[6,18]]]]],[],0,null,["loc",[null,[6,4],[8,11]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});