define('admin/templates/technika/stats/chart', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 4
            },
            "end": {
              "line": 28,
              "column": 97
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Graficzne przedstawienie pomiarów");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 6
            },
            "end": {
              "line": 43,
              "column": 6
            }
          }
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element2,0,0);
          morphs[1] = dom.createMorphAt(element2,2,2);
          return morphs;
        },
        statements: [
          ["content","sensor.station",["loc",[null,[42,10],[42,29]]]],
          ["content","sensor.name",["loc",[null,[42,32],[42,47]]]]
        ],
        locals: ["sensor"],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 6
              },
              "end": {
                "line": 52,
                "column": 6
              }
            }
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0,0,0);
            morphs[1] = dom.createMorphAt(element0,2,2);
            return morphs;
          },
          statements: [
            ["inline","assoc-array-value",[["get","row",["loc",[null,[51,30],[51,33]]]],["get","sensor.id",["loc",[null,[51,34],[51,43]]]],"value_"],[],["loc",[null,[51,10],[51,54]]]],
            ["inline","assoc-array-value",[["get","row",["loc",[null,[51,75],[51,78]]]],["get","sensor.id",["loc",[null,[51,79],[51,88]]]],"unit_"],[],["loc",[null,[51,55],[51,98]]]]
          ],
          locals: ["sensor"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 4
            },
            "end": {
              "line": 54,
              "column": 4
            }
          }
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
          morphs[1] = dom.createMorphAt(element1,3,3);
          return morphs;
        },
        statements: [
          ["inline","moment-js",[["get","row.date",["loc",[null,[49,22],[49,30]]]],"DD.MM.YYYY HH:mm"],[],["loc",[null,[49,10],[49,51]]]],
          ["block","each",[["get","sensors",["loc",[null,[50,14],[50,21]]]]],[],0,null,["loc",[null,[50,6],[52,15]]]]
        ],
        locals: ["row"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui form item");
        dom.setAttribute(el1,"style","margin-top: 20px");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","four flex fields");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","field");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n        <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button> ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","field");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","field");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","field");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4,"class","ui button");
        var el5 = dom.createTextNode("Pokaż");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ui right floated button");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","toggle left icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            Powrót\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"style","clear:both");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ui top attached tabular menu");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","ui button");
        dom.setAttribute(el3,"style","background-color: #f7f7f7");
        var el4 = dom.createTextNode("Tabelaryczne przedstawienie pomiarów (CSV)");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","ui button");
        dom.setAttribute(el3,"style","background-color: #f7f7f7");
        var el4 = dom.createTextNode("Tabelaryczne przedstawienie pomiarów (ZIP)");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"data-tab","graph");
        dom.setAttribute(el1,"class","ui bottom attached tab segment active");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"data-tab","info-table");
        dom.setAttribute(el1,"class","ui bottom attached tab segment");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        dom.setAttribute(el2,"class","ui table");
        dom.setAttribute(el2,"style","margin-top: 15px");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("thead");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tbody");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0, 1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element4, [5]);
        var element8 = dom.childAt(element3, [7]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [3]);
        var element11 = dom.childAt(fragment, [4, 1]);
        var element12 = dom.childAt(element11, [3]);
        var element13 = dom.childAt(element11, [5]);
        var element14 = dom.childAt(fragment, [8, 1]);
        var element15 = dom.childAt(element14, [1, 1]);
        var morphs = new Array(20);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createElementMorph(element5);
        morphs[2] = dom.createMorphAt(element5,0,0);
        morphs[3] = dom.createAttrMorph(element6, 'class');
        morphs[4] = dom.createElementMorph(element6);
        morphs[5] = dom.createMorphAt(element6,0,0);
        morphs[6] = dom.createAttrMorph(element7, 'class');
        morphs[7] = dom.createElementMorph(element7);
        morphs[8] = dom.createMorphAt(element7,0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
        morphs[11] = dom.createElementMorph(element9);
        morphs[12] = dom.createElementMorph(element10);
        morphs[13] = dom.createMorphAt(element11,1,1);
        morphs[14] = dom.createElementMorph(element12);
        morphs[15] = dom.createElementMorph(element13);
        morphs[16] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        morphs[18] = dom.createMorphAt(element15,3,3);
        morphs[19] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[4,35],[4,38]]]]]]],
        ["element","action",["stats24Hours",["get","chart1",["loc",[null,[4,66],[4,72]]]],"chart1DataLoading"],[],["loc",[null,[4,42],[4,94]]]],
        ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[4,95],[4,139]]]],
        ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[5,35],[5,37]]]]]]],
        ["element","action",["stats7Days",["get","chart1",["loc",[null,[5,63],[5,69]]]],"chart1DataLoading"],[],["loc",[null,[5,41],[5,91]]]],
        ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[5,92],[5,134]]]],
        ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[6,35],[6,38]]]]]]],
        ["element","action",["stats30Days",["get","chart1",["loc",[null,[6,65],[6,71]]]],"chart1DataLoading"],[],["loc",[null,[6,42],[6,93]]]],
        ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[6,94],[6,137]]]],
        ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[11,8],[11,37]]]],
        ["inline","field-date",[],["ids","dateTo"],["loc",[null,[14,8],[14,35]]]],
        ["element","action",["statsCustom",["get","chart1",["loc",[null,[17,57],[17,63]]]],"chart1DataLoading"],[],["loc",[null,[17,34],[17,85]]]],
        ["element","action",["goBack"],[],["loc",[null,[18,45],[18,64]]]],
        ["block","form-tabs-tab",[],["active",true,"data-tab","graph","onVisible",""],0,null,["loc",[null,[28,4],[28,115]]]],
        ["element","action",["statsCustomCSVtable",["get","chart1",["loc",[null,[29,93],[29,99]]]],"chart1DataLoading"],[],["loc",[null,[29,62],[29,121]]]],
        ["element","action",["statsCustomCSVzip",["get","chart1",["loc",[null,[30,91],[30,97]]]],"chart1DataLoading"],[],["loc",[null,[30,62],[30,119]]]],
        ["inline","ui-floatcharts",[],["isLoading",["subexpr","@mut",[["get","charLoading",["loc",[null,[34,32],[34,43]]]]],[],[]],"data",["subexpr","@mut",[["get","chartdata",["loc",[null,[34,49],[34,58]]]]],[],[]],"yaxis",["subexpr","@mut",[["get","yaxis",["loc",[null,[34,65],[34,70]]]]],[],[]],"legend","outside"],["loc",[null,[34,4],[34,90]]]],
        ["inline","t",["technika.stats.chart.table.date"],[],["loc",[null,[40,10],[40,49]]]],
        ["block","each",[["get","sensors",["loc",[null,[41,14],[41,21]]]]],[],1,null,["loc",[null,[41,6],[43,15]]]],
        ["block","each",[["get","table",["loc",[null,[47,12],[47,17]]]]],[],2,null,["loc",[null,[47,4],[54,13]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});