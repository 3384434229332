define('admin/routes/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    templateName: "edit",
    config: {
      into: null,
      controller: null },
    object_id: null,
    setupController: function setupController(controller, model) {
      if (model) {
        this.set("object_id", model.id);
      }
      controller.set("isModal", false);
    },
    model: function model(params) {
      this.set("object_id", params.object_id);
    },
    renderTemplate: function renderTemplate() {
      this._super();
      var controller = this.controllerFor(this.get("config.controller"));
      controller.load(this.get("object_id"));
      this.render("buttons", {
        outlet: "left1",
        into: this.get("config.into"),
        controller: controller
      });
      this.render("navigation", {
        outlet: "left2",
        into: this.get("config.into"),
        controller: controller
      });
      this.render("accordion", {
        outlet: "left3",
        into: this.get("config.into"),
        controller: controller });
      this.render(this.get("config.controller"), {
        outlet: "edit",
        into: this.get("config.into"),
        controller: controller
      });
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor(this.get("config.controller"));
      controller.set("chartdata", []);
      controller.set("table", []);
      this.deepRollback(this.get("controller.model"));
    },
    deepRollback: function deepRollback(model, skipBelongsTo) {
      if (typeof model === "undefined") {
        return;
      }if (model === null) {
        return;
      }if (model.get("hasDirtyAttributes")) {
        model.rollbackAttributes();
      }
      if (typeof model.eachRelationship === "function") {
        return model.eachRelationship((function (key, relationship) {
          //console.log(relationship.kind, key);
          if (relationship.kind === "belongsTo" && !skipBelongsTo) {
            return this.deepRollback(model.get(key));
          } else if (relationship.kind === "hasMany" && !skipBelongsTo) {
            model.get(key).forEach((function (record) {
              this.deepRollback(record, true);
            }).bind(this));
          }
        }).bind(this));
      }
    }
  });

});