define('admin/views/helper/modaledit', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    classNames: ["ui", "fullscreen", "modal"],
    outletName: (function () {
      return this.get("outletSource._parentView.name");
    }).property("outletSource._parentView.name"),
    viewName: (function () {
      return this.get("outletSource._parentView.viewName");
    }).property("outletSource._parentView.viewName"),
    buttons: [],
    tagName: "div",
    buildButtons: (function () {
      this.set("buttons", []);
      if (this.get("outletSource._parentView.type") === "multiselect") {
        this.get("buttons").pushObject({
          title: "app.select.continue",
          action: "positive select"
        });
        this.get("buttons").pushObject({
          title: "app.select.end",
          action: "positive select end"
        });
        this.get("buttons").pushObject({
          title: "app.cancel",
          action: "negative end"
        });
      }
    }).observes("outletSource._parentView.type"),
    name: (function () {
      return this.get("outletSource._parentView.name");
    }).property("outletSource._parentView.name"),
    title: (function () {
      return this.get("outletSource._parentView.type") + "." + this.get("name");
    }).property("name"),
    didInsertElement: function didInsertElement() {
      this._super();
      this.$().data("value", this.get("data-value"));
      this.$().modal({
        padding: 0,
        allowMultiple: true,
        onHidden: (function () {
          this.get("controller").send("closeModal", this.get("outletName"), this.get("viewName"));
          this.$().remove();
        }).bind(this),
        onVisible: (function () {
          if (this.$().find(".admin-modal").length) {
            var leftmenu = this.$().find(".admin-modal .admin-leftmenu");
            var win = this.$().outerWidth() - leftmenu.outerWidth() - 40;
            this.$().find(".admin-modal .admin-content").width(win);
          }
          //var mtop = (jQuery(window).height()- this.$().height()) / 2;
          this.$().modal("refresh");
        }).bind(this),
        selector: {
          close: ".close",
          approve: "",
          deny: ""
        } });
      this.$().delegate(".button", "click", (function (event) {
        var el = event.currentTarget;
        if (jQuery['default'](el).hasClass("select")) {
          this.get("controller").multiselect(this);
        }
        if (jQuery['default'](el).hasClass("singleselect")) {
          this.get("controller").singleselect(this, jQuery['default'](el).data("value"));
        }
        if (jQuery['default'](el).hasClass("end")) {
          this.$().modal("hide");
        }
      }).bind(this));
      this.$().modal("show");
    }
  });

});