define('admin/tests/unit/routes/technika/raport/aggregate-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor("route:technika/raport/aggregate", "Unit | Route | technika/raport/aggregate", {});

  ember_qunit.test("it exists", function (assert) {
    var route = this.subject();
    assert.ok(route);
  });

  // Specify the other units that are required for this test.
  // needs: ['controller:foo']

});