define('admin/routes/new', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    templateName: "edit",
    config: {
      into: null,
      controller: null },
    renderTemplate: function renderTemplate() {
      this._super();
      var controller = this.controllerFor(this.get("config.controller"));
      controller.load(0);
      this.render("buttons", {
        outlet: "left1",
        into: this.get("config.into"),
        controller: controller
      });
      this.render("navigation", {
        outlet: "left2",
        into: this.get("config.into"),
        controller: controller
      });
      this.render(this.get("config.controller"), {
        outlet: "edit",
        into: this.get("config.into"),
        controller: controller
      });
    }
  });

});