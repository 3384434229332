define('admin/controllers/admin/user/edit', ['exports', 'admin/controllers/edit', 'jquery', 'admin/utils/filter-element'], function (exports, Edit, jQuery, FilterElement) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "user",
    redirect: "admin.user.edit",
    companyModalVisible: false,
    groupsModalVisible: false,
    companyList: Ember.inject.controller("admin/company/list"),
    groupList: Ember.inject.controller("admin/group/list"),
    roleList: Ember.inject.controller("admin/role/list"),
    resourcesList: Ember.inject.controller("technika/sensor/list"),
    isNotNew: Ember.computed.not("model.isNew"),
    groupsButtons: [{
      title: "app.select.continue",
      action: "addGroups"
    }, {
      title: "app.select.end",
      action: "addGroupsAndClose"
    }],
    rolesButtons: [{
      title: "app.select.continue",
      action: "addRoles"
    }, {
      title: "app.select.end",
      action: "addRolesAndClose"
    }],
    resourcesButtons: [{
      title: "app.select.continue",
      action: "addResources"
    }, {
      title: "app.select.end",
      action: "addResourcesAndClose"
    }],
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "admin.user.index",
        text: "admin.user.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.user.new",
        text: "admin.user.navigation.new"
      });
    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "admin.user.new.title";
      } else {
        return "admin.user.edit.title";
      }
    }).property("model"),
    isMe: (function () {
      if (this.get("model.id") == this.get("authManager").get("user").get("id")) {
        return true;
      } else {
        return false;
      }
    }).property("model"),
    actions: {
      deleteGroup: function deleteGroup(p1, group) {
        this.get("model.groups").removeObject(group);
      },
      deleteRole: function deleteRole(p1, role) {
        this.get("model.securityRoles").removeObject(role);
      },
      deleteResource: function deleteResource(p1, sensor) {
        this.get("model.sensorsUser").removeObject(sensor);
      },
      addGroups: function addGroups() {
        var controller = this.get("groupList");
        this.get("model.groups").pushObjects(controller.get("multiselectCollection"));
      },
      addGroupsAndClose: function addGroupsAndClose() {
        var controller = this.get("groupList");
        this.get("model.groups").pushObjects(controller.get("multiselectCollection"));
        this.set("groupsModalVisible", false);
      },
      showGroupsModal: function showGroupsModal() {
        var controller = this.get("groupList");
        controller.setMultiselect(true);
        //        controller.set('multiselectType', "company");
        //        controller.set('multiselectTarget', this);
        controller.load();
        this.set("groupsModalVisible", true);
      },
      addRoles: function addRoles() {
        var controller = this.get("roleList");
        this.get("model.securityRoles").pushObjects(controller.get("multiselectCollection"));
      },
      addRolesAndClose: function addRolesAndClose() {
        var controller = this.get("roleList");
        this.get("model.securityRoles").pushObjects(controller.get("multiselectCollection"));
        this.set("rolesModalVisible", false);
      },
      addResources: function addResources() {
        var controller = this.get("resourcesList");;
        this.get("model.sensorsUser").pushObjects(controller.get("multiselectCollection"));
      },
      addResourcesAndClose: function addResourcesAndClose() {
        var controller = this.get("resourcesList");
        this.get("model.sensorsUser").pushObjects(controller.get("multiselectCollection"));
        this.set("resourcesModalVisible", false);
      },
      showRolesModal: function showRolesModal() {
        var controller = this.get("roleList");
        controller.setMultiselect(true);
        //        controller.set('multiselectType', "company");
        //        controller.set('multiselectTarget', this);
        controller.load();
        controller.set("multiselectCollection", []);
        this.set("rolesModalVisible", true);
      },
      showResourcesModal: function showResourcesModal() {
        var controller = this.get("resourcesList");
        controller.set("multiselectCollection", []);
        controller.setMultiselect(true);
        //controller.resetFilters();
        controller.get("filters").addObject(FilterElement['default'].create({
          name: "diff",
          value: this.get("model").get("id"),
          defaultValue: this.get("model").get("id"),
          hidden: true
        }));
        controller.load();
        this.set("resourcesModalVisible", true);
      },
      showCompanyModal: function showCompanyModal() {
        var controller = this.get("companyList");
        controller.setSingleselect(true);
        controller.set("singleselectType", "company");
        controller.set("singleselectTarget", this);
        controller.load();
        this.set("companyModalVisible", true);
      },
      clearCompany: function clearCompany() {
        this.get("model").set("company", null);
      },
      singleselect: function singleselect(model, type) {
        this.get("model").set("company", model);
        this.set("companyModalVisible", false);
      } }
  });

});