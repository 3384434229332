define('admin/components/field-radio', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    label: null,
    name: null,
    model: null,
    value: 1,
    slider: false,
    radioname: (function () {
      return this.get("class") + this.get("name");
    }).property("name", "class"),
    didInsertElement: function didInsertElement() {
      this.$(".ui.radio.checkbox").addClass(this.get("class")).addClass(this.$().attr("id")).checkbox({
        onChecked: (function () {
          //          (this._parentView.$().find('.'+this.get('class')+'.ui.radio.checkbox:not(.'+this.$().attr('id')+')'));
          //          (this._parentView.$().find('.'+this.get('class')+'.ui.radio.checkbox:not(.'+this.$().attr('id')+')').length);
          //          ('.'+this.get('class')+'.ui.radio.checkbox:not(.'+this.$().attr('id')+')');
          this._parentView.$().find("." + this.get("class") + ".ui.radio.checkbox:not(." + this.$().attr("id") + ")").checkbox("uncheck");
          if (this.get("model") && this.get("model").get(this.get("name")) !== undefined) {
            this.get("model").set(this.get("name"), this.get("value"));
          }
        }).bind(this),
        onUnchecked: (function () {
          if (this.get("model") && this.get("model").get(this.get("name")) !== undefined) {
            this.get("model").set(this.get("name"), null);
          }
        }).bind(this)
      });
    },
    checked: (function () {
      if (this.get("model")) {
        return this.get("model." + this.get("name"));
      }
    }).property("value", "model")
  });

});