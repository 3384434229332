define('admin/components/ui-clock', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "span",
    offset: 0,
    timer: null,
    format: "Do MMMM YYYY, HH:mm:ss",
    didRender: function didRender() {
      clearInterval(this.get("timer"));
      this.set("timer", setInterval((function () {
        var time = moment();
        if (this.get("offset") != 0) {
          time.add(this.get("offset"), "milliseconds");
        }
        this.$().text(time.format(this.get("format")));
      }).bind(this), 1000));
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get("timer"));
    }
  });

});