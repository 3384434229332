define('admin/locales/pl/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"
    "no.company.selected": "Proszę uzupełnić pole kontrahent",
    "app.name": "Hydrowskaz.pl",
    "app.loading": "Trwa pobieranie danych...",
    "app.login": "Logowanie do systemu",
    "app.login.info": "Aby korzystać z systemu konieczne jest zalogowanie.",
    "app.login.name": "Nazwa użytkownika",
    "app.login.password": "Hasło",
    "app.login.login": "Zaloguj",
    "app.login.register": "Nie posiadasz konta? <a href=\"{{url}}\">Zarejestruj się</a>.",
    "app.login.failure": "Logowanie zakończone niepowodzeniem.",
    "app.login.success.title": "Logowanie zakończone sukcesem.",
    "app.login.success": "Logowanie zakończone sukcesem.",
    "app.error.authentication": "Błędny login lub hasło.",
    "app.register": "Rejestracja",
    "app.register.info": "W tym miejscu możesz założyć nowe konto.",
    "app.register.email": "Adres e-mail",
    "app.register.password": "Hasło",
    "app.register.firstName": "Imię",
    "app.register.lastName": "Nazwisko",
    "app.register.register": "Zarejestruj",
    "app.register.login": "Posiadasz już konto? <a href=\"{{url}}\">Zaloguj się</a>.",
    "app.cant.save.file": "Plik jest zbyt duży",
    "app.register.failure": "Rejestracja zakończona niepowodzeniem.",
    "captcha.validation.error": "Proszę potwierdzić że nie jesteś robotem.",
    "app.error.validation": "Formularz zawiera błędy.",
    "app.error.form.save": "Błąd zapisu",
    "app.error.list": "Nieudane pobranie listy. Spróbuj odświeżyć. Jeśli błąd się powtórzy skontaktuj się z administratorem.",
    "app.error": "Błąd aplikacji",
    "app.error.data.empty": "Brak danych do zapisania.",
    "app.error.empty": "Brak danych.",
    "app.refresh": "Odśwież",
    "app.filter": "Filtry",
    "app.filter.term": "Fraza",
    "app.filter.term.sensor": "Wyszukiwarka czujników",
    "app.filter.search": "Szukaj",
    "app.filter.clear": "Wyczyść",
    "app.filter.category": "Kategoria",
    "app.select.continue": "Wybierz",
    "app.select.add": "Dodaj",
    "app.select.add.file": "Dodaj plik",
    "app.select.end": "Wybierz i zakończ",
    "app.cancel": "Anuluj",
    "app.save": "Zapisz",
    "app.menu": "Menu",
    "app.operations": "Polecenia",
    "app.edit": "Edycja",
    "app.details": "Szczegóły",
    "app.delete": "Usuń",
    "app.active": "Aktywacja",
    "app.error.form.validation": "Nieudana walidacja formularza",
    "app.error.task.notfound": "Nie znaleziono zadania.",
    "app.field.required": "Pole wymagane",
    "app.field.datepicker.placeholder": "Wybierz datę",
    "app.field.gallery.nbofimages": "Ilość dodanych grafik: {{count}}",
    "app.field.gallery.nboffiles": "Ilość dodanych plików: {{count}}",
    "app.saveAndLeave": "Zapisz i zamknij",

    "application.footer.hydrowskaz": "Hydrowskaz.pl",
    "application.footer.demo": "Demo",
    "application.footer.kontakt": "Kontakt",

    "app.ping": "Przedłużenie sesji",
    "app.ping.message": "Sesja została przedłużona",

    "field.lonlat.lon": "Długość geograficzna (EPSG:4326)",
    "field.lonlat.lat": "Szerokość geograficzna (EPSG:4326)",
    "field.lonlat.select": "Wybierz z mapy",
    "field.lonlat.selectpoint": "Wybierz zaznaczony punkt z mapy",

    "app.notfound.product": "Nie znaleziono produktu",
    "app.notfound.technika.station": "Nie znaleziono stacji",
    "app.notfound.filemanager.object": "Nie znaleziono obiektu dla jakiego mają zostać wgrane pliki",
    "app.notfound.filemanager.class": "Błędna nazwa klasy",
    "app.notfound.filemanager.badfile": "Nie przesłano pliku lub plik jest błędny",
    "app.notfound.filemanager.delete.file": "Nie znaleziono pliku do usunięcia",

    "app.error.cart.parameter.cart": "Brakuje parametru `cart`!",

    //  "pager.nbofresults": "Znaleziono rekordy {{count}}",
    "pager.nbofresults": {
      zero: "Nie znaleziono rekordów.",
      one: "Znaleziono {{count}} rekord.",
      two: "Znaleziono {{count}} rekordy.",
      few: "Znaleziono {{count}} rekordy.",
      many: "Znaleziono {{count}} rekordów.",
      other: "Znaleziono {{count}} rekordów."
    },

    "validation.delivery.type.1": "Proszę wybrać typ sposobu dostawy",

    "app.pager.result.zero": "Nie znaleziono obiektów",
    "app.pager.result.one": "Znaleziono 1 obiekt",
    "app.pager.result.two": "Znaleziono 2 obiekty",
    "app.pager.result.few": "Znaleziono {{count}} obiekty",
    "app.pager.result.many": "Znaleziono {{count}} obiektów",
    "app.pager.result.other": "Znaleziono {{count}} obiektów",

    "component.system.information.header": "Informacje systemowe",
    "component.system.information.subheader": "W tym miejscu widocznych jest 150 ostatnich operacji w systemie.",

    "nav.account.logout": "Wyloguj",

    "account.register.email.notblank": "Proszę podać adres e-mail.",
    "account.register.email.validation": "Błędny format adresu e-mail.",
    "account.register.password.notblank": "Proszę podać hasło.",
    "account.register.firstName.notblank": "Proszę podać imię.",
    "account.register.lastName.notblank": "Proszę podać nazwisko.",

    "ajax.get.success.title": "Żądanie pobrania danych zakończone sukcesem.",
    "ajax.get.success.message": "Wykonano żądanie GET na adres <i>{{url}}</i>.",
    "ajax.post.success.title": "Zapis na serwerze zakończony sukcesem.",
    "ajax.post.success.message": "Wykonano żądanie POST na adres <i>{{url}}</i>.",
    "ajax.put.success.title": "Aktualizacja danych na serwerze zakończona sukcesem.",
    "ajax.put.success.message": "Wykonano żądanie PUT na adres <i>{{url}}</i>.",
    "ajax.error.title": "Błąd operacji `{{type}}` na serwerze ({{status}})",
    "ajax.error.message": "Wykonano żądanie `{{type}}` na adres <i>{{url}}</i>.",

    "menu.depot": "Magazyn",
    "menu.technika.import": "Import",
    "technika.error.station.ftpFilePfx.unique": "Przedrostek pliku musi być unikalny",
    "technika.raport.get.results": "Pokaż raport",
    "menu.technika.raport": "Raporty",
    "technika.raport.daily": "Dzienny",
    "technika.raport.monthly": "Miesięczny",
    "technika.raport.yearly": "Roczny",
    "technika.raport.daily.agregate": "Dzienny agregowany",
    "technika.raport.monthly.agregate": "Miesięczny agregowany",
    "technika.raport.yearly.agregate": "Roczny agregowany",
    "menu.depot.storehouse": "Magazyny",
    "menu.depot.items": "Produkty",
    "menu.admin.usermanager": "Zarządzanie użytkownikami",
    "menu.admin.user": "Użytkownicy",
    "menu.admin.group": "Grupy uprawnień",
    "menu.admin.role": "Role",
    "menu.admin.company": "Kontrahenci",
    "menu.configuration": "Ustawienia systemu",
    "menu.configuration.dictionary": "Słownik",
    "menu.configuration.tax": "Stawki VAT",
    "menu.configuration.systeminfo": "Informacje o systemie",
    "menu.configuration.task": "Menadżer zadań",
    "menu.shop": "Sklep",
    "menu.shop.cashbox": "Kasa",
    "menu.shop.order": "Zamówienia",
    "menu.shop.product": "Produkty",
    "menu.shop.delivery": "Sposoby dostawy",
    "menu.shop.payment": "Sposoby płatności",
    "menu.shop.salegroup": "Grupy sprzedażowe",
    "menu.technika": "Stacje / Czujniki",
    "menu.technika.stations": "Stacje",
    "technika.station.files.header": "Twoje pliki",
    "menu.technika.sensors": "Czujniki",
    "menu.configuration.systeminfo.mysql": "Statystyki serwera BD",

    "menu.account.edit": "Edycja konta",

    "account.edit.title": "Edycja konta",
    "account.navigation.edit": "Edycja konta",
    "account.navigation.password": "Zmiana hasła",
    "account.edit.form.email": "E-mail",
    "account.edit.form.firstName": "Imię",
    "account.edit.form.lastName": "Nazwisko",
    "account.password.title": "Zmiana hasła",
    "account.password.old": "Stare hasło",
    "account.password.new": "Nowe hasło",
    "account.password.repeated": "Powtórz nowe hasło",
    "account.validation.error.old": "Błędne stare hasło.",
    "account.validation.error.new": "Podanie nowego hasła jest wymagane.",
    "account.validation.error.repeated": "Powtórzone hasło jest błędne.",
    "account.navigation.history": "Historia logowania",
    "account.history.list.empty": "Brak historii dla konta.",
    "account.history.list": "Historia konta",
    "account.history.list.type.loginattempt": "Nieudana próba logowania",
    "account.history.list.type.login": "Logowanie na konto",
    "account.history.list.type.login.login": "Logowanie",
    "account.history.list.type.login.logout.manually": "Wylogowanie",
    "account.history.list.type.login.logout.timeout": "Sesja wygasła",

    "admin.role.list.header": "Lista dostępnych ról w systemie",
    "admin.role.list.empty": "Nie znaleziono ról spełniających kryteria wyszukiwania.",
    "admin.role.list.name": "Nazwa",
    "admin.role.list.description": "Opis",
    "admin.role.list.active": "Aktywna?",

    "admin.group.list.header": "Lista dostępnych grup w systemie",
    "admin.group.list.empty": "Nie znaleziono grup spełniających kryteria wyszukiwania.",
    "admin.group.list.name": "Nazwa",
    "admin.group.list.isactive": "Aktywna?",
    "admin.group.navigation.list": "Lista grup",
    "admin.group.navigation.new": "Nowa grupa",
    "admin.group.new.title": "Nowa grupa",
    "admin.group.edit.title": "Edycja grupy",
    "admin.group.edit.form.name": "Nazwa",
    "admin.group.edit.form.isactive": "Określa czy grupa uprawnień jest aktywna",
    "admin.group.edit.form.roles": "Przypisane role",
    "admin.group.edit.form.roles.add": "Dodaj rolę",
    "admin.group.edit.modal.securityroles": "Wybierz role",
    "group.error.name.notblank": "Wymagane jest podanie nazwy grupy.",
    "group.error.securityroles.notblank": "Wymagane jest wybranie conajmniej jednej roli.",

    "admin.company.list.header": "Lista kontrahentów",
    "admin.company.list.name": "Nazwa",
    "admin.company.navigation.list": "Lista  kontrahentów",
    "admin.company.navigation.new": "Nowy kontrahent",
    "admin.company.list.empty": "Nie znaleziono kontrahentów spełniających kryteria wyszukiwania.",
    "admin.company.edit.title": "Edycja kontrahenta",
    "admin.company.new.title": "Nowa kontrahent",
    "admin.company.edit.tab.info": "Informacje o kontrahencie",
    "admin.company.edit.tab.distributor": "Ustawienia dystrybutora",
    "admin.company.edit.tab.organizer": "Ustawienia organizatora",
    "admin.company.edit.form.name": "Nazwa",
    "admin.company.edit.form.continiuedName": "Akronim",
    "admin.company.edit.form.isContractor": "Kontrahent może być dystrybutorem",
    "admin.company.edit.form.isOrganizer": "Kontrahent może być organizatorem",
    "admin.user.edit.form.header_text": "Tekst nagłówka",
    "admin.user.edit.form.footer_text": "Tekst stopki",
    "technika.company.activate.message": "Aktywacja",
    "app.activate": "Aktywacja",

    "admin.user.list.header": "Lista użytkowników",
    "admin.user.list.empty": "Nie znaleziono użytkowników spełniających kryteria wyszukiwania.",
    "admin.user.navigation.list": "Lista użytkowników",
    "admin.user.navigation.new": "Nowy użytkownik",
    "admin.user.list.name": "Nazwisko imię / email",
    "admin.user.list.addresse": "Adres",
    "admin.user.list.isAccount": "Konto umożliwia logowanie?",
    "admin.user.list.isActive": "Konto aktywne?",
    "admin.user.new.title": "Nowy użytkownik",
    "admin.user.edit.title": "Edycja użytkownika",
    "admin.user.edit.tab.info": "Informacje o użytkowniku",
    "admin.user.edit.tab.credentials": "Uprawnienia",
    "admin.user.edit.form.firstName": "Imię",
    "admin.user.edit.form.lastName": "Nazwisko",
    "admin.user.edit.form.isAccount": "Konto umożliwia logowanie?",
    "admin.user.edit.form.company": "Kontrahent",
    "admin.user.edit.form.email": "E-mail (Login)",
    "admin.user.edit.form.addresse": "Adres",
    "admin.user.edit.form.zip": "Kod pocztowy",
    "admin.user.edit.form.city": "Miasto",
    "admin.user.edit.form.groups": "Przypisane grupy uprawnień",
    "admin.user.edit.form.groups.add": "Dodaj grupę uprawnień",
    "admin.user.edit.form.resource": "Dodaj zasób",
    "admin.user.edit.form.resources": "Przypisane zasoby",
    "admin.user.edit.form.resourcesadd": "Dostępne zasoby",
    "admin.user.delete": "Uzunąć użytkownika ? Operacja nieodwracalna.",

    "admin.tax.list.header": "Lista stawek VAT",
    "admin.tax.list.name": "Stawka VAT",
    "admin.tax.list.description": "Opis",
    "admin.tax.list.isDefault": "Domyślna stawka VAT",
    "admin.tax.list.empty": "Nie zdefiniowano stawek podatku VAT",
    "admin.tax.navigation.new": "Nowa stawka VAT",
    "admin.tax.navigation.list": "Lista stawek VAT",
    "admin.tax.edit.title": "Edycja stawki VAT",
    "admin.tax.new.title": "Nowa stawka VAT",
    "admin.tax.edit.form.tax": "Stawka podatku VAT",
    "admin.tax.edit.form.symbol": "Symbol",
    "admin.tax.edit.form.displayName": "Nazwa wyświetlana",
    "admin.tax.edit.form.description": "Opis",
    "admin.tax.edit.form.isDefault": "Domyślna stawka podatku VAT",

    "depot.storehouse.list.header": "Lisa magazynów",
    "depot.storehouse.list.name": "Nazwa",
    "depot.storehouse.list.empty": "Nie znaleziono magazynów spełniających kryteria wyszukiwania.",
    "depot.storehouse.navigation.list": "Lista magazynów",
    "depot.storehouse.navigation.new": "Nowy magazyn",
    "depot.storehouse.new.title": "Nowy magazyn",
    "depot.storehouse.edit.title": "Edycja magazynu",
    "depot.storehouse.edit.form.name": "Nazwa",
    "depot.storehouse.edit.form.addresse": "Adres",
    "depot.storehouse.edit.form.zip": "Kod pocztowy",
    "depot.storehouse.edit.form.city": "Miasto",
    "depot.storehouse.edit.form.company": "Firma",
    "depot.storehouse.list.addresse": "Adres",

    "depot.item.list.header": "Lista dostępnych produktów magazynowych",
    "depot.item.list.empty": "Nie znaleziono produktów magazynowych spełniających kryteria wyszukiwania.",
    "depot.item.navigation.list": "Lista produktów magazynowych",
    "depot.item.navigation.new": "Nowy produkt magazynowy",
    "depot.item.list.name": "Nazwa",
    "depot.item.new.title": "Nowy produkt magazynowy",
    "depot.item.edit.title": "Edycja produktu magazynowego",
    "depot.item.edit.form.name": "Nazwa",
    "depot.item.edit.form.code": "Kod systemowy",
    "depot.item.edit.form.sku": "SKU",
    "depot.item.edit.form.tax": "VAT",
    "depot.item.edit.tab.info": "Informacje o produkcie",
    "depot.item.edit.tab.storehouse": "Stany magazynowe",
    "depot.item.edit.tab.products": "Produkty w sprzedaży",
    "depot.item.operation.add": "Przyjęcie do magazynu",
    "depot.item.edit.form.unitOfMeasure": "Jednostka miary",
    "depot.item.edit.form.ruleOfFreightDelivery": "Sposób wydawania z magazynu",
    "depot.item.list.sku": "SKU",
    "depot.item.list.unitOfMeasure": "Jednostka miary",
    "depot.item.list.ruleOfFreightDelivery": "Sposób wydawania z magazynu",
    "singleselect.itemwarehouse": "Wybór magazynu",

    "depot.add.item.storehouse": "Magazyn",
    "depot.add.item.quantity": "Ilość",
    "depot.add.item.unit_net_price": "Cena jednostkowa netto",
    "depot.add.item.unit_gross_price": "Cena jednostkowa brutto",
    "depot.add.item.gross_price": "Cena brutto",
    "depot.add.item.net_price": "Cena netto",
    "depot.add.item.name": "Nazwa",
    "depot.add.item.sku": "SKU",
    "depot.add.item.code": "Kod systemowy",
    "depot.add.item.tax": "Stawka VAT",
    "new.admission": "Przyjęcie towaru do magazynu",
    "depot.error.warehouseitem.notfound": "Nie znaleziono produtu w magazynie",

    "multiselect.grouproles": "Wybierz role",
    "multiselect.productsalegroup": "Wybierz grupę sprzedaży",
    "multiselect.usergroups": "Wybierz grupę uprawnień",
    "singleselect.usercompany": "Wybierz firmę",

    "admin.dictionary.list.header": "Słownik",
    "admin.dictionary.navigation.list": "Słownik",
    "admin.dictionary.navigation.new": "Nowa pozycja w słowniku",
    "admin.dictionary.new.title": "Nowa pozycja w słowniku",
    "admin.dictionary.edit.title": "Edycja pozycji w słowniku",
    "admin.dictionary.list.empty": "Nie znaleziono pozycji w słowniku spełniających kryteria wyszukiwania.",
    "admin.dictionary.list.category": "Rodzaj słownika",
    "admin.dictionary.list.value": "Wartość",
    "admin.user.edit.form.category": "Rodzaj słownika",
    "admin.user.edit.form.value": "Wartość",
    "admin.dictionary.enum.type": function adminDictionaryEnumType() {
      return [{ key: "unitOfMeasure", value: "Jednostki miary" }, { key: "locationSector", value: "Sektory" }, { key: "timezone", value: "Strefy czasowe" }, { key: "manufacturer", value: "Producent" }, { key: "sensorType", value: "Typ czujnika" }, { key: "dataType", value: "Typ danych" }];
    },
    "admin.enum.ruleOfFreightDelivery": function adminEnumRuleOfFreightDelivery() {
      return [{ key: "LIFO", value: "LIFO (ostatnie przyszło, pierwsze wyszło)" }, { key: "FIFO", value: "FIFO (pierwsze przyszło, pierwsze wyszło)" }, { key: "FEFO", value: "FEFO (pierwsze traci ważność, pierwsze wyszło)" }, { key: "HIFO", value: "HIFO (najdroższe przyszło, pierwsze wyszło)" }, { key: "LOFO", value: "LOFO (najtańsze przyszło, pierwsze wyszło)" }];
    },
    "admin.enum.task.status": function adminEnumTaskStatus() {
      return [{ key: "0", value: "W trakcie" }, { key: "1", value: "Błąd" }, { key: "2", value: "Wyłączony" }, { key: "3", value: "Oczekuje" }];
    },
    "admin.tasks.description": function adminTasksDescription() {
      return [{ key: "task:update", name: "Aktualizacja listy zadań", description: "Aktualizuje listę dostępnych zadań." }, { key: "task:session:clear", name: "Czyszczenie nieaktywnych sesji", description: "Zadanie czyści nieaktywne sesje. Wszyscy nieaktywni użytkownicy zostaną wylogowani." }];
    },

    "systeminformation.version": "Wersja oprogramowania serwerowego:",
    "systeminformation.version.client": "Wersja oprogramowania klienckiego:",
    "systeminformation.version.browser": "Wersja przeglądarki:",
    "systeminformation.vendor": "Aleksander Smołowik",
    "systeminformation.vendor.adresse": "ul. Anieli Krzywoń 1A/11",
    "systeminformation.vendor.zip": "41-800",
    "systeminformation.vendor.city": "Zabrze",
    "systeminformation.vendor.country": "Polska",
    "systeminformation.vendor.phone": "(+48) 692 331 650",
    "systeminformation.vendor.email": "smolowik@gmail.com",
    "systeminformation.email": "e-mail",
    "systeminformation.phone": "tel.",
    "systeminformation.description.short": "Framework dla oprogramowania serwerowego.",

    "task.description.task.session.clear.lifetime": "Długość życia nieaktywnych sesji (w minutach)",

    "admin.task.list.empty": "Nie znaleziono pozycji w menadżerze zadań spełniających kryteria wyszukiwania.",
    "admin.task.list.header": "Menadżer zadań",
    "admin.task.navigation.list": "Menadżer zadań",
    "admin.task.list.name": "Zadanie",
    "admin.task.list.status": "Status",
    "admin.task.list.run": "Uruchomienie",
    "admin.task.list.lastRunDate": "Data ostatniego uruchomienia",
    "admin.task.serverTime": "Czas serwera",
    "admin.task.list.nextRunDate": "Data następnego uruchomienia",
    "admin.task.edit.title": "Edycja zadania",
    "admin.task.edit.name": "Nazwa",
    "admin.task.edit.description": "Opis",
    "admin.task.edit.status": "Status",
    "admin.task.edit.nextRunDate": "Data następnego uruchomienia",
    "admin.task.edit.lastRunDate": "Data ostatniego uruchomienia",
    "admin.task.edit.expression": "Częstotliwość uruchamiania",
    "admin.task.edit.expression.description": "Opis",
    "admin.task.edit.expression.description.year": "rok [opcjonalnie]",
    "admin.task.edit.expression.description.dayofweek": "dzień tygodnia (0 - 7) (Niedziela=0 lub 7)",
    "admin.task.edit.expression.description.month": "miesiąc (1 - 12)",
    "admin.task.edit.expression.description.dayofmonth": "dzień miesiąca (1 - 31)",
    "admin.task.edit.expression.description.hour": "godzina (0 - 23)",
    "admin.task.edit.expression.description.minute": "minuta (0 - 59)",
    "admin.task.edit.expression.description.example": "Przykłady: 3-59/15 2, 6-12 */15 1 2-5. Można używać także skrótów: ",
    "admin.task.edit.expression.description.yearly": "Uruchamiany raz do roku, o północy, pierwszego stycznia - 0 0 1 1 *",
    "admin.task.edit.expression.description.monthly": "Uruchamiany raz w miesiącu, o północy, pierwszego dnia miesiąca - 0 0 1 * *",
    "admin.task.edit.expression.description.weekly": "Uruchamiany raz w tygodniu, o północy w niedzielę - 0 0 * * 0",
    "admin.task.edit.expression.description.daily": "Uruchamiany raz dzieńnie, o północy - 0 0 * * *",
    "admin.task.edit.expression.description.hourly": "uruchamiany raz na godzine, w pierwszej minucie - 0 * * * *",
    "admin.task.edit.option.default": "Wartość domyślna",

    "shop.delivery.navigation.list": "Lista sposobów dostawy",
    "shop.delivery.navigation.new": "Nowy sposób dostawy",
    "shop.delivery.list.header": "Sposoby dostawy",
    "shop.delivery.list.empty": "Brak sposobów dostawy spełniających podane kryteria wyszukiwania",
    "shop.delivery.edit.title": "Edycja sposobu dostawy",
    "shop.delivery.new.title": "Nowy sposób dostawy",
    "shop.delivery.edit.tab.info": "Informacje o sposobie dostawy",
    "shop.delivery.edit.tab.deliveries": "Dostawy",
    "shop.delivery.list": "Lista sposobów dostawy",
    "shop.delivery.list.name": "Nazwa",
    "shop.delivery.list.type": "Typ",
    "shop.delivery.new": "Nowy sposób dostawy",
    "shop.delivery.edit.form.name": "Nazwa",
    "shop.delivery.edit.form.receiptName": "Nazwa na paragonie",
    "shop.delivery.edit.form.type": "Typ",
    "shop.delivery.types": function shopDeliveryTypes() {
      return [
      //{'key': '1', 'value': 'Domyślny', 'isOnline': false},
      { key: "2", value: "Odbiór osobisty", isOnline: false }, { key: "3", value: "Email", isOnline: true, "default": true }];
    },

    "shop.payment.list.header": "Sposoby płatności",
    "shop.payment.list.empty": "Nie znaleziono sposobów płatności spełniających podane kryteria wyszukiwania",
    "shop.payment.navigation.list": "Lista sposobów płatności",
    "shop.payment.navigation.new": "Nowy sposób płatności",
    "shop.payment.edit.tab.info": "Informacje o sposobie płatności",
    "shop.payment.edit.tab.payments": "Płatności",
    "shop.payment.edit.form.name": "Nazwa",
    "shop.payment.edit.form.receiptName": "Nazwa na paragonie",
    "shop.payment.edit.form.type": "Rodzaj",
    "shop.payment.new.title": "Nowy sposób płatności",
    "shop.payment.edit.title": "Edycja sposobu płatności",
    "shop.payment.list.name": "Nazwa",
    "shop.payment.list.type": "Typ",
    "shop.payment.types": function shopPaymentTypes() {
      return [
      //{'key': '1', 'value': 'Domyślny', 'isOnline': false},
      { key: "2", value: "Płatność na miejscu", isOnline: false }, { key: "3", value: "Płatność gotówką", isOnline: true, "default": true }, { key: "4", value: "Przelewy24", isOnline: true, "default": true }];
    },

    "shop.salegroup.list.header": "Lista grup sprzedaży",
    "shop.salegroup.list.name": "Nazwa",
    "shop.salegroup.list.isonline": "Dostępna <span style=\"white-space: nowrap;\">on-line</span>?",
    "shop.salegroup.list.isdefaultonline": "Domyślna <span style=\"white-space: nowrap;\">on-line</span>?",
    "shop.salegroup.list.isactive": "Aktywna?",
    "shop.salegroup.list.empty": "Brak grup sprzedaży spełniających podane kryteria wyszukiwania",
    "shop.salegroup.navigation.list": "Lista grup sprzedaży",
    "shop.salegroup.navigation.new": "Nowa grupa sprzedaży",
    "shop.salegroup.edit.title": "Edycja grupy sprzedaży",
    "shop.salegroup.new.title": "Nowa grupa sprzedaży",
    "shop.salegroup.edit.tab.info": "Informacje o grupie sprzedaży",
    "shop.salegroup.edit.form.name": "Nazwa",
    "shop.salegroup.edit.form.isonline": "Dostępna on-line?",
    "shop.salegroup.edit.form.isdefaultonline": "Domyślna on-line?",
    "shop.salegroup.edit.form.isactive": "Aktywna?",
    "shop.salegroup.edit.form.hash": "API key",
    "shop.salegroup.edit.tab.payments": "Sposoby płatności",
    "shop.salegroup.edit.tab.deliveries": "Sposoby dostawy",
    "shop.salegroup.edit.tab.companies": "Firmy",
    "shop.salegroup.edit.form.companies": "Przypisane firmy",
    "shop.salegroup.edit.form.companies.add": "Dodaj firmę",
    "shop.salegroup.edit.form.deliveries": "Przypisane sposoby dostawy",
    "shop.salegroup.edit.form.deliveries.add": "Dodaj sposób dostawy",
    "shop.salegroup.edit.form.deliveries.isdefault": "Domyślny sposób dostawy?",
    "shop.salegroup.edit.form.payments": "Przypisane sposoby płatności",
    "shop.salegroup.edit.form.payments.add": "Dodaj sposób płatności",
    "shop.salegroup.edit.form.payments.isdefault": "Domyślny sposób płatności?",
    "shop.salegroup.validation.delivery.e1": "Do grupy sprzedaży musi być przypisany conajmniej jeden sposób dostawy.",
    "shop.salegroup.validation.delivery.e2": "Jeden ze sposobów dostawy musi być domyślny.",
    "shop.salegroup.validation.name.e1": "Podanie nazwy jest wymagane.",
    "shop.salegroup.validation.payment.e1": "Do grupy sprzedaży musi być przypisany conajmniej jeden sposób płatności.",
    "shop.salegroup.validation.payment.e2": "Jeden ze sposobów płatności musi być domyślny.",

    "shop.product.list.header": "Produkty",
    "shop.product.list.empty": "Nie znaleziono produktów spełniających podane kryteria wyszukiwania",
    "shop.product.list.name": "Nazwa",
    "shop.product.navigation.list": "Lista produktów",
    "shop.product.navigation.new": "Nowy produkt",
    "shop.product.edit.title": "Edycja produktu",
    "shop.product.new.title": "Nowy produkt",
    "shop.product.edit.tab.info": "Informacje o produkcie",
    "shop.product.edit.form.name": "Nazwa",
    "shop.product.edit.form.publish": "Publikacja?",
    "shop.product.edit.form.grossprice": "Cena brutto",
    "shop.product.form.tax": "VAT",
    "shop.product.edit.form.deliverymethod": "Typ produktu",
    "shop.product.edit.form.salegroup": "Przypisane grupy sprzedaży",
    "shop.product.edit.form.salegroup.add": "Dodaj grupę sprzedaży",
    "shop.product.deliverymethod": function shopProductDeliverymethod() {
      return [{ key: "electronic", value: "Elektroniczny" }, { key: "material", value: "Tradycyjny " }, { key: "both", value: "Elektroniczny i tradycyjny" }];
    },

    "component.search.noresults": "Brak wyników wyszukiwania.",
    "admin.company.edit.form.licenseKey": "Klucz licencji",

    "cashbox.cart": "Koszyk",
    "cashbox.boxoffice": "Kasa",
    "cashbox.products": "Produkty",
    "cashbox.repertoire": "Terminy",
    "cashbox.order": "Zamówienia",

    "cashbox.boxoffice.details": "Zawartość koszyka",
    "cashbox.boxoffice.details.name": "Nazwa",
    "cashbox.boxoffice.details.worth": "Cena jednostkowa",
    "cashbox.boxoffice.details.quantity": "Ilość",
    "cashbox.boxoffice.details.amount": "Wartość",
    "cashbox.boxoffice.details.sum": "SUMA:",

    "cashbox.boxoffice.payments": "Sposób płatności",
    "cashbox.boxoffice.payments.name": "Nazwa",
    "cashbox.boxoffice.payments.amount": "Wpłacona kwota",
    "cashbox.boxoffice.payments.rest": "Reszta",

    "cashbox.boxoffice.customer": "Klient",
    "cashbox.boxoffice.customer.none": "Nie powiązano klienta z zamówieniem",
    "cashbox.boxoffice.customer.name": "Imię",
    "cashbox.boxoffice.customer.surname": "Nazwisko",
    "cashbox.boxoffice.customer.addresse": "Adres",
    "cashbox.boxoffice.customer.email": "E-mail",
    "cashbox.boxoffice.customer.phone": "Telefon",
    "cashbox.boxoffice.customer.notfound": "Nie znaleziono użytkownika.",
    "cashbox.boxoffice.additional": "Ustawienia dodatkowe",
    "cashbox.boxoffice.additional.invitation": "Wydaj jako zaproszenie",
    "cashbox.boxoffice.additional.print": "Wydruk",
    "cashbox.boxoffice.transaction": "Rodzaj potwierdzenia tranzakcji",
    "cashbox.boxoffice.transaction.receipt": "Paragon",
    "cashbox.boxoffice.transaction.invoice": "Faktura",
    "cashbox.boxoffice.transaction.bill": "Rachunek",
    "cashbox.boxoffice.transaction.none": "Brak",
    "cashbox.boxoffice.description": "Uwagi",

    "shop.order.list.header": "Lista zamówień / rezerwacji / koszyków",
    "shop.order.list.empty": "Brak zamówień spełniających podane kryteria wyszukiwania.",
    "shop.order.list.code": "Identyfikator",
    "shop.order.list.quantity": "Ilość elementów",
    "shop.order.list.worth": "Wartość",
    "shop.order.list.createdby": "Sprzedający",
    "shop.order.list.createdfor": "Zamawiający",
    "shop.order.state": function shopOrderState() {
      return [{ key: "0", value: "Koszyk" }, { key: "1", value: "Rezerwacja" }, { key: "2", value: "Zamówienie niezapłacone" }, { key: "3", value: "Zamówienie zapłacone, oczekuje na przygotowanie" }, { key: "4", value: "Zamówienie przygotowane, oczekuje na wydanie/wysłanie" }, { key: "5", value: "Zamówienie wysłane" }, { key: "6", value: "Zamówienie zrealizowane" }, { key: "7", value: "Zamówienie zwrócone/anulowane" }];
    },

    "technika.station.delete.message": "Usunąć stację? Operacja nieodwracalna.",
    "technika.station.list.header": "Lista stacji",
    "technika.station.navigation.list": "Lista stacji",
    "technika.station.navigation.new": "Nowa stacja",
    "technika.station.list.empty": "Nie znaleziono stacji spełniających podane kryteria wyszukiwania.",
    "technika.station.edit.tab.info": "Informacje o stacji",
    "technika.station.new.title": "Nowa stacja",
    "technika.station.edit.form.name": "Nazwa stacji",
    "technika.station.edit.form.description": "Opis",
    "technika.station.edit.form.serial": "Numer seryjny",
    "technika.station.edit.form.lonlat": "Położenie stacji",
    "technika.station.edit.form.timezone": "Strefa czasowa",
    "technika.station.edit.form.ismonitored": "Udostępnienie danych przez API ?",
    "technika.station.edit.form.ignoreTimezone": "Nie uwzględniaj strefy czasowej",
    "technika.station.edit.form.owner": "Właściciel",
    "technika.station.edit.form.sendrawdata": "Nie wygładzaj wykresów",
    "technika.station.edit.form.notloged": "Nie loguj błędów danych",
    "technika.station.edit.tab.sensors": "Czujniki",
    "technika.station.edit.tab.mainenance": "Przeglądy",
    "technika.station.edit.modal.owner": "Wybór właściciela stacji",
    "technika.station.edit.title": "Edycja stacji",
    "technika.station.details.title": "Szczegóły stacji",
    "technika.station.edit.form.gallery": "Galeria",
    "technika.station.edit.form.config.file": "Pliki konfiguracyjne",
    "technika.station.edit.form.active": "Stacja aktywna?",
    "technika.station.edit.form.inactive.date": "Stacja nieaktywna od",
    "technika.station.edit.form.inactive.description": "Komentarz",
    "technika.station.list.name": "Nazwa stacji",
    "technika.station.edit.actions.add.maintenance": "Dodaj przegląd",
    "technika.station.edit.actions.add.sensor": "Dodaj czujnik",
    "technika.error.station.name.required": "Podanie nazwy jest wymagane!",
    "technika.error.station.company.required": "Wybranie właściciela czujnika jest wymagane!",
    "technika.station.maintenance.modal.add": "Dodaj przegląd",
    "technika.station.maintenance.edit.title": "Edycja przeglądu",
    "technika.station.maintenance.new.title": "Nowy przegląd",
    "technika.station.maintanance.delete.message": "Usunąć przgląd ? Operacja nieodwraclana !",
    "technika.station.maintenance.edit.form.description": "Opis",
    "technika.station.maintenance.edit.form.date": "Data przeglądu",
    "technika.station.maintenance.edit.form.internal": "Wewnętrzny",
    "technika.error.maintenance.description.nocredentials": "Nie masz odpowiednich uprawnień aby dodać przegląd do tej stacji.",
    "technika.station.maintenance.list.date": "Data przeglądu",
    "technika.station.maintenance.list.user": "Użytkownik",
    "technika.station.maintenance.list.description": "Opis",
    "technika.station.navigation.map": "Mapa",
    "technika.station.map.layers": "Warstwy",
    "technika.station.map.list": "Wszystkie stacje poglądowo",
    "technika.station.map.showall": "Wszystkie stacje poglądowo",
    "technika.station.edit.actions.clone": "Kopia",
    "technika.station.ftp.importer": "Identyfikator importera",
    "technika.station.ftp.filePfx": "Przedrostek pliku",
    "technika.station.list.ownerName": "Nazwa kontrahenta",
    "technika.station.list.isActive": "Aktywna?",
    "technika.station.list.timezone": "Strefa czasowa",
    "technika.station.data.type": "Typ danych",
    "technika.sensor.list.header": "Lista czujników",
    "technika.sensor.list.name": "Nazwa czujnika",
    "technika.sensor.delete.message": "Usnąć czujnik ? Operacja nieodwracalna",
    "technika.sensor.list.stationname": "Stacja",
    "technika.sensor.list.sensorvalue": "Wartość pomiaru",
    "technika.sensor.list.sensordate": "Data i godzina pomiaru",
    "technika.sensor.list.csv": "CSV",
    "technika.sensor.list.isActive": "Aktywny",
    "technika.sensor.list.formula": "Charakterystyka",
    "technika.sensor.filtr.type": "Rodzaj słownika",
    "technika.sensor.navigation.list": "Lista czujników",
    "technika.sensor.navigation.new": "Nowy czujnik",
    "technika.sensor.navigation.stats": "Widok zaawansowany",
    "technika.sensor.navigation.go.stats": "Pokaż dane z wybranych czujników",
    "technika.sensor.edit.title": "Edycja czujnika",
    "technika.sensor.details.title": "Szczegóły czujnika",
    "technika.sensor.new.title": "Nowy czujnik",
    "technika.sensor.edit.tab.info": "Informacje o czujniku",
    "technika.sensor.edit.tab.warning": "Stany alarmowe",
    "technika.sensor.edit.form.name": "Nazwa czujnika",
    "technika.sensor.edit.form.roundDeviation": "Zmień dane",
    "technika.sensor.edit.form.formulaData": "Parametry wzoru",
    "technika.sensor.edit.form.description": "Opis",
    "technika.sensor.edit.form.serial": "Numer seryjny",
    "technika.sensor.edit.form.sampling": "Interwał próbkowania",
    "technika.sensor.edit.form.alarms": "Stany ostrzegawcze",
    "technika.sensor.edit.form.transmision": "Interwał transmisji",
    "technika.sensor.edit.form.minvalue": "Minimalna wartość",
    "technika.sensor.edit.form.maxValue": "Maksymalna wartość",
    "technika.sensor.edit.form.warningLevel": "Poziom ostrzegawczy",
    "technika.sensor.edit.form.formula": "Charakterystyka czujnika",
    "technika.sensor.edit.form.csvColumn": "Kolumna w pliku CSV",
    "technika.sensor.edit.form.rawId": "ID czujnika źródłowego",
    "technika.sensor.edit.form.alarmLevel": "Poziom alarmowy",
    "technika.sensor.edit.form.gallery": "Galeria",
    "technika.sensor.edit.form.ismonitored": "Udostępnienie przez API ?",
    "technika.sensor.edit.form.manufacturer": "Producent",
    "technika.sensor.edit.form.unit": "Jednostka",
    "technika.sensor.edit.form.defaultgraphtype": "Typ wykresu",
    "technika.sensor.edit.form.type": "Typ czujnika",
    "technika.sensor.edit.form.warning.name": "Nazwa",
    "technika.sensor.edit.form.warning.level_gte": "Powyżej",
    "technika.sensor.edit.form.warning.level_lte": "Poniżej",
    "technika.sensor.edit.form.warning.color": "Kolor",
    "technika.sensor.edit.form.warning.pin": "Wskaźnik na mapie",
    "technika.sensor.edit.form.warning.isActive": "Aktywny?",
    "technika.sensor.edit.form.warning.add": "Dodaj stan ostrzegawczy",
    "technika.sensor.edit.chart.waterlevel": "Poziom wody",
    "technika.sensor.edit.chart.temperature": "Temperatura",
    "technika.sensor.edit.form.active": "Sensor aktywny",
    "technika.sensor.edit.form.service": "Sensor serwisowy",
    "technika.sensor.edit.form.inactive.description": "Komentarz",
    "technika.sensor.edit.form.inactive.date": "Sensor nieaktywny od",
    "technika.sensor.edit.form.topScale": "Sklauj wykres do:",
    "technika.sensor.edit.form.bottomScale": "Skaluj wykres od:",
    "technika.sensor.graph": function technikaSensorGraph() {
      return [
      //{"key": "water-level", "value": "Wykres linowy typ 1"},
      //{"key": "water-temperature", "value": "Wykres linowy typ 2"},
      //{"key": "water-drop", "value": "Wykres słupkowy typ 1"},
      { key: "flot-line", value: "Wykres liniowy flot" }, { key: "flot-bar", value: "Wykres słupkowy flot" }, { key: "flot-radar", value: "Wykres róża wiatrów" }];
    },
    "technika.sensor.add.type": "Wgraj plik",
    "technika.sensor.navigation.new.type": "Nowy wzór",
    "technika.sensor.type.edit.file": "Plik",
    "technika.sensor.type.new.title": "Nowy wzór czujnika",
    "technika.sensor.type.edit.title": "Edycja wzoru czujnika",
    "technika.sensor.edit.form.decimalPoint": "Ilość miejsc po przecinku",
    "technika.sensor.navigation.index": "Widok podstawowy",
    "technika.station.type.error.filenotfound": "Podanie pliku wzoru czytnika jest wymagane!",
    "technika.station.type.error.badnamespace": "Błędny format pliku. Klasa powinna znajdować się w przestrzeni nazw: `Cyfra\\Technika\\SensorBundle\\Types`!",
    "technika.station.type.error.badclassname": "Nazwa pliku oraz nazwa klasy powinny być takie same!",
    "technika.station.type.error.badfile": "Plik musi być plikiem PHP!",
    "technika.station.type.error.badextend": "Klasa musi rozszeżać `BaseType` lub implementować interfejs 'TypeInterface'!",
    "technika.station.type.error.sampling": "Interwał próbkowania musi być mniejszy niż interwał transmisji.",
    "technika.station.type.error.transmision": "Interwał transmisji musi być większy niż interwał prubkowania.",
    "technika.error.sensor.serial.unique": "Wybrany numer seryjny już znajduje się w bazie danych.",
    "technika.error.station.serial.unique": "Wybrany numer seryjny już znajduje się w bazie danych.",
    "technika.error.station.serial.required": "Podanie numeru seryjnego stacji jest wymagane.",
    "technika.error.sensor.badstation": "Do wybranej stacji nie możesz dodać czujnika.",
    "app.notfound.technika.sensor": "Nie znaleziono czujnika.",
    "technika.station.edit.form.station": "Stacja",
    "technika.error.sensor.type.formula": "Proszę wybrać typ czujnika",
    "technika.station.ftp.server": "Ftp adres",
    "technika.station.ftp.login": "Ftp login",
    "technika.station.ftp.password": "Ftp hasło",
    "technika.station.ftp.directory": "Ftp katalog źródłowy",
    "technika.station.ftp.done_directory": "Ftp katalog docelowy",
    "technika.station.ftp.config_directory": "Ftp katalog konfiguracyjny",
    "technika.station.filter.client": "Kontrahent",
    "technika.station.export": "Eksport CSV",
    "technika.station.files": "Twoje pliki",
    "technika.sensor.modal.station": "Wybór stacji",
    "technika.error.sensor.name.required": "Podanie nazwy czujnika jest wymagane!",
    "technika.error.sensor.station.required": "Podanie stacji czujnika jest wymagane!",
    "technika.error.sensor.type.required": "Podanie typu czujnika jest wymagane!",
    "technika.error.sensor.type.nopl": "To pole nie może zawierać polskich znaków",
    "technika.sensor.list.empty": "Nie znaleziono czujników spełniających podane kryteria wyszukiwania.",
    "technika.sensor.chart.button.24hours": "24 godziny",
    "technika.sensor.chart.button.7days": "7 dni",
    "technika.sensor.chart.button.30days": "30 dni",
    "technika.sensor.chart.button.365days": "365 dni",
    "technika.sensor.chart.button.full": "Pełny zakres",
    "technika.station.sensor.modal.add": "Dodawanie nowego czujnika",
    "technika.menu.contact": "Kontakt",
    "technika.contact.form.topic": "Temat",
    "technika.contact.form.description": "Wiadomość",
    "technika.contact.form.sendcopy": "Wyślij kopię do",
    "technika.contact.form.attach": "Załącznik",

    "technika.station.map.sensor": "Czunik",
    "technika.station.map.sensor.date": "Data ostatniego pomiaru",
    "technika.station.map.sensor.value": "Wartość ostatniego pomiaru",

    "technika.stats.chart.table.date": "Data",
    "technika.company.delete.message": "Usunąć firmę ? Operacja nieodwracalna",

    "company.error.name.notblank": "Prosze podac nazwę",
    "company.error.continuedName.notblank": "Proszę podać akronim",
    "admin.company.edit.form.city": "Miejscowość",
    "admin.company.edit.form.zip": "Kod pocztowy",
    "admin.company.edit.form.phone": "Telefon",
    "admin.company.edit.form.info_email": "Email",
    "admin.company.edit.form.users": "Maksymalna liczba użytkowników",
    "admin.company.edit.form.instances": "Maksymalna liczba instancji",
    "admin.company.edit.form.addresse": "Ulica i numer lokalu",

    "This value is not valid.": "Nieprawidłowa wartośc pola",
    "technika.service.delete.message": "Usunąć logi ? Operacja nieodwracalna",
    "technika.serviceone.delete.message": "Usunąć log ? Operacja nieodwracalna"
  };

  //{"key": "wind-direction", "value": "Wykres kołowy"},
  //{"key": "wind-speed", "value": "Róża wiatrów"},
  //{"key": "air-humidity", "value": "Wilgotność powietrza"}

});