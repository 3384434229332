define('admin/components/ui-button', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    classNames: ["ui", "small", "icon", "button"],
    route: null,
    routeObject: null,
    icon: null,
    popup: null,
    translate: true,
    action: null,
    actionController: null,
    actionParam: null,
    "class": null,
    "data-value": null,
    popupobj: null,
    init: function init() {
      if (this.get("route")) {
        this.tagName = "a";
        this.set("attributeBindings", ["href"]);
      } else {
        this.set("attributeBindings", ["data-value"]);
      }
      this._super();
    },
    didInsertElement: function didInsertElement() {
      this._super();
      if (this.get("data-value")) {
        this.$().data("value", this.get("data-value"));
      }
      if (this.get("action") && this.get("actionController")) {
        this.$().on("click", (function () {
          this.get("actionController").send(this.get("action"), this.get("data-value"), this.get("actionParam"));
        }).bind(this));
      } else if (this.get("action")) {
        this.$().on("click", (function () {
          this.triggerAction({
            action: this.get("action"),
            actionContext: { value: this.get("data-value"), param: this.get("actionParam") }
            //target: this
          });
        }).bind(this));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$().popup("hide all");
      this._super();
    },
    href: (function () {
      return Admin.__container__.lookup("route:application").get("router").generate(this.get("route"), this.get("routeObject"));
    }).property("url")
  });

});