define('admin/helpers/has-items', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.helperfunc = helperfunc;

    function helperfunc(params, options) {
        var items = params[0] ? params[0].length : 0;
        if (items > 0) {
            return true;
        } else {
            return false;
        }
    }

    exports['default'] = Ember['default'].Helper.helper(helperfunc);

});