define('admin/models/file', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    file: DS['default'].attr("file"),
    name: DS['default'].attr("string"),
    size: DS['default'].attr("string"),
    category: DS['default'].attr("string"),
    uri: DS['default'].attr("string"),
    src: null,
    //fullPath: null,
    object: DS['default'].attr("string"),
    objectId: DS['default'].attr("string"),
    procesed_at: DS['default'].attr("string"),
    procesed_at_fixed: DS['default'].attr("string"),
    valid_at: DS['default'].attr("string"),
    fullPath: (function () {
      return Admin.get("static") + "/tmp/miner/" + this.get("name");
    }).property("name"),
    onFileChanged: (function () {
      //    ('src changed file');
      var r = new FileReader();
      r.onload = (function (e) {
        this.set("src", e.target.result);
      }).bind(this);
      r.readAsDataURL(this.get("file"));
    }).observes("file"),
    onUriChanged: (function () {
      //    ('src changed uri');
      this.set("src", Admin.get("static") + this.get("uri"));
    }).observes("uri").on("init"),
    fullUri: (function () {
      return Admin.get("static") + "/" + this.get("uri");
    }).property("uri"),
    serverUrl: (function () {
      if (this.get("uri") && this.get("uri").length > 0) {
        return Admin.get("server");
      }
      return null;
    }).property("uri"),
    isImage: (function () {
      if (this.get("category") == "image") {
        return true;
      } else {
        return false;
      }
    }).property("category")

  });

});