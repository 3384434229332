define('admin/components/field-colorpicker', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["ui", "field"],
    model: null,
    name: null,
    value: null,
    init: function init() {
      this._super();
      this.onModel();
    },
    onModel: (function () {
      if (this.get("model")) {
        this.set("value", this.get("model").get(this.get("name")));
        this.get("model").addObserver(this.get("name"), (function () {
          this.set("value", this.get("model").get(this.get("name")));
        }).bind(this));
      }
    }).observes("model"),
    color: Ember['default'].computed("value", function () {
      return new Ember['default'].Handlebars.SafeString("background-color: " + this.get("value"));
    }),
    didInsertElement: function didInsertElement() {
      this.$(".popup").children(".colorpicker").spectrum({
        flat: true,
        showInput: false,
        cancelText: null,
        chooseText: null,
        move: (function (color) {
          this.get("model").set(this.get("name"), color.toHexString());
        }).bind(this)
      });
      //var joe = colorjoe.rgb(this.$('.popup').children('.colorpicker').context, "red");
      this.$(".color").popup({
        popup: this.$(".popup"),
        on: "click"
      });
    }
  });

});