define('admin/templates/admin/task/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 13,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-input",[],["readonly",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[12,42],[12,47]]]]],[],[]],"name","name"],["loc",[null,[12,8],[12,62]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-textarea",[],["readonly",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[15,45],[15,50]]]]],[],[]],"name","description"],["loc",[null,[15,8],[15,72]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-input",[],["readonly",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[18,42],[18,47]]]]],[],[]],"name","displayStatus"],["loc",[null,[18,8],[18,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","moment-js",[["get","model.nextRunDate",["loc",[null,[21,20],[21,37]]]],"dddd, Do MMMM YYYY, HH:mm"],[],["loc",[null,[21,8],[21,68]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 25,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","moment-js",[["get","model.lastRunDate",["loc",[null,[24,20],[24,37]]]],"dddd, Do MMMM YYYY, HH:mm"],[],["loc",[null,[24,8],[24,68]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 6
            },
            "end": {
              "line": 49,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(":");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("code");
          var el2 = dom.createTextNode("\n          *    *    *    *    *    *");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          -    -    -    -    -    -");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          |    |    |    |    |    |");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          |    |    |    |    |    + ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          |    |    |    |    +----- ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          |    |    |    +---------- ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          |    |    +--------------- ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          |    +-------------------- ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          +------------------------- ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("@yearly, @annually - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("@monthly - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("@weekly - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("@daily - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("@hourly - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [9]);
          var element2 = dom.childAt(element1, [29]);
          var morphs = new Array(14);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[2] = dom.createMorphAt(element1,7,7);
          morphs[3] = dom.createMorphAt(element1,10,10);
          morphs[4] = dom.createMorphAt(element1,13,13);
          morphs[5] = dom.createMorphAt(element1,16,16);
          morphs[6] = dom.createMorphAt(element1,19,19);
          morphs[7] = dom.createMorphAt(element1,22,22);
          morphs[8] = dom.createMorphAt(element1,26,26);
          morphs[9] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
          morphs[10] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
          morphs[11] = dom.createMorphAt(dom.childAt(element2, [5]),1,1);
          morphs[12] = dom.createMorphAt(dom.childAt(element2, [7]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element2, [9]),1,1);
          return morphs;
        },
        statements: [
          ["inline","field-input",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[27,28],[27,33]]]]],[],[]],"name","expression"],["loc",[null,[27,8],[27,54]]]],
          ["inline","t",["admin.task.edit.expression.description"],[],["loc",[null,[29,8],[29,54]]]],
          ["inline","t",["admin.task.edit.expression.description.year"],[],["loc",[null,[34,37],[34,88]]]],
          ["inline","t",["admin.task.edit.expression.description.dayofweek"],[],["loc",[null,[35,37],[35,93]]]],
          ["inline","t",["admin.task.edit.expression.description.month"],[],["loc",[null,[36,37],[36,89]]]],
          ["inline","t",["admin.task.edit.expression.description.dayofmonth"],[],["loc",[null,[37,37],[37,94]]]],
          ["inline","t",["admin.task.edit.expression.description.hour"],[],["loc",[null,[38,37],[38,88]]]],
          ["inline","t",["admin.task.edit.expression.description.minute"],[],["loc",[null,[39,37],[39,90]]]],
          ["inline","t",["admin.task.edit.expression.description.example"],[],["loc",[null,[40,10],[40,64]]]],
          ["inline","t",["admin.task.edit.expression.description.yearly"],[],["loc",[null,[42,37],[42,90]]]],
          ["inline","t",["admin.task.edit.expression.description.monthly"],[],["loc",[null,[43,27],[43,81]]]],
          ["inline","t",["admin.task.edit.expression.description.weekly"],[],["loc",[null,[44,26],[44,79]]]],
          ["inline","t",["admin.task.edit.expression.description.daily"],[],["loc",[null,[45,25],[45,77]]]],
          ["inline","t",["admin.task.edit.expression.description.hourly"],[],["loc",[null,[46,26],[46,79]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 8
              },
              "end": {
                "line": 56,
                "column": 8
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("sup");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(": ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(element0,0,0);
            morphs[2] = dom.createMorphAt(element0,2,2);
            return morphs;
          },
          statements: [
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","option",["loc",[null,[54,30],[54,36]]]]],[],[]],"name","value"],["loc",[null,[54,10],[54,52]]]],
            ["inline","t",["admin.task.edit.option.default"],[],["loc",[null,[55,15],[55,53]]]],
            ["content","option.default",["loc",[null,[55,55],[55,73]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 6
            },
            "end": {
              "line": 57,
              "column": 6
            }
          }
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[53,33],[53,43]]]]],[],[]],"field","lastRunDate","label",["subexpr","@mut",[["get","option.description",["loc",[null,[53,70],[53,88]]]]],[],[]]],0,null,["loc",[null,[53,8],[56,27]]]]
        ],
        locals: ["option"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 6
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1,"class","ui left floated header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2,"class","icon edit");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui clearing divider");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui form small");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","two flex fields");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","field");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","field");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  \n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [4, 1]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]),1,1);
        morphs[1] = dom.createMorphAt(element4,1,1);
        morphs[2] = dom.createMorphAt(element4,2,2);
        morphs[3] = dom.createMorphAt(element4,3,3);
        morphs[4] = dom.createMorphAt(element4,4,4);
        morphs[5] = dom.createMorphAt(element4,5,5);
        morphs[6] = dom.createMorphAt(element4,6,6);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        return morphs;
      },
      statements: [
        ["inline","t",[["get","title",["loc",[null,[4,8],[4,13]]]]],[],["loc",[null,[4,4],[4,15]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[11,31],[11,41]]]]],[],[]],"field","name","label","admin.task.edit.name"],0,null,["loc",[null,[11,6],[13,25]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[14,31],[14,41]]]]],[],[]],"field","description","label","admin.task.edit.description"],1,null,["loc",[null,[14,6],[16,25]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[17,31],[17,41]]]]],[],[]],"field","status","label","admin.task.edit.status"],2,null,["loc",[null,[17,6],[19,25]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[20,31],[20,41]]]]],[],[]],"field","nextRunDate","label","admin.task.edit.nextRunDate"],3,null,["loc",[null,[20,6],[22,25]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[23,31],[23,41]]]]],[],[]],"field","lastRunDate","label","admin.task.edit.lastRunDate"],4,null,["loc",[null,[23,6],[25,25]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[26,31],[26,41]]]]],[],[]],"field","expression","label","admin.task.edit.expression"],5,null,["loc",[null,[26,6],[49,25]]]],
        ["block","each",[["get","model.options",["loc",[null,[52,14],[52,27]]]]],[],6,null,["loc",[null,[52,6],[57,15]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});