define('admin/models/technika/sensor/warning', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    level_gte: DS['default'].attr("string"),
    level_lte: DS['default'].attr("string"),
    color: DS['default'].attr("string"),
    pin: DS['default'].attr("string"),
    isActive: DS['default'].attr("boolean"),
    message: DS['default'].attr("string"),
    subject: DS['default'].attr("string"),
    to: DS['default'].attr("string")
  });

});