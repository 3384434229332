define('admin/controllers/admin/dictionary/edit', ['exports', 'admin/controllers/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "dictionary",
    redirect: "admin.dictionary.edit",
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "admin.dictionary.index",
        text: "admin.dictionary.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.dictionary.new",
        text: "admin.dictionary.navigation.new"
      });
    },

    title: (function () {
      if (this.get("model.isNew")) {
        return "admin.dictionary.new.title";
      } else {
        return "admin.dictionary.edit.title";
      }
    }).property("model"),
    save: function save(redirect, callback) {
      this._super(redirect, (function () {
        this.transitionToRoute("admin.dictionary.index");
      }).bind(this));
    },
    actions: {}
  });

});