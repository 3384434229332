define('admin/models/mysql', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    Variable_name: DS['default'].attr("string"),
    Value: DS['default'].attr("string"),
    Label: DS['default'].attr("string"),
    Color: DS['default'].attr("string") });

});