define('admin/controllers/technika/sensor/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element', 'admin/utils/accordion', 'jquery'], function (exports, List, FilterElement, Accoridion, jQuery) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "technika/sensor",
    fastFilter: false,
    sensorDetatils: Ember.inject.controller("technika/sensor/details"),
    init: function init() {
      this._super();
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("navigation").pushObject({
          route: "technika.sensor.new",
          text: "technika.sensor.navigation.new"
        });
        this.get("navigation").pushObject({
          route: "technika.sensor.stats",
          text: "technika.sensor.navigation.stats"
        });
      }
      if (this.get("authManager").get("ROLE_TECHNIKA_USER")) {
        this.get("navigation").pushObject({
          route: "technika.sensor.stats",
          text: "technika.sensor.navigation.stats"
        });
        this.set("hasAccordionNavigation", true);
      }
      if (this.get("authManager").get("ROLE_TECHNIKA_USER")) {
        this.get("filters").addObject(FilterElement['default'].create({
          name: "term",
          label: "app.filter.term"
        }));
        //this.set('navigation',[])
      } else {
        this.get("filters").addObject(FilterElement['default'].create({
          name: "term",
          label: "app.filter.term"
        }));
        this.get("filters").addObject(FilterElement['default'].create({
          name: "companyName",
          label: "technika.station.filter.client"
        }));
      }
    },
    resetFilters: function resetFilters() {
      var filters = this.get("filters");
      if (filters.length > 4) {
        filters.pop();
        this.set("filters", filters);
      }
    },
    actions: {
      showAllStations: function showAllStations() {
        this.transitionToRoute("technika.sensor.index");
      },
      clearOnMap: function clearOnMap() {},
      showOnMap: function showOnMap() {},
      change: function change(filter) {
        filter.set("value", jQuery['default']("input[name=" + filter.name + "]").val());
      }
    }
  });

});