define('admin/helpers/moment-js', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.helperfunc = helperfunc;

  function helperfunc(params, obj) {
    var date = params[0] ? params[0] : new Date();
    var format = params[1];
    if (typeof format !== "string") {
      format = "dddd, Do MMMM YYYY, HH:mm";
    }
    if (format === "fromNow") {
      return moment(date).fromNow();
    } else {
      return moment(date).format(format);
    }
  }

  exports['default'] = Ember['default'].Helper.helper(helperfunc);

});