define('admin/models/user', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    authManager: Ember['default'].inject.service("authManager"),
    firstName: DS['default'].attr("string"),
    companyLogo: DS['default'].attr("string"),
    companyName: DS['default'].attr("string"),
    lastName: DS['default'].attr("string"),
    headerText: DS['default'].attr("string"),
    footerText: DS['default'].attr("string"),
    email: DS['default'].attr("string"),
    isActive: DS['default'].attr("boolean"),
    isAccount: DS['default'].attr("boolean"),
    city: DS['default'].attr("string"),
    zip: DS['default'].attr("string"),
    addresse: DS['default'].attr("string"),
    password: DS['default'].attr("string"),
    phone: DS['default'].attr("string"),
    fullName: (function () {
      var res = this.get("firstName");
      if (this.get("lastName")) {
        res += " ";
        res += this.get("lastName");
      }
      return res;
    }).property("firstName", "lastName"),
    company: DS['default'].belongsTo("company", { async: false }),
    groups: DS['default'].hasMany("group", { async: false }),
    securityRoles: DS['default'].hasMany("securityrole", { async: false }),
    nbOfGroups: (function () {
      return this.get("groups").length;
    }).property("groups"),
    nbOfRoles: (function () {
      return this.get("securityRoles").length;
    }).property("securityRoles"),
    sensorsUser: DS['default'].hasMany("technika/sensor", { async: false }),
    nbOfSensors: (function () {
      return this.get("sensorsUser").length;
    }).property("sensorsUser"),
    isMe: (function () {
      if (this.get("id") == this.get("authManager").get("user").get("id")) {
        return true;
      } else {
        return false;
      }
    }).property("id"),
    hasUsersLimit: DS['default'].attr("boolean")
  });

});