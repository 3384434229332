define('admin/controllers/technika/station/sensor-list', ['exports', 'admin/controllers/technika/sensor/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    init: function init() {
      this._super();
      this.get("filters").addObject(FilterElement['default'].create({
        name: "id" }));
    }
  });

});