define('admin/controllers/technika/station/details', ['exports', 'admin/controllers/technika/station/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    config: {
      into: null,
      controller: null },
    sensorList: Ember.inject.controller("technika/station/sensorList"),
    hevent: Ember.inject.service("hevent"),
    init: function init() {
      this.get("amcharts").load();
      this._super();
      this.set("navigation", []);
      this.set("operations", []);
      this.set("accordion", []);
      this.set("allowSave", false);
      this.get("accordion").pushObject({
        text: "technika.sensor.navigation.list",
        data: this.get("store").find("technika/station")
      });
    },
    onModel: (function () {
      ;
      this.set("isLoading", true);
    }).observes("model.sensors"),

    load: function load(id) {
      this._super(id);
      this.get("hevent").trigger("station-details");
      this.get("store").query("technika/station", { filter: { limit: 1000 } });
    }
  });

});