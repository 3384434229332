define('admin/templates/components/field-datepicker', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 67
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("input");
        dom.setAttribute(el1,"type","text");
        dom.setAttribute(el1,"readonly","readonly");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1,"class","ui button");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2,"class","fa fa-calendar");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'placeholder');
        morphs[1] = dom.createAttrMorph(element0, 'value');
        return morphs;
      },
      statements: [
        ["attribute","placeholder",["concat",[["subexpr","t",["app.field.datepicker.placeholder"],[],["loc",[null,[1,54],[1,94]]]]]]],
        ["attribute","value",["concat",[["subexpr","moment-js",[["get","value",["loc",[null,[1,115],[1,120]]]],"dddd, Do MMMM YYYY"],[],["loc",[null,[1,103],[1,143]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});