define('admin/models/task', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    command: DS['default'].attr("string"),
    expression: DS['default'].attr("string"),
    status: DS['default'].attr("string"),
    lastRunDate: DS['default'].attr("string"),
    lastRunFinishDate: DS['default'].attr("string"),
    nextRunDate: DS['default'].attr("string"),
    runTime: DS['default'].attr("string"),
    lastRunLog: DS['default'].attr("string"),
    arguments: DS['default'].attr("string"),
    displayStatus: (function () {
      var res = this.get("i18n").t("admin.enum.task.status").findBy("key", this.get("status"));
      if (res) {
        return res.value;
      }
    }).property("status")
  });

});