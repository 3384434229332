define('admin/helpers/currdate', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.helperfunc = helperfunc;

    function helperfunc(params, options) {
        var d = new Date();
        var p = params[1] == "month" ? d.getMonth() + 1 : d.getFullYear();
        console.log(params[0], p);
        if (params[0] == p) {
            return true;
        } else {
            return false;
        }
    }

    exports['default'] = Ember['default'].Helper.helper(helperfunc);

});