define('admin/templates/technika/sensor/details', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 96
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" - ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","model.station.name",["loc",[null,[6,57],[6,79]]]],
          ["content","model.name",["loc",[null,[6,82],[6,96]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 8
            },
            "end": {
              "line": 12,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","ui medium image");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element54, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","model.imageUrl",["loc",[null,[11,46],[11,60]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 8
            },
            "end": {
              "line": 14,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 12
            },
            "end": {
              "line": 32,
              "column": 12
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","moment-js",[["get","model.sensorDate",["loc",[null,[31,33],[31,49]]]],"DD.MM.YYYY HH:mm"],[],["loc",[null,[31,20],[31,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 12
            },
            "end": {
              "line": 34,
              "column": 12
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 14
            },
            "end": {
              "line": 52,
              "column": 14
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.manufacturer.value",["loc",[null,[51,16],[51,46]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 14
            },
            "end": {
              "line": 54,
              "column": 14
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                -\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 14
            },
            "end": {
              "line": 58,
              "column": 14
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.type_display_text",["loc",[null,[57,16],[57,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 14
            },
            "end": {
              "line": 60,
              "column": 14
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  -\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 14
            },
            "end": {
              "line": 64,
              "column": 14
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.serial",["loc",[null,[63,16],[63,34]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 14
            },
            "end": {
              "line": 66,
              "column": 14
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    -\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.8",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 12
                },
                "end": {
                  "line": 94,
                  "column": 12
                }
              }
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3,"class","green check circle outline icon");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element52 = dom.childAt(fragment, [1]);
              var element53 = dom.childAt(element52, [7, 0]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element52, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element52, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element52, [5]),0,0);
              morphs[3] = dom.createAttrMorph(element53, 'style');
              return morphs;
            },
            statements: [
              ["content","warning.name",["loc",[null,[88,24],[88,40]]]],
              ["content","warning.level_lte",["loc",[null,[89,24],[89,45]]]],
              ["content","warning.level_gte",["loc",[null,[90,24],[90,45]]]],
              ["attribute","style",["concat",["background-color: ",["get","warning.color",["loc",[null,[91,56],[91,69]]]],"; width: 20px; height:20px"]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.8",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 12
                },
                "end": {
                  "line": 104,
                  "column": 12
                }
              }
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"style","background-color:#f0f0f0");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"style","background-color:#f0f0f0");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"style","background-color:#f0f0f0");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"style","background-color:#f0f0f0");
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"style","background-color: gray; width: 20px; height:20px");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"style","background-color:#f0f0f0");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3,"class","red remove circle outline icon");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element51 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element51, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element51, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element51, [5]),0,0);
              return morphs;
            },
            statements: [
              ["content","warning.name",["loc",[null,[96,57],[96,73]]]],
              ["content","warning.level_lte",["loc",[null,[97,57],[97,78]]]],
              ["content","warning.level_gte",["loc",[null,[98,57],[98,78]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 8
              },
              "end": {
                "line": 105,
                "column": 8
              }
            }
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","warning.isActive",["loc",[null,[86,18],[86,34]]]]],[],0,1,["loc",[null,[86,12],[104,19]]]]
          ],
          locals: ["warning"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 8
            },
            "end": {
              "line": 106,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.warnings",["loc",[null,[85,16],[85,30]]]]],[],0,null,["loc",[null,[85,8],[105,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 8
            },
            "end": {
              "line": 110,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2,"colspan","6");
          dom.setAttribute(el2,"style","text-align:center; background-color:#f0f0f0");
          var el3 = dom.createElement("i");
          var el4 = dom.createTextNode("Brak zdefiniowanych stanów alarmowych");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 4
            },
            "end": {
              "line": 136,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui form item");
          dom.setAttribute(el1,"style","margin-top: 20px");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","one flex fields");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","four flex fields");
          dom.setAttribute(el2,"style","margin-top: 5px");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("Pokaż");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element43 = dom.childAt(fragment, [1]);
          var element44 = dom.childAt(element43, [1, 1]);
          var element45 = dom.childAt(element44, [1]);
          var element46 = dom.childAt(element44, [3]);
          var element47 = dom.childAt(element44, [5]);
          var element48 = dom.childAt(element44, [7]);
          var element49 = dom.childAt(element43, [3]);
          var element50 = dom.childAt(element49, [5, 1]);
          var morphs = new Array(15);
          morphs[0] = dom.createAttrMorph(element45, 'class');
          morphs[1] = dom.createElementMorph(element45);
          morphs[2] = dom.createMorphAt(element45,0,0);
          morphs[3] = dom.createAttrMorph(element46, 'class');
          morphs[4] = dom.createElementMorph(element46);
          morphs[5] = dom.createMorphAt(element46,0,0);
          morphs[6] = dom.createAttrMorph(element47, 'class');
          morphs[7] = dom.createElementMorph(element47);
          morphs[8] = dom.createMorphAt(element47,0,0);
          morphs[9] = dom.createAttrMorph(element48, 'class');
          morphs[10] = dom.createElementMorph(element48);
          morphs[11] = dom.createMorphAt(element48,0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element49, [1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element49, [3]),1,1);
          morphs[14] = dom.createElementMorph(element50);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[118,39],[118,42]]]]]]],
          ["element","action",["stats24Hours",["get","chart1",["loc",[null,[118,70],[118,76]]]],"chart1DataLoading"],[],["loc",[null,[118,46],[118,98]]]],
          ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[118,99],[118,143]]]],
          ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[119,39],[119,41]]]]]]],
          ["element","action",["stats7Days",["get","chart1",["loc",[null,[119,67],[119,73]]]],"chart1DataLoading"],[],["loc",[null,[119,45],[119,95]]]],
          ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[119,96],[119,138]]]],
          ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[120,39],[120,42]]]]]]],
          ["element","action",["stats30Days",["get","chart1",["loc",[null,[120,69],[120,75]]]],"chart1DataLoading"],[],["loc",[null,[120,46],[120,97]]]],
          ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[120,98],[120,141]]]],
          ["attribute","class",["concat",["ui button ",["get","365d",["loc",[null,[121,39],[121,43]]]]]]],
          ["element","action",["stats365Days",["get","chart1",["loc",[null,[121,71],[121,77]]]],"chart1DataLoading"],[],["loc",[null,[121,47],[121,99]]]],
          ["inline","t",["technika.sensor.chart.button.365days"],[],["loc",[null,[121,100],[121,144]]]],
          ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[126,12],[126,41]]]],
          ["inline","field-date",[],["ids","dateTo"],["loc",[null,[129,12],[129,39]]]],
          ["element","action",["statsCustom",["get","chart1",["loc",[null,[132,61],[132,67]]]],"chart1DataLoading"],[],["loc",[null,[132,38],[132,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 137,
              "column": 4
            },
            "end": {
              "line": 159,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui form item");
          dom.setAttribute(el1,"style","margin-top: 20px");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","one flex fields");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","four flex fields");
          dom.setAttribute(el2,"style","margin-top: 5px");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("Pokaż");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element35 = dom.childAt(fragment, [1]);
          var element36 = dom.childAt(element35, [1, 1]);
          var element37 = dom.childAt(element36, [1]);
          var element38 = dom.childAt(element36, [3]);
          var element39 = dom.childAt(element36, [5]);
          var element40 = dom.childAt(element36, [7]);
          var element41 = dom.childAt(element35, [3]);
          var element42 = dom.childAt(element41, [5, 1]);
          var morphs = new Array(15);
          morphs[0] = dom.createAttrMorph(element37, 'class');
          morphs[1] = dom.createElementMorph(element37);
          morphs[2] = dom.createMorphAt(element37,0,0);
          morphs[3] = dom.createAttrMorph(element38, 'class');
          morphs[4] = dom.createElementMorph(element38);
          morphs[5] = dom.createMorphAt(element38,0,0);
          morphs[6] = dom.createAttrMorph(element39, 'class');
          morphs[7] = dom.createElementMorph(element39);
          morphs[8] = dom.createMorphAt(element39,0,0);
          morphs[9] = dom.createAttrMorph(element40, 'class');
          morphs[10] = dom.createElementMorph(element40);
          morphs[11] = dom.createMorphAt(element40,0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element41, [1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element41, [3]),1,1);
          morphs[14] = dom.createElementMorph(element42);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[141,39],[141,42]]]]]]],
          ["element","action",["stats24Hours",["get","chart1",["loc",[null,[141,70],[141,76]]]],"chart1DataLoading"],[],["loc",[null,[141,46],[141,98]]]],
          ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[141,99],[141,143]]]],
          ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[142,39],[142,41]]]]]]],
          ["element","action",["stats7Days",["get","chart1",["loc",[null,[142,67],[142,73]]]],"chart1DataLoading"],[],["loc",[null,[142,45],[142,95]]]],
          ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[142,96],[142,138]]]],
          ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[143,39],[143,42]]]]]]],
          ["element","action",["stats30Days",["get","chart1",["loc",[null,[143,69],[143,75]]]],"chart1DataLoading"],[],["loc",[null,[143,46],[143,97]]]],
          ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[143,98],[143,141]]]],
          ["attribute","class",["concat",["ui button ",["get","365d",["loc",[null,[144,39],[144,43]]]]]]],
          ["element","action",["stats365Days",["get","chart1",["loc",[null,[144,71],[144,77]]]],"chart1DataLoading"],[],["loc",[null,[144,47],[144,99]]]],
          ["inline","t",["technika.sensor.chart.button.365days"],[],["loc",[null,[144,100],[144,144]]]],
          ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[149,12],[149,41]]]],
          ["inline","field-date",[],["ids","dateTo"],["loc",[null,[152,12],[152,39]]]],
          ["element","action",["statsCustom",["get","chart1",["loc",[null,[155,61],[155,67]]]],"chart1DataLoading"],[],["loc",[null,[155,38],[155,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 160,
              "column": 4
            },
            "end": {
              "line": 182,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui form item");
          dom.setAttribute(el1,"style","margin-top: 20px");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","one flex fields");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","four flex fields");
          dom.setAttribute(el2,"style","margin-top: 5px");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("Pokaż");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [1]);
          var element28 = dom.childAt(element27, [1, 1]);
          var element29 = dom.childAt(element28, [1]);
          var element30 = dom.childAt(element28, [3]);
          var element31 = dom.childAt(element28, [5]);
          var element32 = dom.childAt(element28, [7]);
          var element33 = dom.childAt(element27, [3]);
          var element34 = dom.childAt(element33, [5, 1]);
          var morphs = new Array(15);
          morphs[0] = dom.createAttrMorph(element29, 'class');
          morphs[1] = dom.createElementMorph(element29);
          morphs[2] = dom.createMorphAt(element29,0,0);
          morphs[3] = dom.createAttrMorph(element30, 'class');
          morphs[4] = dom.createElementMorph(element30);
          morphs[5] = dom.createMorphAt(element30,0,0);
          morphs[6] = dom.createAttrMorph(element31, 'class');
          morphs[7] = dom.createElementMorph(element31);
          morphs[8] = dom.createMorphAt(element31,0,0);
          morphs[9] = dom.createAttrMorph(element32, 'class');
          morphs[10] = dom.createElementMorph(element32);
          morphs[11] = dom.createMorphAt(element32,0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element33, [1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element33, [3]),1,1);
          morphs[14] = dom.createElementMorph(element34);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[164,39],[164,42]]]]]]],
          ["element","action",["stats24Hours",["get","chart1",["loc",[null,[164,70],[164,76]]]],"chart1DataLoading"],[],["loc",[null,[164,46],[164,98]]]],
          ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[164,99],[164,143]]]],
          ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[165,39],[165,41]]]]]]],
          ["element","action",["stats7Days",["get","chart1",["loc",[null,[165,67],[165,73]]]],"chart1DataLoading"],[],["loc",[null,[165,45],[165,95]]]],
          ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[165,96],[165,138]]]],
          ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[166,39],[166,42]]]]]]],
          ["element","action",["stats30Days",["get","chart1",["loc",[null,[166,69],[166,75]]]],"chart1DataLoading"],[],["loc",[null,[166,46],[166,97]]]],
          ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[166,98],[166,141]]]],
          ["attribute","class",["concat",["ui button ",["get","365d",["loc",[null,[167,39],[167,43]]]]]]],
          ["element","action",["stats365Days",["get","chart1",["loc",[null,[167,71],[167,77]]]],"chart1DataLoading"],[],["loc",[null,[167,47],[167,99]]]],
          ["inline","t",["technika.sensor.chart.button.365days"],[],["loc",[null,[167,100],[167,144]]]],
          ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[172,12],[172,41]]]],
          ["inline","field-date",[],["ids","dateTo"],["loc",[null,[175,12],[175,39]]]],
          ["element","action",["statsCustom",["get","chart1",["loc",[null,[178,61],[178,67]]]],"chart1DataLoading"],[],["loc",[null,[178,38],[178,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 183,
              "column": 4
            },
            "end": {
              "line": 206,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui form item");
          dom.setAttribute(el1,"style","margin-top: 20px");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","one flex fields");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","four flex fields");
          dom.setAttribute(el2,"style","margin-top: 5px");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("Pokaż");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("CSV");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var element18 = dom.childAt(element17, [1, 1]);
          var element19 = dom.childAt(element18, [1]);
          var element20 = dom.childAt(element18, [3]);
          var element21 = dom.childAt(element18, [5]);
          var element22 = dom.childAt(element18, [7]);
          var element23 = dom.childAt(element17, [3]);
          var element24 = dom.childAt(element23, [5]);
          var element25 = dom.childAt(element24, [1]);
          var element26 = dom.childAt(element24, [3]);
          var morphs = new Array(16);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          morphs[1] = dom.createElementMorph(element19);
          morphs[2] = dom.createMorphAt(element19,0,0);
          morphs[3] = dom.createAttrMorph(element20, 'class');
          morphs[4] = dom.createElementMorph(element20);
          morphs[5] = dom.createMorphAt(element20,0,0);
          morphs[6] = dom.createAttrMorph(element21, 'class');
          morphs[7] = dom.createElementMorph(element21);
          morphs[8] = dom.createMorphAt(element21,0,0);
          morphs[9] = dom.createAttrMorph(element22, 'class');
          morphs[10] = dom.createElementMorph(element22);
          morphs[11] = dom.createMorphAt(element22,0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element23, [1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element23, [3]),1,1);
          morphs[14] = dom.createElementMorph(element25);
          morphs[15] = dom.createElementMorph(element26);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[187,39],[187,42]]]]]]],
          ["element","action",["stats24Hours",["get","chart1",["loc",[null,[187,70],[187,76]]]],"chart1DataLoading"],[],["loc",[null,[187,46],[187,98]]]],
          ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[187,99],[187,143]]]],
          ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[188,39],[188,41]]]]]]],
          ["element","action",["stats7Days",["get","chart1",["loc",[null,[188,67],[188,73]]]],"chart1DataLoading"],[],["loc",[null,[188,45],[188,95]]]],
          ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[188,96],[188,138]]]],
          ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[189,39],[189,42]]]]]]],
          ["element","action",["stats30Days",["get","chart1",["loc",[null,[189,69],[189,75]]]],"chart1DataLoading"],[],["loc",[null,[189,46],[189,97]]]],
          ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[189,98],[189,141]]]],
          ["attribute","class",["concat",["ui button ",["get","365d",["loc",[null,[190,39],[190,43]]]]]]],
          ["element","action",["stats365Days",["get","chart1",["loc",[null,[190,71],[190,77]]]],"chart1DataLoading"],[],["loc",[null,[190,47],[190,99]]]],
          ["inline","t",["technika.sensor.chart.button.365days"],[],["loc",[null,[190,100],[190,144]]]],
          ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[195,12],[195,41]]]],
          ["inline","field-date",[],["ids","dateTo"],["loc",[null,[198,12],[198,39]]]],
          ["element","action",["statsCustom",["get","chart1",["loc",[null,[201,61],[201,67]]]],"chart1DataLoading"],[],["loc",[null,[201,38],[201,89]]]],
          ["element","action",["statsCustomCSV",["get","chart1",["loc",[null,[202,64],[202,70]]]],"chart1DataLoading"],[],["loc",[null,[202,38],[202,92]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 207,
              "column": 4
            },
            "end": {
              "line": 229,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui form item");
          dom.setAttribute(el1,"style","margin-top: 20px");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","one flex fields");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","four flex fields");
          dom.setAttribute(el2,"style","margin-top: 5px");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("Pokaż");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(element9, [1, 1]);
          var element11 = dom.childAt(element10, [1]);
          var element12 = dom.childAt(element10, [3]);
          var element13 = dom.childAt(element10, [5]);
          var element14 = dom.childAt(element10, [7]);
          var element15 = dom.childAt(element9, [3]);
          var element16 = dom.childAt(element15, [5, 1]);
          var morphs = new Array(15);
          morphs[0] = dom.createAttrMorph(element11, 'class');
          morphs[1] = dom.createElementMorph(element11);
          morphs[2] = dom.createMorphAt(element11,0,0);
          morphs[3] = dom.createAttrMorph(element12, 'class');
          morphs[4] = dom.createElementMorph(element12);
          morphs[5] = dom.createMorphAt(element12,0,0);
          morphs[6] = dom.createAttrMorph(element13, 'class');
          morphs[7] = dom.createElementMorph(element13);
          morphs[8] = dom.createMorphAt(element13,0,0);
          morphs[9] = dom.createAttrMorph(element14, 'class');
          morphs[10] = dom.createElementMorph(element14);
          morphs[11] = dom.createMorphAt(element14,0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element15, [3]),1,1);
          morphs[14] = dom.createElementMorph(element16);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[211,39],[211,42]]]]]]],
          ["element","action",["stats24Hours",["get","chart1",["loc",[null,[211,70],[211,76]]]],"chart1DataLoading"],[],["loc",[null,[211,46],[211,98]]]],
          ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[211,99],[211,143]]]],
          ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[212,39],[212,41]]]]]]],
          ["element","action",["stats7Days",["get","chart1",["loc",[null,[212,67],[212,73]]]],"chart1DataLoading"],[],["loc",[null,[212,45],[212,95]]]],
          ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[212,96],[212,138]]]],
          ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[213,39],[213,42]]]]]]],
          ["element","action",["stats30Days",["get","chart1",["loc",[null,[213,69],[213,75]]]],"chart1DataLoading"],[],["loc",[null,[213,46],[213,97]]]],
          ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[213,98],[213,141]]]],
          ["attribute","class",["concat",["ui button ",["get","365d",["loc",[null,[214,39],[214,43]]]]]]],
          ["element","action",["stats365Days",["get","chart1",["loc",[null,[214,71],[214,77]]]],"chart1DataLoading"],[],["loc",[null,[214,47],[214,99]]]],
          ["inline","t",["technika.sensor.chart.button.365days"],[],["loc",[null,[214,100],[214,144]]]],
          ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[219,12],[219,41]]]],
          ["inline","field-date",[],["ids","dateTo"],["loc",[null,[222,12],[222,39]]]],
          ["element","action",["statsCustom",["get","chart1",["loc",[null,[225,61],[225,67]]]],"chart1DataLoading"],[],["loc",[null,[225,38],[225,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 230,
              "column": 4
            },
            "end": {
              "line": 252,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui form item");
          dom.setAttribute(el1,"style","margin-top: 20px");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","one flex fields");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","four flex fields");
          dom.setAttribute(el2,"style","margin-top: 5px");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          dom.setAttribute(el3,"style","margin-right: 2px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","ui button");
          var el5 = dom.createTextNode("Pokaż");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1, 1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(element2, [5]);
          var element6 = dom.childAt(element2, [7]);
          var element7 = dom.childAt(element1, [3]);
          var element8 = dom.childAt(element7, [5, 1]);
          var morphs = new Array(15);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createMorphAt(element3,0,0);
          morphs[3] = dom.createAttrMorph(element4, 'class');
          morphs[4] = dom.createElementMorph(element4);
          morphs[5] = dom.createMorphAt(element4,0,0);
          morphs[6] = dom.createAttrMorph(element5, 'class');
          morphs[7] = dom.createElementMorph(element5);
          morphs[8] = dom.createMorphAt(element5,0,0);
          morphs[9] = dom.createAttrMorph(element6, 'class');
          morphs[10] = dom.createElementMorph(element6);
          morphs[11] = dom.createMorphAt(element6,0,0);
          morphs[12] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element7, [3]),1,1);
          morphs[14] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["ui button ",["get","24h",["loc",[null,[234,39],[234,42]]]]]]],
          ["element","action",["stats24Hours",["get","chart3",["loc",[null,[234,70],[234,76]]]],"chart1DataLoading"],[],["loc",[null,[234,46],[234,98]]]],
          ["inline","t",["technika.sensor.chart.button.24hours"],[],["loc",[null,[234,99],[234,143]]]],
          ["attribute","class",["concat",["ui button ",["get","7d",["loc",[null,[235,39],[235,41]]]]]]],
          ["element","action",["stats7Days",["get","chart3",["loc",[null,[235,67],[235,73]]]],"chart1DataLoading"],[],["loc",[null,[235,45],[235,95]]]],
          ["inline","t",["technika.sensor.chart.button.7days"],[],["loc",[null,[235,96],[235,138]]]],
          ["attribute","class",["concat",["ui button ",["get","30d",["loc",[null,[236,39],[236,42]]]]]]],
          ["element","action",["stats30Days",["get","chart3",["loc",[null,[236,69],[236,75]]]],"chart1DataLoading"],[],["loc",[null,[236,46],[236,97]]]],
          ["inline","t",["technika.sensor.chart.button.30days"],[],["loc",[null,[236,98],[236,141]]]],
          ["attribute","class",["concat",["ui button ",["get","365d",["loc",[null,[237,39],[237,43]]]]]]],
          ["element","action",["stats365Days",["get","chart3",["loc",[null,[237,71],[237,77]]]],"chart1DataLoading"],[],["loc",[null,[237,47],[237,99]]]],
          ["inline","t",["technika.sensor.chart.button.365days"],[],["loc",[null,[237,100],[237,144]]]],
          ["inline","field-date",[],["ids","dateFrom"],["loc",[null,[242,12],[242,41]]]],
          ["inline","field-date",[],["ids","dateTo"],["loc",[null,[245,12],[245,39]]]],
          ["element","action",["statsCustom",["get","chart3",["loc",[null,[248,61],[248,67]]]],"chart1DataLoading"],[],["loc",[null,[248,38],[248,89]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 256,
              "column": 4
            },
            "end": {
              "line": 256,
              "column": 97
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Graficzne przedstawienie pomiarów");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 260,
              "column": 8
            },
            "end": {
              "line": 264,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          dom.setAttribute(el1,"style","margin-right: 2px");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","checkbox");
          dom.setAttribute(el2,"class","show_alarms");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Pokaż stany alarmowe\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'onclick');
          return morphs;
        },
        statements: [
          ["attribute","onclick",["subexpr","action",["getAlarms"],["value","target.checked"],["loc",[null,[262,63],[262,108]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 264,
              "column": 8
            },
            "end": {
              "line": 266,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1,"type","checkbox");
          dom.setAttribute(el1,"class","show_alarms");
          dom.setAttribute(el1,"style","display: none");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 267,
              "column": 8
            },
            "end": {
              "line": 269,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[268,32],[268,38]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[268,49],[268,66]]]]],[],[]]],["loc",[null,[268,12],[268,68]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 270,
              "column": 8
            },
            "end": {
              "line": 272,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[271,32],[271,38]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[271,49],[271,66]]]]],[],[]]],["loc",[null,[271,12],[271,68]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 273,
              "column": 8
            },
            "end": {
              "line": 275,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart3",["loc",[null,[274,32],[274,38]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[274,49],[274,66]]]]],[],[]]],["loc",[null,[274,12],[274,68]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 276,
              "column": 8
            },
            "end": {
              "line": 278,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-floatcharts",[],["sensor",["subexpr","@mut",[["get","model",["loc",[null,[277,36],[277,41]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","charLoading",["loc",[null,[277,52],[277,63]]]]],[],[]],"legend","outside","data",["subexpr","@mut",[["get","chartdata",["loc",[null,[277,86],[277,95]]]]],[],[]],"yaxis",["subexpr","@mut",[["get","yaxis",["loc",[null,[277,102],[277,107]]]]],[],[]],"warnings",["subexpr","@mut",[["get","model.warnings",["loc",[null,[277,117],[277,131]]]]],[],[]]],["loc",[null,[277,12],[277,134]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 279,
              "column": 8
            },
            "end": {
              "line": 281,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-floatcharts",[],["sensor",["subexpr","@mut",[["get","model",["loc",[null,[280,36],[280,41]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","charLoading",["loc",[null,[280,52],[280,63]]]]],[],[]],"legend","outside","data",["subexpr","@mut",[["get","chartdata",["loc",[null,[280,86],[280,95]]]]],[],[]],"yaxis",["subexpr","@mut",[["get","yaxis",["loc",[null,[280,102],[280,107]]]]],[],[]],"warnings",["subexpr","@mut",[["get","model.warnings",["loc",[null,[280,117],[280,131]]]]],[],[]]],["loc",[null,[280,12],[280,134]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 282,
              "column": 8
            },
            "end": {
              "line": 284,
              "column": 8
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-flotradarcharts",[],["sensor",["subexpr","@mut",[["get","model",["loc",[null,[283,40],[283,45]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","charLoading",["loc",[null,[283,56],[283,67]]]]],[],[]],"legend","outside","data",["subexpr","@mut",[["get","chartdata",["loc",[null,[283,90],[283,99]]]]],[],[]],"yaxis",["subexpr","@mut",[["get","yaxis",["loc",[null,[283,106],[283,111]]]]],[],[]],"warnings",["subexpr","@mut",[["get","model.warnings",["loc",[null,[283,121],[283,135]]]]],[],[]]],["loc",[null,[283,12],[283,138]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 290,
            "column": 0
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui right floated button");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2,"class","toggle left icon");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    Powrót\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui two column grid");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui two column grid");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","four wide column");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","twelve wide column");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Ostatni pomiar czujnika");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("table");
        dom.setAttribute(el3,"class","ui left aligned table");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("thead");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tr");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:35%");
        var el7 = dom.createTextNode("Czujnik");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:20%");
        var el7 = dom.createTextNode("Wartość pomiaru");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:25%");
        var el7 = dom.createTextNode("Data i godzina pomiaru");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:20%");
        var el7 = dom.createTextNode("Stan alarmowy");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("div");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Dane czujnika");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("table");
        dom.setAttribute(el3,"class","ui left aligned table");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("thead");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tr");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:35%");
        var el7 = dom.createTextNode("Producent / Typ / Nr seryjny");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:20%");
        var el7 = dom.createTextNode("Jednostka miary");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:25%");
        var el7 = dom.createTextNode("Próbkowanie [min]");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.setAttribute(el6,"style","width:20%");
        var el7 = dom.createTextNode("Opis");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("              /\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("              /\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Zdefiniowane stany alarmowe");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("table");
        dom.setAttribute(el3,"class","ui left aligned table");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("thead");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tr");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        var el7 = dom.createTextNode("Nazwa");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        var el7 = dom.createTextNode("Aktywowany poniżej");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        var el7 = dom.createTextNode("Aktywowany powyżej");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        var el7 = dom.createTextNode("Oznaczenie");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("th");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui clearing divider");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","ui top attached tabular menu");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","ui button");
        dom.setAttribute(el3,"style","background-color: white");
        var el4 = dom.createTextNode("Tabelaryczne przedstawienie pomiarów (CSV)");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"data-tab","graph");
        dom.setAttribute(el2,"class","ui bottom attached tab segment active");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"data-tab","info-table");
        dom.setAttribute(el2,"class","ui bottom attached tab segment");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element55 = dom.childAt(fragment, [0]);
        var element56 = dom.childAt(fragment, [4]);
        var element57 = dom.childAt(element56, [3]);
        var element58 = dom.childAt(element57, [3, 3]);
        var element59 = dom.childAt(element58, [3]);
        var element60 = dom.childAt(element58, [7, 0]);
        var element61 = dom.childAt(element57, [7, 3]);
        var element62 = dom.childAt(element61, [1]);
        var element63 = dom.childAt(fragment, [15]);
        var element64 = dom.childAt(element63, [1]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element63, [3]);
        var morphs = new Array(30);
        morphs[0] = dom.createElementMorph(element55);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element56, [1]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element58, [1]),0,0);
        morphs[4] = dom.createMorphAt(element59,0,0);
        morphs[5] = dom.createMorphAt(element59,2,2);
        morphs[6] = dom.createMorphAt(element58,5,5);
        morphs[7] = dom.createAttrMorph(element60, 'style');
        morphs[8] = dom.createMorphAt(element62,1,1);
        morphs[9] = dom.createMorphAt(element62,3,3);
        morphs[10] = dom.createMorphAt(element62,5,5);
        morphs[11] = dom.createMorphAt(dom.childAt(element61, [3]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element61, [5]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element61, [7]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element57, [11]),3,3);
        morphs[15] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[21] = dom.createMorphAt(element64,1,1);
        morphs[22] = dom.createElementMorph(element65);
        morphs[23] = dom.createMorphAt(element66,1,1);
        morphs[24] = dom.createMorphAt(element66,2,2);
        morphs[25] = dom.createMorphAt(element66,3,3);
        morphs[26] = dom.createMorphAt(element66,4,4);
        morphs[27] = dom.createMorphAt(element66,5,5);
        morphs[28] = dom.createMorphAt(element66,6,6);
        morphs[29] = dom.createMorphAt(element66,7,7);
        return morphs;
      },
      statements: [
        ["element","action",["goBack"],[],["loc",[null,[1,37],[1,56]]]],
        ["block","link-to",["technika.station.details",["get","model.station",["loc",[null,[6,42],[6,55]]]]],[],0,null,["loc",[null,[6,4],[6,108]]]],
        ["block","if",[["get","model.imageUrl",["loc",[null,[10,14],[10,28]]]]],[],1,2,["loc",[null,[10,8],[14,15]]]],
        ["content","model.name",["loc",[null,[28,16],[28,32]]]],
        ["content","model.sensorValue",["loc",[null,[29,16],[29,39]]]],
        ["content","model.unit.value",["loc",[null,[29,40],[29,62]]]],
        ["block","if",[["get","model.sensorDate",["loc",[null,[30,18],[30,34]]]]],[],3,4,["loc",[null,[30,12],[34,19]]]],
        ["attribute","style",["concat",["background-color: ",["get","model.sensorAlarm",["loc",[null,[35,48],[35,65]]]],"; width: 20px; height:20px"]]],
        ["block","if",[["get","model.manufacturer.value",["loc",[null,[50,20],[50,44]]]]],[],5,6,["loc",[null,[50,14],[54,21]]]],
        ["block","if",[["get","model.type_display_text",["loc",[null,[56,20],[56,43]]]]],[],7,8,["loc",[null,[56,14],[60,21]]]],
        ["block","if",[["get","model.serial",["loc",[null,[62,20],[62,32]]]]],[],9,10,["loc",[null,[62,14],[66,21]]]],
        ["content","model.unit.value",["loc",[null,[68,16],[68,38]]]],
        ["content","model.sampling",["loc",[null,[69,16],[69,36]]]],
        ["content","model.description",["loc",[null,[70,16],[70,39]]]],
        ["block","if",[["get","model.warnings",["loc",[null,[84,14],[84,28]]]]],[],11,12,["loc",[null,[84,8],[110,15]]]],
        ["block","if",[["get","isFlotLine",["loc",[null,[114,10],[114,20]]]]],[],13,null,["loc",[null,[114,4],[136,11]]]],
        ["block","if",[["get","isFlotBar",["loc",[null,[137,10],[137,19]]]]],[],14,null,["loc",[null,[137,4],[159,11]]]],
        ["block","if",[["get","isFlotRadar",["loc",[null,[160,10],[160,21]]]]],[],15,null,["loc",[null,[160,4],[182,11]]]],
        ["block","if",[["get","isWaterLevelChart",["loc",[null,[183,10],[183,27]]]]],[],16,null,["loc",[null,[183,4],[206,11]]]],
        ["block","if",[["get","isWaterDropChart",["loc",[null,[207,10],[207,26]]]]],[],17,null,["loc",[null,[207,4],[229,11]]]],
        ["block","if",[["get","isWaterTemperatureChart",["loc",[null,[230,10],[230,33]]]]],[],18,null,["loc",[null,[230,4],[252,11]]]],
        ["block","form-tabs-tab",[],["active",true,"data-tab","graph","onVisible",""],19,null,["loc",[null,[256,4],[256,115]]]],
        ["element","action",["statsCustomCSV",["get","chart3",["loc",[null,[257,86],[257,92]]]],"chart1DataLoading"],[],["loc",[null,[257,60],[257,114]]]],
        ["block","if",[["get","model.warnings",["loc",[null,[260,14],[260,28]]]]],[],20,21,["loc",[null,[260,8],[266,15]]]],
        ["block","if",[["get","isWaterLevelChart",["loc",[null,[267,14],[267,31]]]]],[],22,null,["loc",[null,[267,8],[269,15]]]],
        ["block","if",[["get","isWaterDropChart",["loc",[null,[270,14],[270,30]]]]],[],23,null,["loc",[null,[270,8],[272,15]]]],
        ["block","if",[["get","isWaterTemperatureChart",["loc",[null,[273,14],[273,37]]]]],[],24,null,["loc",[null,[273,8],[275,15]]]],
        ["block","if",[["get","isFlotLine",["loc",[null,[276,14],[276,24]]]]],[],25,null,["loc",[null,[276,8],[278,15]]]],
        ["block","if",[["get","isFlotBar",["loc",[null,[279,14],[279,23]]]]],[],26,null,["loc",[null,[279,8],[281,15]]]],
        ["block","if",[["get","isFlotRadar",["loc",[null,[282,14],[282,25]]]]],[],27,null,["loc",[null,[282,8],[284,15]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27]
    };
  }()));

});