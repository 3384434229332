define('admin/helpers/assoc-array-value', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.helperfunc = helperfunc;

  function helperfunc(params, obj) {
    var arr = params[0];
    var key = params[1];
    var prefix = params[2];
    return arr[prefix + key];
  }

  exports['default'] = Ember['default'].Helper.helper(helperfunc);

});