define('admin/models/technika/contact', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    topic: DS['default'].attr("string"),
    description: DS['default'].attr("string"),
    file: DS['default'].belongsTo("file", { async: false })
  });

});