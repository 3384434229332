define('admin/controllers/technika/raport/new', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "technika/sensor",
    multiselect: true,
    checkedSensors: [],
    type: null,
    init: function init() {
      this._super();
    } });

});