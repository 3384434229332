define('admin/tests/unit/utils/navigation/container-test', ['admin/tests/utils/navigation/container', 'qunit'], function (navigationContainer, qunit) {

  'use strict';

  qunit.module("navigationContainer");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = navigationContainer['default']();
    assert.ok(result);
  });

});