define('admin/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'admin/config/environment', 'jquery'], function (exports, Ember, Resolver, loadInitializers, config, jQuery) {

  'use strict';

  (function () {
    var method;
    var noop = function noop() {};
    var methods = ["assert", "clear", "count", "debug", "dir", "dirxml", "error", "exception", "group", "groupCollapsed", "groupEnd", "info", "log", "markTimeline", "profile", "profileEnd", "table", "time", "timeEnd", "timeStamp", "trace", "warn"];
    var length = methods.length;
    var console = window.console = window.console || {};

    while (length--) {
      method = methods[length];

      // Only stub undefined methods.
      if (!console[method]) {
        console[method] = noop;
      }
    }
  })();

  Ember['default'].MODEL_FACTORY_INJECTIONS = true;
  Ember['default'].deprecate = function () {};
  Ember['default'].warn = function (i) {};
  var App = Ember['default'].Application.extend({
    LOG_TRANSITIONS: false,
    LOG_TRANSITIONS_INTERNAL: false,

    modulePrefix: config['default'].modulePrefix,
    podModulePrefix: config['default'].podModulePrefix,
    Resolver: Resolver['default'],
    server: null,
    authManager: null,
    systemInformation: Ember['default'].Object.create(),
    messages: [],
    isLoading: 0,
    i18n: null,
    ping: function ping() {
      jQuery['default'].ajax(this.get("server") + "/ping.json", {
        success: (function () {
          this.message("app.ping", "app.ping.message");
        }).bind(this)
      });
    },
    addMessage: function addMessage(message) {
      if (message) {
        this.get("messages").unshiftObject(message);
        if (message.type === "error") {
          this.error(message.title, message.message, false);
        } else if (message.type === "information") {
          this.message(message.title, message.message, false);
        }
      }
    },
    message: (function (_message) {
      var _messageWrapper = function message(_x, _x2, _x3) {
        return _message.apply(this, arguments);
      };

      _messageWrapper.toString = function () {
        return _message.toString();
      };

      return _messageWrapper;
    })(function (title, message, translate) {
      if (translate === undefined || translate === true) {
        title = this.get("i18n").t(title);
        message = this.get("i18n").t(message);
      }
      noty({
        layout: "topCenter",
        theme: "relax",
        text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">" + title + "</div></h5>" + message,
        type: "information",
        timeout: 5000
      });
    }),
    error: function error(title, message, translate) {
      if (translate === undefined || translate === true) {
        title = this.get("i18n").t(title);
        message = this.get("i18n").t(message);
      }
      noty({
        layout: "topCenter",
        theme: "relax",
        text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"warning sign icon\"></i><div class=\"content\">" + title + "</div></h5>" + message,
        type: "error",
        timeout: 5000
      });
    }
  });

  loadInitializers['default'](App, config['default'].modulePrefix);

  exports['default'] = App;

});