define('admin/models/technika/import', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    ftpDirectory: DS['default'].attr("string"),
    images: DS['default'].hasMany("file", { async: false }),
    sameStations: DS['default'].attr("string")
  });

});