define('admin/controllers/admin/company/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

    'use strict';

    exports['default'] = List['default'].extend({
        modelName: "company",
        init: function init() {
            this._super();
            this.get("filters").addObject(FilterElement['default'].create({
                name: "term",
                label: "app.filter.term"
            }));
            this.get("navigation").pushObject({
                route: "admin.company.index",
                text: "admin.company.navigation.list"
            });
            this.get("navigation").pushObject({
                route: "admin.company.new",
                text: "admin.company.navigation.new"
            });
        },
        actions: {
            activate: function activate(data) {
                var id = data.value.get("id");
                this.set("isLoading", true);
                return jQuery.ajax({
                    url: this.get("application").get("server") + "/system/company/activate/" + id + ".json",
                    complete: (function () {
                        this.set("isLoading", false);
                        var comp = this.get("store").find("company"); // Find the post from the store
                        comp.reload();
                    }).bind(this)
                });
            }
        }
    });

});