define('admin/controllers/admin/systeminfo/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    browser: (function () {
      return navigator.appVersion;
    }).property("this")
  });

});