define('admin/models/tax', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    displayName: DS['default'].attr("string"),
    isDefault: DS['default'].attr("boolean"),
    description: DS['default'].attr("string"),
    tax: DS['default'].attr("string"),
    symbol: DS['default'].attr("string") });

});