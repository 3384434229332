define('admin/components/field-datepicker', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["ui", "action", "input"],
    onModel: (function () {
      if (this.get("model") && this.get("name") in this.get("model")) {
        if (this.get("model").get(this.get("name")) === null || this.get("model").get(this.get("name")).length == 0) {
          this.get("model").set(this.get("name"), moment().format());
        }
        this.set("value", this.get("model." + this.get("name")));
        this.get("model").addObserver(this.get("name"), (function () {
          this.set("value", this.get("model." + this.get("name")));
        }).bind(this));
      } else {
        this.addObserver("val", (function () {
          this.set("value", this.get("val"));
        }).bind(this));
      }
    }).observes("model", "val"),
    tagName: "div",
    name: null,
    model: null,
    val: null,
    value: null,
    onmethod: "change",
    //attributeBindings: ["name", "value", "type"],
    //  onMask: function () {
    //    if (this.$() && this.get('mask')) {
    //      this.$().mask(this.get('mask'), {reverse: true});
    //    }
    //  }.observes('mask'),
    didRender: function didRender() {
      Ember['default'].run.scheduleOnce("afterRender", this, "initialize");
    },
    initialize: function initialize() {
      this.onModel();
      this.$().attr("readonly", true);
      this.$("button").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        format: "dddd, Do MMMM YYYY",
        opens: "left"
      }, (function (start, end, label) {
        if (this.get("model")) {
          this.get("model").set(this.get("name"), start.format());
        }
      }).bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("name"));
      }
      this.$().remove();
    } });

});