define('admin/controllers/technika/sensor/type/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "technika/sensor/type",
    init: function init() {
      this._super();
      this.get("filters").addObject(FilterElement['default'].create({
        name: "term",
        label: "app.filter.term"
      }));
      this.get("navigation").pushObject({
        route: "technika.sensor.index",
        text: "technika.sensor.navigation.list"
      });
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("navigation").pushObject({
          route: "technika.sensor.new",
          text: "technika.sensor.navigation.new"
        });
      }
    }
  });

});