define('admin/views/admin/user/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    didInsertElement: function didInsertElement() {
      this._super();
      if (this.$(".menu .item").length > 0) {
        this.$(".menu .item").tab();
      }
    }
  });

});