define('admin/tests/unit/utils/auth-manager-test', ['admin/utils/auth-manager', 'qunit'], function (authManager, qunit) {

  'use strict';

  qunit.module("authManager");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = authManager['default']();
    assert.ok(result);
  });

});