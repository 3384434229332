define('admin/controllers/admin/dictionary/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "dictionary",
    init: function init() {
      this._super();
      this.get("filters").addObject(FilterElement['default'].create({
        name: "term",
        label: "app.filter.term"
      }));
      this.get("filters").addObject(FilterElement['default'].create({
        name: "category",
        label: "technika.sensor.filtr.type",
        type: "dictionary",
        dictionaryName: "admin.dictionary.enum.type"
      }));
      this.get("navigation").pushObject({
        route: "admin.dictionary.index",
        text: "admin.dictionary.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.dictionary.new",
        text: "admin.dictionary.navigation.new"
      });
    }
  });

});