define('admin/transforms/number-string', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  var empty = Ember['default'].isEmpty;
  var none = Ember['default'].isNone;

  function isNumber(value) {
    return value === value && value !== Infinity && value !== -Infinity;
  }

  exports['default'] = DS['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      var transformed;

      if (empty(serialized)) {
        return null;
      } else {
        transformed = Number(serialized);

        return isNumber(transformed) ? transformed : null;
      }
    },

    serialize: function serialize(deserialized) {
      return none(deserialized) ? null : String(deserialized);
    }
  });

});