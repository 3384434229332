define('admin/components/field-singleselect', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    showModalAction: null,
    clearValueAction: null,

    controllerName: null,
    into: null,
    outlet: null,
    name: null,
    model: null,
    action: null,
    editable: true,
    value: (function () {
      //(this.get('model.' + this.get('name')));
      if (this.get("model." + this.get("name"))) {
        return this.get("model." + this.get("name"));
      }return null;
    }).property("model"),
    hasValue: (function () {
      //('X', this.get('value'));
      if (this.get("value") !== null) {
        return true;
      }
      return false;
    }).property("value"),
    actions: {
      showModal: function showModal() {
        //controller, into, outlet
        this.triggerAction({
          action: this.get("showModalAction")
          //target: this
        });
      },
      clear: function clear() {
        this.triggerAction({
          action: this.get("clearValueAction")
          //target: this
        });
      }
    }
  });

});