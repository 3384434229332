define('admin/serializers/application', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === "delivery" || payloadKey === "deliveries") {
        return this._super("shop/delivery");
      } else if (payloadKey === "payment" || payloadKey === "payments") {
        return this._super("shop/payment");
      } else if (payloadKey === "salegroup" || payloadKey === "salegroups") {
        return this._super("shop/salegroup");
      } else if (payloadKey === "salegroupdelivery" || payloadKey === "salegroupdeliveries") {
        return this._super("shop/salegroup/delivery");
      } else if (payloadKey === "salegrouppayment" || payloadKey === "salegrouppayments") {
        return this._super("shop/salegroup/payment");
      } else if (payloadKey === "product" || payloadKey === "products") {
        return this._super("shop/product");
      } else if (payloadKey === "cart" || payloadKey === "carts") {
        return this._super("shop/cart");
      } else if (payloadKey === "cartItem" || payloadKey === "cartItems") {
        return this._super("shop/cart/item");
      } else if (payloadKey === "cartPayment" || payloadKey === "cartPayments") {
        return this._super("shop/cart/payment");
      } else if (payloadKey === "order" || payloadKey === "orders") {
        return this._super("shop/order");
      } else if (payloadKey === "station" || payloadKey === "stations") {
        return this._super("technika/station");
      } else if (payloadKey === "maintenance" || payloadKey === "maintenances") {
        return this._super("technika/station/maintenance");
      } else if (payloadKey === "sensor" || payloadKey === "sensors") {
        return this._super("technika/sensor");
      } else if (payloadKey === "sensortype" || payloadKey === "sensortypes") {
        return this._super("technika/sensor/type");
      } else if (payloadKey === "warning" || payloadKey === "warnings") {
        return this._super("technika/sensor/warning");
      } else if (payloadKey === "import" || payloadKey === "imports") {
        return this._super("technika/imports");
      } else {
        return this._super(payloadKey);
      }
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      if (modelName === "shop/delivery" || modelName === "shop/deliveries") {
        return this._super("delivery");
      } else if (modelName === "shop/payment" || modelName === "shop/payments") {
        return this._super("payment");
      } else if (modelName === "shop/salegroup" || modelName === "shop/salegroups") {
        return this._super("salegroup");
      } else if (modelName === "shop/salegroup/delivery" || modelName === "shop/salegroups/delivery") {
        return this._super("salegroupdelivery");
      } else if (modelName === "shop/product" || modelName === "shop/products") {
        return this._super("product");
      } else if (modelName === "shop/cart" || modelName === "shop/carts") {
        return this._super("cart");
      } else if (modelName === "shop/cart/item" || modelName === "shop/cart/items") {
        return this._super("cartItem");
      } else if (modelName === "shop/cart/payment" || modelName === "shop/cart/payments") {
        return this._super("cartPayment");
      } else if (modelName === "shop/order" || modelName === "shop/orders") {
        return this._super("order");
      } else if (modelName === "technika/station" || modelName === "technika/stations") {
        return this._super("station");
      } else if (modelName === "technika/station/maintenance" || modelName === "technika/station/maintenances") {
        return this._super("maintenance");
      } else if (modelName === "technika/sensor") {
        return this._super("sensor");
      } else if (modelName === "technika/import") {
        return this._super("import");
      } else if (modelName === "technika/sensor/type") {
        return this._super("sensortype");
      }
      return this._super(modelName);
    } });

  //    normalizeResponse: function (serializer, store, modelClass, payload, id, requestType) {
  //      ("A", serializer, store, modelClass, payload, id, requestType);
  //      return this._super(serializer, store, modelClass, payload, id, requestType);
  //    }

});