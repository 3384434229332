define('admin/components/field-input', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    onModel: (function () {
      if (this.get("model") && this.get("name") in this.get("model")) {
        this.set("value", this.get("model." + this.get("name")));
        this.get("model").addObserver(this.get("name"), (function () {
          this.set("value", this.get("model." + this.get("name")));
        }).bind(this));
      } else {
        this.addObserver("val", (function () {
          this.set("value", this.get("val"));
        }).bind(this));
      }
    }).observes("model", "val"),
    tagName: "input",
    name: null,
    model: null,
    val: null,
    readonly: false,
    value: null,
    mask: null,
    type: "text",
    onmethod: "change",
    attributeBindings: ["name", "value", "type"],
    onMask: (function () {
      if (this.$() && this.get("mask")) {
        this.$().mask(this.get("mask"), { reverse: true });
      }
    }).observes("mask"),
    onReadonly: (function () {
      if (this.get("readonly")) {
        this.$().attr("readonly", true);
      } else {
        this.$().attr("readonly", false);
      }
    }).observes("readonly").on("didRender"),
    didRender: function didRender() {
      Ember['default'].run.scheduleOnce("afterRender", this, "initialize");
    },
    initialize: function initialize() {
      this.onModel();
      this.$().data("modelId", this.get("model.id"));
      this.$().on(this.get("onmethod"), (function (event) {
        if (this.get("model")) {
          this.get("model").set(this.get("name"), jQuery['default'](event.target).val());
        } else {
          this.set("val", jQuery['default'](event.target).val());
        }
      }).bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("name"));
      }
      this.$().remove();
    } });

});