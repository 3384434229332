define('admin/templates/technika/sensor/tab-warning-owner', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 6
              },
              "end": {
                "line": 41,
                "column": 6
              }
            }
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","nocolor");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"rowspan","5");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","nocolor");
            dom.setAttribute(el1,"style","border: 0px");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("th");
            dom.setAttribute(el2,"colspan","7");
            dom.setAttribute(el2,"style","background-color: #f9f9f9; border-bottom: 1px solid #d4d4d5; text-align: center; padding: 3px");
            var el3 = dom.createTextNode("Powiadomienia");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","nocolor");
            dom.setAttribute(el1,"style","border: 0px");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"style","border: 0px");
            var el3 = dom.createTextNode("Adresaci");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"colspan","5");
            dom.setAttribute(el2,"style","border: 0px");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","nocolor");
            dom.setAttribute(el1,"style","background-color: #ffffff; border: 0px");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"style","border: 0px");
            var el3 = dom.createTextNode("Temat");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"colspan","5");
            dom.setAttribute(el2,"style","border: 0px");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","nocolor");
            dom.setAttribute(el1,"style","background-color: #ffffff; border: 0px");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"style","border: 0px");
            var el3 = dom.createTextNode("Wiadomość");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"colspan","5");
            dom.setAttribute(el2,"style","border: 0px");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [11]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [13]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(fragment, [5, 3]),0,0);
            morphs[8] = dom.createMorphAt(dom.childAt(fragment, [7, 3]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(fragment, [9, 3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[18,34],[18,41]]]]],[],[]],"name","name"],["loc",[null,[18,14],[18,56]]]],
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[19,34],[19,41]]]]],[],[]],"name","level_lte"],["loc",[null,[19,14],[19,61]]]],
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[20,34],[20,41]]]]],[],[]],"name","level_gte"],["loc",[null,[20,14],[20,61]]]],
            ["inline","field-cpicker",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[21,36],[21,43]]]]],[],[]],"name","color"],["loc",[null,[21,14],[21,58]]]],
            ["inline","field-pinpicker",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[22,38],[22,45]]]]],[],[]],"name","pin"],["loc",[null,[22,14],[22,58]]]],
            ["inline","field-checkbox",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[23,37],[23,44]]]]],[],[]],"name","isActive"],["loc",[null,[23,14],[23,62]]]],
            ["inline","ui-button",[],["action","deleteWarningLevel","actionParam",["subexpr","@mut",[["get","warning",["loc",[null,[24,78],[24,85]]]]],[],[]],"icon","close icon","popup","app.edit"],["loc",[null,[24,26],[24,122]]]],
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[31,68],[31,75]]]]],[],[]],"name","to"],["loc",[null,[31,48],[31,87]]]],
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[35,68],[35,75]]]]],[],[]],"name","subject"],["loc",[null,[35,48],[35,92]]]],
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","warning",["loc",[null,[39,68],[39,75]]]]],[],[]],"name","message"],["loc",[null,[39,48],[39,92]]]]
          ],
          locals: ["warning"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 44,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","ui button blue");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1,"class","ui striped compact small blue table");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4,"class","one wide");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(fragment, [3]);
          var element3 = dom.childAt(element2, [1, 1]);
          var morphs = new Array(10);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [5]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [7]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element3, [9]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element3, [11]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element3, [13]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["addWarningLevel"],[],["loc",[null,[2,33],[2,61]]]],
          ["inline","t",["technika.sensor.edit.form.warning.add"],[],["loc",[null,[2,62],[2,107]]]],
          ["inline","t",["technika.sensor.edit.form.warning.name"],[],["loc",[null,[6,14],[6,60]]]],
          ["inline","t",["technika.sensor.edit.form.warning.level_lte"],[],["loc",[null,[7,14],[7,65]]]],
          ["inline","t",["technika.sensor.edit.form.warning.level_gte"],[],["loc",[null,[8,14],[8,65]]]],
          ["inline","t",["technika.sensor.edit.form.warning.color"],[],["loc",[null,[9,14],[9,61]]]],
          ["inline","t",["technika.sensor.edit.form.warning.pin"],[],["loc",[null,[10,14],[10,59]]]],
          ["inline","t",["technika.sensor.edit.form.warning.isActive"],[],["loc",[null,[11,14],[11,64]]]],
          ["inline","t",["app.delete"],[],["loc",[null,[12,31],[12,49]]]],
          ["block","each",[["get","model.warnings",["loc",[null,[16,14],[16,28]]]]],[],0,null,["loc",[null,[16,6],[41,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 6
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("Zmienne kontekstowe");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n   %nazwastanu% - nazwa alarmu, stanu");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   %stanmin%    - poniżej");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   %stanmax%    - powyżej");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   %dataczas%   - data kiedy alarm został przekroczony");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   %wartosc%    - wartość czujnika wraz z jednostką");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   %czujnik%    - nazwa czujnika");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   %stacja%     - nazwa stacji");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[1,25],[1,35]]]]],[],[]],"field","alarmLevel","label","technika.sensor.edit.form.alarms"],0,null,["loc",[null,[1,0],[44,23]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});