define('admin/components/ui-modal', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["ui", "fullscreen", "modal"],
    title: null,
    ident: null,
    didInsertElement: function didInsertElement() {
      this.$().modal({

        onHidden: (function () {
          this.set("showParameter", false);
        }).bind(this)
      });
      if (this.get("showParameter")) {
        this.$().modal("show");
      }
    },
    showParameter: false,
    show: (function () {
      if (this.get("showParameter")) {
        this.$().modal("show");
        this.$(".content-size").css({
          height: $(window).height() - 170
        });
      } else {
        this.$().modal("hide");
      }
    }).observes("showParameter")
  });

});