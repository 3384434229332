define('admin/routes/technika/raport/index', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "technika/raport/index",
      controller: "technika/raport/list",
      hasPager: true,
      hasFilter: true,
      hasNavigation: true,
      hasAccordionNavigation: true
    },
    model: function model(params) {
      var controler = this.controllerFor("technika/raport/list");
      controler.set("dateFrom", moment().subtract(30, "days").format("DD.MM.YYYY"));
      controler.set("dateTo", moment().format("DD.MM.YYYY"));
      controler.set("type", params.type);
    },
    renderTemplate: function renderTemplate() {
      this._super();

      var controller = this.controllerFor(this.get("config.controller"));

      this.render(this.get("config.controller"), {
        outlet: "raport",
        into: this.get("config.into"),
        controller: controller
      });
      if (this.get("config.hasPager")) {
        this.render("pager", {
          outlet: "pager",
          into: this.get("config.into"),
          controller: controller
        });
      }
    }
  });

});