define('admin/components/boxoffice-payments', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    cart: null,
    rest: 0,
    //    label: null,
    //    name: null,
    //    model: null,
    //    value: 1,
    //    slider: false,
    didInsertElement: function didInsertElement() {
      this.changeWorthOrQuantity();
      this.$().delegate(".ui.checkbox", "click", (function (event) {
        var id = jQuery['default'](event.currentTarget).data("modelId");
        var checked = jQuery['default'](event.currentTarget).hasClass("checked");
        if (checked === true) {
          this.get("cart").get("payments").forEach((function (payment) {
            if (payment.get("id") === id) {
              payment.set("selected", true);
              payment.set("amount", this.get("cart").get("worth"));
            } else {
              payment.set("selected", false);
              payment.set("amount", "0.00");
            }
          }).bind(this));
        } else {
          var payment = this.get("cart").get("payments").findBy("id", id);
          if (payment) {
            payment.set("amount", "0.00");
          }
        }
      }).bind(this));
    },
    actions: {
      reload: function reload() {
        this.changeWorthOrQuantity();
      }
    },
    changeAmount: (function () {
      var payed_amount = 0;
      var worth = parseFloat(this.get("cart").get("worth").replace(/,/g, "."));
      var nf = new NumberFormat(this.get("cart.locale"));
      this.get("cart.payments").forEach(function (payment) {
        if (typeof payment.get("amount") === "string") {
          var amount = payment.get("amount").replace(/,/g, ".");
          if (amount.length > 0) {
            payed_amount += parseFloat(amount);
            if (parseFloat(amount) > 0) {
              payment.set("selected", true);
            } else {
              payment.set("selected", false);
            }
          } else {
            payment.set("selected", false);
          }
        }
      });
      var rest = payed_amount - worth;
      if (rest <= 0) rest = 0;
      this.set("rest", rest + "");
    }).observes("cart.payments.@each.amount"),
    changeWorthOrQuantity: (function () {
      if (this.get("cart.payments")) {
        this.get("cart.payments").forEach((function (payment) {
          if (payment.get("isDefault")) {
            payment.set("selected", true);
            payment.set("amount", this.get("cart").get("worth"));
          } else {
            payment.set("selected", false);
            payment.set("amount", "0.00");
          }
        }).bind(this));
      }
    }).observes("cart.worth")
    //    didInsertElement: function () {
    //      this.$('.ui.checkbox').checkbox({
    //        onChecked: function () {
    //          if (this.get('model') && this.get('model').get(this.get('name')) !== undefined) {           
    //            this.get('model').set(this.get('name'), this.get('value'));
    //          }
    //        }.bind(this),
    //        onUnchecked: function () {
    //          if (this.get('model') && this.get('model').get(this.get('name')) !== undefined) {
    //            this.get('model').set(this.get('name'), null);
    //          }
    //        }.bind(this)
    //      });
    //    },
    //    checked: function () {
    //      if (this.get('model')) {
    //        return this.get('model.' + this.get('name'));
    //      }
    //    }.property('value', 'model')
  });

});