define('admin/models/account/history', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    ip: DS['default'].attr("string"),
    loginAttempt: DS['default'].attr("boolean"),
    date: DS['default'].attr("string"),
    loginDate: DS['default'].attr("string"),
    logoutDate: DS['default'].attr("string"),
    logoutType: DS['default'].attr("string"),
    manually: (function () {
      if (this.get("logoutType") === "0") {
        return true;
      }
      return false;
    }).property("logoutType")
  });

});