define('admin/templates/technika/sensor/tab-info-admin', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-input",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[4,24],[4,29]]]]],[],[]],"name","name","readonly",true],["loc",[null,[4,4],[4,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 2
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-input",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[7,26],[7,31]]]]],[],[]],"name","description"],["loc",[null,[7,6],[7,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.8",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 10
                },
                "end": {
                  "line": 13,
                  "column": 10
                }
              }
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","render",["technika.station.list"],[],["loc",[null,[12,12],[12,46]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 14,
                "column": 8
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","ui-modal",[],["showParameter",["subexpr","@mut",[["get","stationModalVisible",["loc",[null,[11,36],[11,55]]]]],[],[]],"title","technika.sensor.modal.station"],0,null,["loc",[null,[11,10],[13,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 15,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","field-singleselect",[],["editable",["subexpr","@mut",[["get","model.isNew",["loc",[null,[10,39],[10,50]]]]],[],[]],"model",["subexpr","@mut",[["get","model.station",["loc",[null,[10,57],[10,70]]]]],[],[]],"name","name","showModalAction","showStationModal"],0,null,["loc",[null,[10,8],[14,31]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","field-input",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[18,28],[18,33]]]]],[],[]],"name","serial","readonly",true],["loc",[null,[18,8],[18,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[17,29],[17,39]]]]],[],[]],"field","serial","label","technika.sensor.edit.form.serial"],0,null,["loc",[null,[17,4],[19,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 8
              },
              "end": {
                "line": 25,
                "column": 8
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","field-input",[],["value",["subexpr","@mut",[["get","model.manufacturer.value",["loc",[null,[24,32],[24,56]]]]],[],[]],"readonly",true],["loc",[null,[24,12],[24,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[23,33],[23,43]]]]],[],[]],"field","manufacturer","label","technika.sensor.edit.form.manufacturer"],0,null,["loc",[null,[23,8],[25,27]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 8
              },
              "end": {
                "line": 30,
                "column": 8
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","field-input",[],["value",["subexpr","@mut",[["get","model.type.value",["loc",[null,[29,32],[29,48]]]]],[],[]],"readonly",true],["loc",[null,[29,12],[29,64]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[28,33],[28,43]]]]],[],[]],"field","type","label","technika.sensor.edit.form.type"],0,null,["loc",[null,[28,8],[30,27]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 4
            },
            "end": {
              "line": 36,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-input",[],["value",["subexpr","@mut",[["get","model.unit.value",["loc",[null,[35,28],[35,44]]]]],[],[]],"readonly",true],["loc",[null,[35,8],[35,60]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 6
              },
              "end": {
                "line": 42,
                "column": 6
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","field-datepicker",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[41,33],[41,38]]]]],[],[]],"name","inactiveDate"],["loc",[null,[41,8],[41,60]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 6
              },
              "end": {
                "line": 45,
                "column": 6
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","field-textarea",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[44,31],[44,36]]]]],[],[]],"name","inactiveDescription"],["loc",[null,[44,8],[44,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 4
            },
            "end": {
              "line": 46,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[40,31],[40,41]]]]],[],[]],"field","inactiveDate","label","technika.sensor.edit.form.inactive.date"],0,null,["loc",[null,[40,6],[42,25]]]],
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[43,31],[43,41]]]]],[],[]],"field","inactiveDescription","label","technika.sensor.edit.form.inactive.description"],1,null,["loc",[null,[43,6],[45,25]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.8",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 4
              },
              "end": {
                "line": 51,
                "column": 4
              }
            }
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","field-gallery",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[50,28],[50,33]]]]],[],[]],"category","image","name","images","deleted",["subexpr","@mut",[["get","deleted",["loc",[null,[50,73],[50,80]]]]],[],[]],"readonly",true],["loc",[null,[50,6],[50,96]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 2
            },
            "end": {
              "line": 52,
              "column": 2
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[49,29],[49,39]]]]],[],[]],"field","images","label","technika.sensor.edit.form.gallery"],0,null,["loc",[null,[49,4],[51,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 6
            },
            "end": {
              "line": 58,
              "column": 6
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","field-dictionary",[],["key","technika.sensor.graph","model",["subexpr","@mut",[["get","model",["loc",[null,[57,61],[57,66]]]]],[],[]],"name","defaultGraphType","readonly",true],["loc",[null,[57,8],[57,106]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 4
            },
            "end": {
              "line": 70,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart2 isLoading=chart2DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[68,26],[68,32]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[68,43],[68,60]]]]],[],[]],"mode","demo"],["loc",[null,[68,6],[68,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 4
            },
            "end": {
              "line": 89,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart2 isLoading=chart2DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart3 \"chart3DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart3 \"chart3DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart3 \"chart3DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart3 \"chart3DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart3 \"chart3DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart3 isLoading=chart3DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart3",["loc",[null,[79,26],[79,32]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[79,43],[79,60]]]]],[],[]],"mode","demo"],["loc",[null,[79,6],[79,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 4
            },
            "end": {
              "line": 101,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart2 isLoading=chart2DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart3 isLoading=chart3DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[98,26],[98,32]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[98,43],[98,60]]]]],[],[]],"mode","demo"],["loc",[null,[98,6],[98,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 4
            },
            "end": {
              "line": 112,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart2 isLoading=chart2DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[110,26],[110,32]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[110,43],[110,60]]]]],[],[]],"mode","demo"],["loc",[null,[110,6],[110,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 113,
              "column": 4
            },
            "end": {
              "line": 123,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" {{ui-amcharts chart=chart2 isLoading=chart2DataLoading}} ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[121,26],[121,32]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[121,43],[121,60]]]]],[],[]],"mode","demo"],["loc",[null,[121,6],[121,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 124,
              "column": 4
            },
            "end": {
              "line": 133,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n      <button class=\"ui button\" {{action \"stats24Hours\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.24hours\"}}</button>\n      <button class=\"ui button\" {{action \"stats7Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.7days\"}}</button>\n      <button class=\"ui button\" {{action \"stats30Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.30days\"}}</button>\n      <button class=\"ui button\" {{action \"stats365Days\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.365days\"}}</button>\n      <button class=\"ui button\" {{action \"statsFull\" chart1 \"chart1DataLoading\"}}>{{t \"technika.sensor.chart.button.full\"}}</button>\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-amcharts",[],["chart",["subexpr","@mut",[["get","chart1",["loc",[null,[132,26],[132,32]]]]],[],[]],"isLoading",["subexpr","@mut",[["get","chart1DataLoading",["loc",[null,[132,43],[132,60]]]]],[],[]],"mode","demo"],["loc",[null,[132,6],[132,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 134,
              "column": 4
            },
            "end": {
              "line": 136,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-floatcharts",[],["data",["subexpr","@mut",[["get","chartdata",["loc",[null,[135,30],[135,39]]]]],[],[]],"type","line","unit",["subexpr","@mut",[["get","model.unit.value",["loc",[null,[135,57],[135,73]]]]],[],[]]],["loc",[null,[135,8],[135,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.8",
          "loc": {
            "source": null,
            "start": {
              "line": 137,
              "column": 4
            },
            "end": {
              "line": 139,
              "column": 4
            }
          }
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","ui-floatcharts",[],["data",["subexpr","@mut",[["get","chartdata",["loc",[null,[138,30],[138,39]]]]],[],[]],"type","bar","unit",["subexpr","@mut",[["get","model.unit.value",["loc",[null,[138,56],[138,72]]]]],[],[]]],["loc",[null,[138,8],[138,74]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 143,
            "column": 0
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","two flex fields");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","field");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("   ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","two flex fields");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","field");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("    \n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("   ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [6]);
        var element3 = dom.childAt(element0, [3]);
        var morphs = new Array(18);
        morphs[0] = dom.createMorphAt(element1,1,1);
        morphs[1] = dom.createMorphAt(element1,2,2);
        morphs[2] = dom.createMorphAt(element1,3,3);
        morphs[3] = dom.createMorphAt(element1,4,4);
        morphs[4] = dom.createMorphAt(element2,1,1);
        morphs[5] = dom.createMorphAt(element2,2,2);
        morphs[6] = dom.createMorphAt(dom.childAt(element1, [8]),1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element1, [10]),1,1);
        morphs[8] = dom.createMorphAt(element1,12,12);
        morphs[9] = dom.createMorphAt(dom.childAt(element3, [1]),1,1);
        morphs[10] = dom.createMorphAt(element3,3,3);
        morphs[11] = dom.createMorphAt(element3,4,4);
        morphs[12] = dom.createMorphAt(element3,5,5);
        morphs[13] = dom.createMorphAt(element3,6,6);
        morphs[14] = dom.createMorphAt(element3,7,7);
        morphs[15] = dom.createMorphAt(element3,8,8);
        morphs[16] = dom.createMorphAt(element3,9,9);
        morphs[17] = dom.createMorphAt(element3,10,10);
        return morphs;
      },
      statements: [
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[3,27],[3,37]]]]],[],[]],"field","name","label","technika.sensor.edit.form.name","required",true],0,null,["loc",[null,[3,2],[5,21]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[6,27],[6,37]]]]],[],[]],"field","description","label","technika.sensor.edit.form.description"],1,null,["loc",[null,[6,2],[8,21]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[9,31],[9,41]]]]],[],[]],"field","station","label","technika.station.edit.form.station","required","true"],2,null,["loc",[null,[9,6],[15,25]]]],
        ["block","if",[["get","model.hasSerial",["loc",[null,[16,8],[16,23]]]]],[],3,null,["loc",[null,[16,2],[20,9]]]],
        ["block","if",[["get","model.hasManufacturer",["loc",[null,[22,10],[22,31]]]]],[],4,null,["loc",[null,[22,4],[26,11]]]],
        ["block","if",[["get","model.hasType",["loc",[null,[27,10],[27,23]]]]],[],5,null,["loc",[null,[27,4],[31,11]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[34,29],[34,39]]]]],[],[]],"field","unit","label","technika.sensor.edit.form.unit"],6,null,["loc",[null,[34,4],[36,23]]]],
        ["block","unless",[["get","model.isActive",["loc",[null,[39,14],[39,28]]]]],[],7,null,["loc",[null,[39,4],[46,15]]]],
        ["block","if",[["get","model.hasImages",["loc",[null,[48,8],[48,23]]]]],[],8,null,["loc",[null,[48,2],[52,9]]]],
        ["block","form-field-top",[],["errors",["subexpr","@mut",[["get","formErrors",["loc",[null,[56,31],[56,41]]]]],[],[]],"field","defaultGraphType","label","technika.sensor.edit.form.defaultgraphtype","readonly",true],9,null,["loc",[null,[56,6],[58,25]]]],
        ["block","if",[["get","isWaterLevelChart",["loc",[null,[60,10],[60,27]]]]],[],10,null,["loc",[null,[60,4],[70,11]]]],
        ["block","if",[["get","isWaterTemperatureChart",["loc",[null,[71,10],[71,33]]]]],[],11,null,["loc",[null,[71,4],[89,11]]]],
        ["block","if",[["get","isWaterDropChart",["loc",[null,[90,10],[90,26]]]]],[],12,null,["loc",[null,[90,4],[101,11]]]],
        ["block","if",[["get","isWindSpeedChart",["loc",[null,[102,10],[102,26]]]]],[],13,null,["loc",[null,[102,4],[112,11]]]],
        ["block","if",[["get","isAirTemperatureChart",["loc",[null,[113,10],[113,31]]]]],[],14,null,["loc",[null,[113,4],[123,11]]]],
        ["block","if",[["get","isAirHumidityChart",["loc",[null,[124,10],[124,28]]]]],[],15,null,["loc",[null,[124,4],[133,11]]]],
        ["block","if",[["get","isFlotLine",["loc",[null,[134,10],[134,20]]]]],[],16,null,["loc",[null,[134,4],[136,11]]]],
        ["block","if",[["get","isFlotBar",["loc",[null,[137,10],[137,19]]]]],[],17,null,["loc",[null,[137,4],[139,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
    };
  }()));

});