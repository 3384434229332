define('admin/controllers/technika/station/maplist', ['exports', 'admin/controllers/technika/station/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

    'use strict';

    exports['default'] = List['default'].extend({
        detailsModalVisible: false,
        checkedSensors: [],
        sensorDetatils: Ember.inject.controller("technika/sensor/details"),
        init: function init() {
            this._super();
        },
        actions: {
            showStats: function showStats() {
                this.transitionToRoute("technika.stats.chart", { queryParams: { stations: this.get("checkedSensors") } });
            }
        }
    });

});