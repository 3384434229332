define('admin/components/ui-chart-line', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    chart: null,
    tagName: "canvas",
    init: function init() {
      this.get("chartjs").load();
      this._super();
    },
    didRender: function didRender() {
      this.$().css({
        width: "100%",
        height: "100%"
      });
      this.onLibLoaded();
    },
    update: function update() {
      if (this.get("chart")) {
        this.get("chart").removeData();
        this.get("chart").addData([Math.random() * 100, Math.random() * 100], moment().format("H:m:s"));
      }
      setTimeout((function () {
        this.update();
      }).bind(this), 1000);
    },
    onLibLoaded: (function () {
      var data = {
        labels: [1, 2, 3, 4, 5, 6, 7],
        datasets: [{
          label: "My First dataset",
          fillColor: "rgba(" + this.get("chartjs").colors[2].join() + ",0.2)",
          strokeColor: "rgba(" + this.get("chartjs").colors[2].join() + ",1)",
          pointColor: "rgba(" + this.get("chartjs").colors[2].join() + ",1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(" + this.get("chartjs").colors[2].join() + ",1)",
          data: [65, 59, 80, 81, 56, 55, 40]
        }, {
          label: "My Second dataset",
          fillColor: "rgba(" + this.get("chartjs").colors[3].join() + ",0.2)",
          strokeColor: "rgba(" + this.get("chartjs").colors[3].join() + ",1)",
          pointColor: "rgba(" + this.get("chartjs").colors[3].join() + ",1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(" + this.get("chartjs").colors[3].join() + ",1)",
          data: [28, 48, 40, 19, 86, 27, 90]
        }]
      };
      if (this._state === "inDOM" && this.get("chartjs.isLoaded")) {
        this.set("chart", new Chart(this.$().get(0).getContext("2d")).Line(data, { animationSteps: 50 }));
        this.update();
      }
    }).observes("chartjs.isLoaded")
  });

});