define('admin/services/cookie', ['exports', 'ember', 'jquery', 'admin/mixins/serializable'], function (exports, Ember, jQuery, Serializable) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    set: function set(key, value, options) {
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          jQuery['default'].cookie(key, value, options);
          Ember['default'].run(null, resolve);
        } catch (e) {
          Ember['default'].run(null, reject, e);
        }
      });
    },

    get: function get(key) {
      return jQuery['default'].cookie(key);
    },

    remove: function remove(key, options) {
      return jQuery['default'].removeCookie(key, options);
    }
  });

});