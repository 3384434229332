define('admin/controllers/edit', ['exports', 'ember', 'jquery', 'admin/utils/system-log'], function (exports, Ember, jQuery, SystemLog) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    modelName: null,
    navigation: [],
    operations: [],
    formErrors: [],
    redirect: null,
    redirectList: null,
    allowDelete: false,
    allowSave: true,
    init: function init() {
      this._super();
      this.set("operations", []);
      this.set("navigation", []);
      if (this.get("allowSave")) {
        this.get("operations").pushObject({
          title: "app.save",
          action: "save",
          "class": "blue"
        });
      }
      if (this.get("allowDelete")) {
        this.get("operations").pushObject({
          title: "app.delete",
          action: "remove"
        });
      }
    },

    load: function load(id) {
      this.set("isLoading", true);
      //this.set('model', null);
      if (id === 0) {
        // nowy rekord
        //        ('a', this.get('store').adapter);
        //        (this.get('store').adapterFor(this.get('modelName')), this.get('store').modelFor(this.get('modelName')));
        //        var url = this.get('store').adapterFor(this.get('store').modelFor(this.get('modelName'))).buildURL(this.get('modelName'), id);
        var url = this.get("store").adapterFor(this.get("modelName")).buildURL(this.get("modelName"), id);
        return jQuery['default'].ajax(url).then((function (result) {
          var model = this.get("store").createRecord(this.get("modelName"));
          var modelName = this.get("store").serializerFor(this.get("modelName")).payloadKeyFromModelName(this.get("modelName"));
          //(model, model.get('_relationships'));
          for (var relation in model.get("_relationships")) {
            if (result[modelName][relation]) {
              //              result.group[relation].forEach(function (el) {
              //                this.get(relation).pushObject(el);
              //              }.bind(model));
              delete result[modelName][relation];
            }
          }
          delete result[modelName].id;
          //(result.group);
          model.setProperties(result[modelName]);
          //model.transitionTo('loaded.created.uncommitted');
          this.set("model", model);
          this.set("isLoading", false);
        }).bind(this), (function () {
          this.set("isLoading", false);
        }).bind(this));
      } else {
        this.get("store").unloadAll("technika.sensor.warning");
        var record = this.get("store").peekRecord(this.get("modelName"), id);
        if (record) {
          return record.reload().then((function (model) {
            this.set("model", model);
            this.set("isLoading", false);
          }).bind(this), (function () {
            this.set("isLoading", false);
          }).bind(this));
        } else {
          return this.get("store").findRecord(this.get("modelName"), id).then((function (model) {
            this.set("model", model);
            this.set("isLoading", false);
          }).bind(this), (function () {
            this.set("isLoading", false);
          }).bind(this));
        }
      }
    },
    save: function save(redirect, callback) {
      if (redirect !== false) {
        redirect = true;
      }
      this.set("isLoading", true);
      return this.get("model").save().then((function (model) {
        var _this = this;

        this.set("formErrors", []);
        var log = SystemLog['default'].create({
          title: this.get("i18n").t("ajax.post.success.title"),
          message: this.get("i18n").t("ajax.post.success.message"),
          type: "information"
        });
        Admin.addMessage(log);
        if (this.redirect && redirect) {
          this.get("application").__container__.lookup("route:application").transitionTo(this.redirect, model.get("id"));
        }
        if (typeof callback === "function") {
          callback("success", model);
        }
        if (typeof this.get("afterSave") !== "undefined") {
          (function () {
            var transit = _this.get("afterSave");
            _this.set("afterSave", undefined);
            Ember['default'].run.later((function () {
              this.transitionToRoute(transit);
            }).bind(_this), 500);
          })();
        } else {
          this.set("isLoading", false);
        }
      }).bind(this), (function (adapterError) {
        if (adapterError.errors) {
          this.set("formErrors", adapterError.errors);
          this.get("application").error("app.error.form.save", adapterError.message);
        }
        if (adapterError.messages) {
          adapterError.messages.forEach((function (message) {
            this.get("application").error("app.error.form.save", message);
          }).bind(this));
        }
        this.set("isLoading", false);
        if (typeof callback === "function") {
          callback("error", adapterError);
        }
      }).bind(this));
    },
    actions: {
      changeLanguage: function changeLanguage() {
        this.load(this.get("model.id"));
      },
      remove: function remove() {
        this.set("isLoading", true);
        this.get("model").deleteRecord();
        this.get("model").save().then((function () {
          this.set("errors", []);
          this.set("isLoading", false);
          if (this.redirectList) {
            this.get("application").__container__.lookup("route:application").transitionTo(this.redirectList);
          }
        }).bind(this), (function (adapterError) {
          if (adapterError.errors) {
            this.set("formErrors", adapterError.errors);
            this.get("application").error("app.error.form.save", adapterError.message);
          }
          this.set("isLoading", false);
        }).bind(this));
      },
      save: function save() {
        this.save();
      },
      goBack: function goBack() {
        window.history.back();
      }
    }
  });

});