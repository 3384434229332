define('admin/controllers/technika/station/maintenance/edit', ['exports', 'admin/controllers/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/station/maintenance",
    //redirect: "technika.station.maintenance.edit",
    //redirectList: "technika.station.maintenance.index",
    allowDelete: false,
    timezoneFilter: {
      "filter[category]": "timezone"
    },
    //needs: ['admin/company/list'],
    companyModalVisible: false,
    init: function init() {
      //(this.get('authManager').get('isAuthenticated'));
      //      this.set('control', this.controllerFor('admin/company/list'))
      this._super();
      //      this.get('navigation').pushObject({
      //        route: 'technika.station.maintenance.index',
      //        text: "technika.station.maintenance.navigation.list"
      //      });
      //      this.get('navigation').pushObject({
      //        route: 'technika.station.maintenance.new',
      //        text: "technika.station.maintenance.navigation.new"
      //      });
    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "technika.station.maintenance.new.title";
      } else {
        return "technika.station.maintenance.edit.title";
      }
    }).property("model") });

  //  multiselect: function (view) {
  //    view.$().find('input[type=checkbox]:checked').each(function (i, el) {
  //      if (view.get('name') === "stationsalegroup") {
  //        this.get('model.saleGroups').pushObject(view.get('controller.store').peekRecord('technika/salegroup', jQuery(el).val()));
  //      }
  //    }.bind(this));
  //  },
  //  actions: {
  //    deleteSalegroup: function (salegroup) {
  //      this.get('model.saleGroups').removeObject(salegroup);
  //    }
  //  }

});