define('admin/services/openlayers', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    isLoaded: false,
    isLoading: false,
    filesToLoad: 2,
    load: function load() {
      if (this.get("isLoaded") === false && this.get("isLoading") === false) {
        this.set("isLoading", true);
        jQuery['default'].getScript("assets/openlayers/ol-debug.js", (function () {
          this.set("filesToLoad", this.get("filesToLoad") - 1);
        }).bind(this));
        jQuery['default'].ajax({
          url: "assets/openlayers/ol.css",
          dataType: "text",
          success: (function (data) {
            jQuery['default']("<style type=\"text/css\">\n" + data + "</style>").appendTo("head");
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this)
        });
      }
    },
    onFileLoaded: (function () {
      if (this.get("filesToLoad") === 0) {
        this.set("isLoaded", true);
        this.set("isLoading", false);
      }
    }).observes("filesToLoad")
  });

});