define('admin/services/chartjs', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        isLoaded: false,
        isLoading: false,
        filesToLoad: 1,
        load: function load() {
            if (this.get("isLoaded") === false && this.get("isLoading") === false) {
                this.set("isLoading", true);
                jQuery['default'].getScript("assets/chartjs/chart.js", (function () {
                    this.set("filesToLoad", this.get("filesToLoad") - 1);
                }).bind(this));
            }
        },
        onFileLoaded: (function () {
            if (this.get("filesToLoad") === 0) {
                this.set("isLoaded", true);
                this.set("isLoading", false);
            }
        }).observes("filesToLoad"),
        // Ratio is between 0 and 1
        changeColor: function changeColor(color, ratio, darker) {
            // Trim trailing/leading whitespace
            color = color.replace(/^\s*|\s*$/, "");
            // Expand three-digit hex
            color = color.replace(/^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i, "#$1$1$2$2$3$3");
            // Calculate ratio
            var difference = Math.round(ratio * 256) * (darker ? -1 : 1),

            // Determine if input is RGB(A)
            rgb = color.match(new RegExp("^rgba?\\(\\s*" + "(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])" + "\\s*,\\s*" + "(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])" + "\\s*,\\s*" + "(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])" + "(?:\\s*,\\s*" + "(0|1|0?\\.\\d+))?" + "\\s*\\)$", "i")),
                alpha = !!rgb && rgb[4] != null ? rgb[4] : null,

            // Convert hex to decimal
            decimal = !!rgb ? [rgb[1], rgb[2], rgb[3]] : color.replace(/^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i, function () {
                return parseInt(arguments[1], 16) + "," + parseInt(arguments[2], 16) + "," + parseInt(arguments[3], 16);
            }).split(/,/),
                returnValue;

            // Return RGB(A)
            return !!rgb ? "rgb" + (alpha !== null ? "a" : "") + "(" + Math[darker ? "max" : "min"](parseInt(decimal[0], 10) + difference, darker ? 0 : 255) + ", " + Math[darker ? "max" : "min"](parseInt(decimal[1], 10) + difference, darker ? 0 : 255) + ", " + Math[darker ? "max" : "min"](parseInt(decimal[2], 10) + difference, darker ? 0 : 255) + (alpha !== null ? ", " + alpha : "") + ")" :
            // Return hex
            ["#", pad(Math[darker ? "max" : "min"](parseInt(decimal[0], 10) + difference, darker ? 0 : 255).toString(16), 2), pad(Math[darker ? "max" : "min"](parseInt(decimal[1], 10) + difference, darker ? 0 : 255).toString(16), 2), pad(Math[darker ? "max" : "min"](parseInt(decimal[2], 10) + difference, darker ? 0 : 255).toString(16), 2)].join("");
        },
        lighterColor: function lighterColor(color, ratio) {
            return changeColor(color, ratio, false);
        },
        darkerColor: function darkerColor(color, ratio) {
            return changeColor(color, ratio, true);
        },
        colors: [[240, 163, 255], [0, 117, 220], [153, 63, 0], [76, 0, 92], [25, 25, 25], [0, 92, 49], [43, 206, 72], [255, 204, 153], [128, 128, 128], [148, 255, 181], [143, 124, 0], [157, 204, 0], [194, 0, 136], [0, 51, 128], [255, 164, 5], [255, 168, 187], [66, 102, 0], [255, 0, 16], [94, 241, 242], [0, 153, 143], [224, 255, 102], [116, 10, 255], [153, 0, 0], [255, 255, 128], [255, 255, 0], [255, 80, 5]]
    });

});