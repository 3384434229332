define('admin/models/technika/sensor', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    exports['default'] = DS['default'].Model.extend({
        name: DS['default'].attr("string"),
        description: DS['default'].attr("string"),
        unit: DS['default'].belongsTo("dictionary", { async: true }), //DS.attr('string'),
        unit_display_text: DS['default'].attr("string"),
        manufacturer: DS['default'].belongsTo("dictionary", { async: true }),
        manufacturer_id: DS['default'].attr("number"),
        manu_display_text: DS['default'].attr("string"),
        serial: DS['default'].attr("string"),
        company: DS['default'].attr("string"),
        csvColumn: DS['default'].attr("string"),
        formula: DS['default'].belongsTo("technika/sensor/type", { async: false }),
        formulaName: DS['default'].attr("string"),
        formulaData: DS['default'].attr("string"),
        type: DS['default'].belongsTo("dictionary", { async: true }),
        type_display_text: DS['default'].attr("string"),
        type_id: DS['default'].attr("number"),
        bottomScale: DS['default'].attr("number"),
        topScale: DS['default'].attr("number"),
        sampling: DS['default'].attr("string"),
        minValue: DS['default'].attr("string"),
        decimalPoint: DS['default'].attr("string"),
        maxValue: DS['default'].attr("string"),
        defaultGraphType: DS['default'].attr("string"),
        isMonitored: DS['default'].attr("boolean"),
        roundDeviation: DS['default'].attr("boolean"),
        station: DS['default'].belongsTo("technika/station", { async: false }),
        isActive: DS['default'].attr("boolean"),
        isService: DS['default'].attr("boolean"),
        sendRawData: DS['default'].attr("boolean"),
        inactiveDate: DS['default'].attr("string"),
        inactiveDescription: DS['default'].attr("string"),
        images: DS['default'].hasMany("file", { async: false }),
        warnings: DS['default'].hasMany("technika/sensor/warning", { async: true }),
        sensorValue: DS['default'].attr("string"),
        sensorDate: DS['default'].attr("string"),
        sensorAlarm: DS['default'].attr("string"),
        imageUri: DS['default'].attr("string"),
        rawId: DS['default'].attr("string"),
        imageUrl: (function () {
            if (this.get("imageUri") && this.get("imageUri").length > 0) {
                return Admin.get("static") + this.get("imageUri");
            }
            return null;
        }).property("imageUri"),
        hasImages: (function () {
            return this.get("images").length > 0 ? true : false;
        }).property("images"),
        hasSerial: (function () {
            return this.get("serial") !== null ? true : false;
        }).property("serial"),
        hasType: (function () {
            return this.get("type_id") !== null ? true : false;
        }).property("type_id"),
        hasManufacturer: (function () {
            return this.get("manufacturer_id") !== null ? true : false;
        }).property("manufacturer_id")
    });

});