define('admin/adapters/application', ['exports', 'ember-data', 'ember-cli-form-data/mixins/form-data-adapter', 'ember'], function (exports, DS, FormDataAdapterMixin, Ember) {

  'use strict';

  exports['default'] = DS['default'].RESTAdapter.extend(FormDataAdapterMixin['default'], {
    formDataTypes: ["POST"],
    application: null,
    isSingle: false,
    headers: {},
    init: function init() {
      this._super();
      this.set("application", Admin);
      this.set("host", this.get("application.server"));
    },
    jqXHRs: [],
    ajaxOptions: (function (_ajaxOptions) {
      var _ajaxOptionsWrapper = function ajaxOptions(_x, _x2, _x3) {
        return _ajaxOptions.apply(this, arguments);
      };

      _ajaxOptionsWrapper.toString = function () {
        return _ajaxOptions.toString();
      };

      return _ajaxOptionsWrapper;
    })(function (url, type, hash) {
      // Get default AjaxOptions
      var ajaxOptions = this._super(url, type, hash);

      // If the function was defined in the DS.RESTAdapter object,
      // we must call it in out new beforeSend hook.
      var defaultBeforeSend = function defaultBeforeSend() {};
      if (typeof ajaxOptions.beforeSend === "function") {
        defaultBeforeSend = ajaxOptions.beforeSend;
      }
      ajaxOptions.beforeSend = function (jqXHR, settings) {
        defaultBeforeSend(jqXHR, settings);
        this.jqXHRs.push(jqXHR); // Keep the jqXHR somewhere.
        var lastInsertIndex = this.jqXHRs.length - 1;
        jqXHR.always(function () {
          // Destroy the jqXHRs because the call is finished and
          // we don't need it anymore.
          this.jqXHRs.splice(lastInsertIndex, 1);
        });
      };

      return ajaxOptions;
    }),

    cancelQuery: function cancelQuery() {
      this.jqXHRs.forEach(function (req) {
        req.abort();
      });
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query, operation) {
      var url = [],
          host = this.get("host"),
          prefix = this.urlPrefix();
      if (id !== undefined && id !== null && !Ember['default'].isArray(id) && !this.get("isSingle")) {
        if (modelName) {
          url.push(Ember['default'].String.camelize(modelName));
        }
        url.push(id);
      } else if (modelName && (snapshot && snapshot.record.get("isNew") || this.get("isSingle"))) {
        url.push(Ember['default'].String.camelize(modelName));
      } else if (modelName) {
        url.push(this.pathForType(modelName));
      }

      //    ("r", snapshot);
      //We might get passed in an array of ids from findMany
      //in which case we don't want to modify the url, as the
      //ids will be passed in through a query param

      //(url, this.get('namespace'));
      if (prefix) {
        url.unshift(prefix);
      }
      //(url, "========");
      url = url.join("/");
      if (!host && url) {
        url = "/" + url;
      }
      var lang = "";
      //    if (snapshot) {
      //      (snapshot.getProperties());
      //    }
      if (snapshot && snapshot.record && snapshot.record.get("locale")) {
        lang = "?culture=" + snapshot.record.get("locale");
      }
      if (operation) {
        url += "/" + operation;
      }
      //    if (App.SystemInformation.get('tmpCulture') !== null) {
      //      lang = "?culture=" + App.SystemInformation.get('tmpCulture');
      //      App.SystemInformation.set('tmpCulture', null)
      //    }

      return url + ".json" + lang;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      //(status, headers, payload);
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } else if (this.isInvalid(status, headers, payload)) {
        return new DS['default'].InvalidError(payload.errors);
      }

      var errors = this.normalizeErrorResponse(status, headers, payload);
      var res = new DS['default'].AdapterError(errors, payload.message);
      res.messages = payload.messages;
      return res;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    }
  });

  //     "API_KEY": "secret key",
  //     "ANOTHER_HEADER": "Some header value"

});