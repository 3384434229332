define('admin/mixins/serializable', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    serialize: function serialize() {
      var result = {};
      for (var key in jQuery['default'].extend(true, {}, this)) {
        // Skip these
        if (key === "isInstance" || key === "isDestroyed" || key === "isDestroying" || key === "concatenatedProperties" || key === "application" || typeof this[key] === "function") {
          continue;
        }
        result[key] = this[key];
      }
      return result;
    },
    toJSON: function toJSON() {
      return JSON.stringify(this.serialize());
    }
  });

});