define('admin/templates/components/field-multiselect', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","ui primary button");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element0,0,0);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["showModal",["get","controllerName",["loc",[null,[1,52],[1,66]]]],["get","into",["loc",[null,[1,67],[1,71]]]],["get","outlet",["loc",[null,[1,72],[1,78]]]],"multiselect"],[],["loc",[null,[1,31],[1,94]]]],
        ["inline","t",[["get","buttonlabel",["loc",[null,[1,99],[1,110]]]]],[],["loc",[null,[1,95],[1,112]]]],
        ["content","yield",["loc",[null,[2,0],[2,9]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});