define('admin/routes/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    actions: {
      showModal: function showModal(controllerName, into, outlet, type, parent) {

        var controller = this.controllerFor(controllerName);
        if (parent) {
          controller.set("parentController", parent);
        }
        var template = "helper/modal";
        if (type === "multiselect") {
          controller.setMultiselect(true);
          controller.load();
        } else if (type === "singleselect") {
          controller.setSingleselect(true);
          controller.load();
        } else if (type === "edit") {
          template = "helper/modaledit";
          controller.load();
        } else if (type === "new") {
          template = "helper/modaledit";
          controller.load(0);
        }
        //console.info(template, controllerName, into, outlet, type);
        var intoController = this.controllerFor(into);
        var controller = this.controllerFor("admin/company/list");
        //        this.render(template, {
        //          into: into,
        //          outlet: outlet,
        //          controller: intoController
        //        });
        //
        //        if (type !== "edit" && type !== "new") {
        //          this.render('pager', {
        //            outlet: 'pager',
        //            into: "helper/modal",
        //            controller: controller
        //          });
        //          this.render('listFilter', {
        //            outlet: 'filter',
        //            into: "helper/modal",
        //            controller: controller
        //          });
        //        }
        //        this.render(controllerName, {
        //          into: template,
        //          outlet: "modal-content",
        //          controller: controller
        //        });
        //        ("asasa2");
      },
      closeModal: function closeModal(outlet, view) {
        return this.disconnectOutlet({
          outlet: outlet,
          parentView: view
        });
      }
    }
  });

});