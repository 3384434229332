define('admin/models/dictionary', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    category: DS['default'].attr("string"),
    value: DS['default'].attr("string"),
    dictionaryKey: "admin.dictionary.enum.type",
    displayValue: (function () {
      var res = this.get("i18n").t(this.get("dictionaryKey")).findBy("key", this.get("category"));
      if (res) {
        return res.value;
      }
    }).property("category"),
    locale: DS['default'].attr("string")
  });

});