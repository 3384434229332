define('admin/initializers/system-information', ['exports', 'jquery', 'ember', 'admin/utils/system-log', 'admin/services/auth-manager', 'admin/services/system-information', 'admin/services/cookie'], function (exports, jQuery, Ember, SystemLog, AuthManager, SystemInformation, Cookie) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    application.deferReadiness();

    jQuery['default'](window).ajaxSend((function () {
      application.set("isLoading", application.get("isLoading") + 1);
    }).bind(application));
    jQuery['default'](window).ajaxComplete((function () {
      application.set("isLoading", application.get("isLoading") - 1);
    }).bind(application));
    // openlayers service
    ["component", "controller"].forEach(function (type) {
      application.inject(type, "openlayers", "service:openlayers");
    });
    // wykresy
    ["component", "controller"].forEach(function (type) {
      application.inject(type, "chartjs", "service:chartjs");
    });
    ["component", "controller"].forEach(function (type) {
      application.inject(type, "amcharts", "service:amcharts");
    });
    // authManager
    var authManager = AuthManager['default'].create({
      host: application.get("server"),
      application: application
    });
    // rejestracja authManagera
    container.register("cyfra:authManager", authManager, { instantiate: false, singleton: true });
    ["component", "controller", "model", "route"].forEach(function (type) {
      application.inject(type, "authManager", "cyfra:authManager");
    });
    application.set("authManager", authManager);
    // systemInformation
    var systemInformation = new SystemInformation['default'](application.get("server"));
    container.register("cyfra:systemInformation", systemInformation, { instantiate: false, singleton: true });
    ["component", "controller", "model", "route"].forEach(function (type) {
      application.inject(type, "systemInformation", "cyfra:systemInformation");
    });
    application.set("systemInformation", systemInformation);
    systemInformation.load().then(function () {
      authManager.loadSession().then(function () {
        jQuery['default']("body").removeClass("splash");
        jQuery['default']("#splash").remove();
        application.advanceReadiness();
      });
    });
  }

  exports['default'] = {
    name: "system-information",
    after: "settings",
    initialize: initialize
  };

  //    'view'

  //    'view'

});