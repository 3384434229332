define('admin/models/storehouseitem', ['exports', 'ember-data', 'admin/utils/price'], function (exports, DS, Price) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    warehouse: DS['default'].belongsTo("storehouse", { async: true }),
    item: DS['default'].belongsTo("warehouseitem", { async: true }),
    quantity: DS['default'].attr("integer"),
    unitNetPrice: (function () {
      var p = this.get("unitGrossPrice");
      if (!p) {
        p = "0";
      }
      p = p.replace(/[, ]/g, "");
      var nf = new NumberFormat(this.get("locale"), NumberFormat.locale[this.get("locale")].CURRENCY_PATTERN2);
      return nf.format(Price['default'].netFromGross(p, this.get("item.tax.tax")));
    }).property("unitGrossPrice", "quantity"),
    unitGrossPrice: DS['default'].attr("string"),
    grossPrice: (function (key, value) {
      var nf = new NumberFormat(this.get("locale"), NumberFormat.locale[this.get("locale")].CURRENCY_PATTERN2);
      var p = value;
      if (arguments.length !== 1) {
        if (!p) {
          p = "0";
        }
        p = p.replace(/[, ]/g, "");
        this.set("unitGrossPrice", nf.format(String(p / this.get("quantity"))));
      }
      p = this.get("unitGrossPrice");
      if (!p) {
        p = "0";
      }
      p = p.replace(/[, ]/g, "");
      return nf.format(p * this.get("quantity"));
    }).property("unitGrossPrice", "quantity"),
    netPrice: (function () {
      var p = this.get("unitGrossPrice");
      if (!p) {
        p = "0";
      }
      p = p.replace(/[, ]/g, "");
      var nf = new NumberFormat(this.get("locale"), NumberFormat.locale[this.get("locale")].CURRENCY_PATTERN2);
      return nf.format(Price['default'].netFromGross(p * this.get("quantity"), this.get("item.tax.tax")));
    }).property("unitGrossPrice", "quantity"),
    currency: DS['default'].attr("string"),
    locale: DS['default'].attr("string") });

});