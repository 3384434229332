define('admin/helpers/getval', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.helperfunc = helperfunc;

  function helperfunc(params) {
    var item = params[0];
    return item.id;
  }

  exports['default'] = Ember['default'].Helper.helper(helperfunc);

});