define('admin/controllers/account/password', ['exports', 'ember', 'admin/controllers/edit', 'jquery', 'admin/mixins/serializable'], function (exports, Ember, Edit, jQuery, Serializable) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "account",
    redirect: "account.password",
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "account.edit",
        text: "account.navigation.edit"
      });
      this.get("navigation").pushObject({
        route: "account.password",
        text: "account.navigation.password"
      });
      this.get("navigation").pushObject({
        route: "account.history",
        text: "account.navigation.history"
      });
    },
    load: function load() {
      var password = Ember['default'].Object.reopen(Serializable['default'], {
        application: this,
        newPassword: null,
        repeatedNewPassword: null,
        oldPassword: null,
        rollbackAttributes: function rollbackAttributes() {
          this.setProperties({
            newPassword: null,
            repeatedNewPassword: null,
            oldPassword: null
          });
        } });
      this.set("model", password.create());
    },
    //    multiselect: function (view) {
    //      view.$().find('input[type=checkbox]:checked').each(function (i, el) {
    //        this.get('model.securityRoles').pushObject(view.get('controller.store').getById('securityrole', jQuery(el).val()));
    //      }.bind(this));
    //    },
    title: (function () {
      return "account.password.title";
    }).property("model"),
    actions: {
      save: function save() {
        this.set("isLoading", true);
        //('password');
        //this.set('isLoading', false);
        jQuery['default'].ajax({
          url: this.get("application").get("server") + "/account/password/change.json",
          method: "PUT",
          data: this.get("model").serialize(),
          //contentType: 'application/json',
          dataType: "json",
          error: (function (response) {
            this.set("isLoading", false);
            if (response.responseJSON) {
              //(response.responseJSON);
              this.set("formErrors", response.responseJSON.fields);
              this.get("application").error("app.error.form.save", response.responseJSON.message);
            }
          }).bind(this),
          success: (function () {
            this.set("isLoading", false);
            this.get("model").rollbackAttributes();
          }).bind(this)
        });
      }
    }
  });

});