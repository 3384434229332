define('admin/models/shop/product', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    locale: DS['default'].attr("string"),
    currency: DS['default'].attr("string"),
    currencyLocale: DS['default'].attr("string"),
    grossPrice: DS['default'].attr("string"),
    publish: DS['default'].attr("boolean"),
    deliveryMethod: DS['default'].attr("string"),
    tax: DS['default'].belongsTo("tax", { async: true }),
    saleGroups: DS['default'].hasMany("shop/salegroup", { async: true }) });

});