define('admin/controllers/technika/station/maintenance/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

    'use strict';

    exports['default'] = List['default'].extend({
        modelName: "technika/station/maintenance",
        init: function init() {
            this._super();
        }
    });

});