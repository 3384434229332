define('admin/models/shop/cart/payment', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    locale: DS['default'].attr("string"),
    amount: DS['default'].attr("string"),
    isDefault: DS['default'].attr("boolean"),
    selected: DS['default'].attr("boolean") });

});