define('admin/tests/unit/utils/price-test', ['admin/utils/price', 'qunit'], function (price, qunit) {

  'use strict';

  qunit.module("price");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = price['default']();
    assert.ok(result);
  });

});