define('admin/views/dashboard', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    modal1: null,
    modal2: null,
    didInsertElement: function didInsertElement() {
      this._super();
      this.set("modal1", this.$().find("#modal1"));
      this.set("modal2", this.$().find("#modal2"));
      this.get("modal1").modal({});
    },
    actions: {
      showModal1: function showModal1() {
        this.get("modal1").modal("show");
      },
      hideModal1: function hideModal1() {
        this.get("modal1").modal("hide");
      }
    }
  });

});