define('admin/components/ui-amcharts-trend', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        isLoading: false,
        noData: false,
        chart: null,
        //    init: function () {
        //      this.get('amcharts').load();
        //      this._super();
        //    },
        classNames: ["ui", "segment"],
        didRender: function didRender() {
            this.$().css({
                width: "100%",
                height: "80px",
                padding: "0px"
            });
        },
        loadHourlyData: function loadHourlyData(isLoading, id, maxTime, minTime) {
            this.set(isLoading, true);
            return jQuery['default'].ajax({

                url: Admin.get("server") + "/technika/sensor/" + id + "/data/daily.json",

                data: {
                    from: minTime,
                    to: maxTime },
                complete: (function () {
                    this.set(isLoading, false);
                }).bind(this)
            });
        },
        onChart: (function () {
            var chart = this.get("amcharts").smallLine(100, 100);
            var lng = jQuery['default'](".admin-content").width();
            lng = lng - 150;
            this.loadHourlyData("isLoading", this.get("sensor_id"), moment().format(), moment().subtract(10, "days").format()).then((function (data) {
                chart.dataProvider = data.data;
                this.set("chart", chart);
                this.get("chart").write(this.$(".chart_" + this.get("sensor_id"))[0]);
                this.$("a:contains('JS chart by amCharts')").remove();
                this.$(".chart_" + this.get("sensor_id")).width(lng);
                this.set("sensor_id", this.get("sensor_id"));
            }).bind(this));
        }).on("didInsertElement")

        //    onLibLoaded: function () {
        //      if (this.get('amcharts.isLoaded')) {
        //
        ////var chart = new AmCharts.AmSerialChart();
        ////chart.dataProvider = chartData;
        ////chart.categoryField = "country";
        ////var graph = new AmCharts.AmGraph();
        ////graph.valueField = "visits";
        ////graph.type = "column";
        ////chart.addGraph(graph);
        ////var categoryAxis = chart.categoryAxis;
        ////categoryAxis.labelRotation = 45;
        ////chart.write(this.$().context);
        //      }
        //    }.observes('amcharts.isLoaded')
    });

});