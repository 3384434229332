define('admin/models/storehouse', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    city: DS['default'].attr("string"),
    zip: DS['default'].attr("string"),
    addresse: DS['default'].attr("string"),
    phone: DS['default'].attr("string"),
    name: DS['default'].attr("string"),
    company: DS['default'].belongsTo("company", { async: true })
  });

});