define('admin/routes/technika/sensor/details', ['exports', 'admin/routes/edit'], function (exports, Edit) {

    'use strict';

    exports['default'] = Edit['default'].extend({
        config: {
            into: "technika/sensor/details",
            controller: "technika/sensor/details" },
        beforeModel: function beforeModel() {
            this.controllerFor("technika.sensor.details").set("24h", "");
            this.controllerFor("technika.sensor.details").set("7d", "blue");
            this.controllerFor("technika.sensor.details").set("14d", "");
            this.controllerFor("technika.sensor.details").set("30d", "");
            this.controllerFor("technika.sensor.details").set("365d", "");
            this.controllerFor("technika.sensor.details").set("chartdata", []);
        } });

});