define('admin/models/warehouseitem', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    code: DS['default'].attr("string"),
    sku: DS['default'].attr("string"),
    unitOfMeasure: DS['default'].belongsTo("dictionary", { async: true }),
    tax: DS['default'].belongsTo("tax", { async: true }),
    ruleOfFreightDelivery: DS['default'].attr("string"),
    displayRuleOfFreightDelivery: (function () {
      var res = this.get("i18n").t("admin.enum.ruleOfFreightDelivery").findBy("key", this.get("ruleOfFreightDelivery"));
      if (res) {
        return res.value;
      }
    }).property("ruleOfFreightDelivery") });

});