define('admin/instance-initializers/i18n', ['exports', 'jquery', 'ember', 'admin/utils/system-log'], function (exports, jQuery, Ember, SystemLog) {

  'use strict';

  exports.initialize = initialize;

  function initialize(application) {
    var locale = Admin.get("systemInformation.locale").replace(/_[A-Z]{2}/, "");
    var systemInformation = application.container.lookup("service:systemInformation");
    var i18n = application.container.lookup("service:i18n");
    // ustawienie wersji jezykowej
    i18n.set("locale", locale);
    moment.locale(locale);
    // dodanie i18n do aplikacji
    Admin.set("i18n", i18n);
    // ustawienie tytułu
    jQuery['default']("title").text(i18n.t("app.name"));
    // logowanie rządań AJAX
    jQuery['default'](window).ajaxSuccess((function (event, jqXHR, ajaxOptions) {
      //event, jqXHR, ajaxOptions, data
      var log = null;
      if (ajaxOptions.type === "GET") {
        log = SystemLog['default'].create({
          title: i18n.t("ajax.get.success.title"),
          message: i18n.t("ajax.get.success.message", {
            url: ajaxOptions.url
          }),
          type: "success"
        });
      } else if (ajaxOptions.type === "POST") {
        log = SystemLog['default'].create({
          title: i18n.t("ajax.post.success.title"),
          message: i18n.t("ajax.post.success.message", {
            url: ajaxOptions.url
          }),
          type: "success"
        });
      } else if (ajaxOptions.type === "PUT") {
        log = SystemLog['default'].create({
          title: i18n.t("ajax.put.success.title"),
          message: i18n.t("ajax.put.success.message", {
            url: ajaxOptions.url
          }),
          type: "success"
        });
      }
      this.addMessage(log);
    }).bind(Admin));
    jQuery['default'](window).ajaxError((function (event, jqXHR, ajaxOptions) {
      //event, jqXHR, ajaxOptions, data
      var log = null;
      if (jqXHR.statusText === "OK") {
        return;
      }
      if (jqXHR.status === 403) {
        window.location.reload();
      }
      if (jqXHR.statusText === "abort") return;
      if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
        log = SystemLog['default'].create({
          title: i18n.t("ajax.error.title", {
            status: jqXHR.status,
            type: ajaxOptions.type
          }),
          message: i18n.t(jqXHR.responseJSON.message),
          type: "error"
        });
      } else {
        log = SystemLog['default'].create({
          title: i18n.t("ajax.error.title", {
            status: jqXHR.status,
            type: ajaxOptions.type
          }),
          message: i18n.t("ajax.error.message", {
            url: ajaxOptions.url,
            type: ajaxOptions.type
          }),
          type: "error"
        });
      }
      this.addMessage(log);
    }).bind(Admin));
  }

  ;
  exports['default'] = {
    name: "i18n",
    initialize: initialize
  };

});