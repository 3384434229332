define('admin/controllers/dashboard', ['exports', 'admin/controllers/map', 'admin/utils/openlayers/layer', 'jquery'], function (exports, Map, Layer, jQuery) {

  'use strict';

  exports['default'] = Map['default'].extend({
    allowSave: false,
    stationListController: Ember.inject.controller("technika/station/maplist"),
    init: function init() {
      this._super();
      this.get("amcharts").load();
      this.get("stationListController").load();
    },
    showAll: function showAll() {
      var min_lon = null;
      var min_lat = null;
      var max_lon = null;
      var max_lat = null;
      this.set("isLoading", true);
      this.get("store").query("technika/station", {}).then((function (data) {
        var test = data.toArray(); //data.content
        test.forEach(function (el) {
          if (min_lon === null || min_lon > el.get("lon")) {
            min_lon = el.get("lon");
          }
          if (max_lon === null || max_lon < el.get("lon")) {
            max_lon = el.get("lon");
          }
          if (min_lat === null || min_lat > el.get("lat")) {
            min_lat = el.get("lat");
          }
          if (max_lat === null || max_lat < el.get("lat")) {
            max_lat = el.get("lat");
          }
        });
        var poly = ol.geom.Polygon.fromExtent([min_lon, min_lat, max_lon, max_lat]);
        var feature = new ol.Feature({
          geometry: poly });
        this.send("goToFeature", feature);
        Ember.run.later((function () {
          this.set("isLoading", false);
        }).bind(this), 3000);
      }).bind(this), (function () {
        //(a,b,c,d);
        this.set("error", true);
        this.set("isLoading", false);
      }).bind(this));
    },

    loadData: function loadData(el) {
      var dfd = jQuery['default'].Deferred();
      setTimeout(function () {
        dfd.resolve(el.data, el.value, el.unit, el.max_registered, el.min_registered);
      }, 5);
      return dfd.promise();
    },

    beforeMapInit: function beforeMapInit() {
      var normal = new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.11],
          anchorOrigin: "bottom-left",
          scale: 0.45,
          opacity: 0.9,
          src: "assets/images/marker.png"
        })
      });
      var alarm = new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.11],
          anchorOrigin: "bottom-left",
          scale: 0.45,
          opacity: 0.9,
          src: "assets/images/marker_w.png"
        })
      });
      var styles = [normal, alarm];
      this.get("layerCollection").pushObject(Layer['default'].create({
        layer: new ol.layer.Vector({
          name: "Stacje",
          style: function style(feature, resolution) {
            feature.get("style");
            if (feature.get("style") == "alarm") {
              return [styles[1]];
            } else {
              return [styles[0]];
            }
          },
          source: new ol.source.Vector({
            format: new ol.format.GeoJSON(),
            strategy: ol.loadingstrategy.bbox,
            //projection: "EPSG:4326",
            //          tileGrid: new ol.tilegrid.createXYZ({
            //            maxZoom: 19
            //          }),
            url: (function (extent, resolution, projection) {
              var xy = ol.proj.transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
              var xy2 = ol.proj.transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
              return Admin.get("server") + "/technika/station/geojson/" + xy[0] + "/" + xy[1] + "/" + xy2[0] + "/" + xy2[1] + "/viewport.json?session=" + this.get("authManager").get("session.id");
            }).bind(this)
          })
        })
      }));
    },
    onMapInit: (function () {
      if (this.get("mapa")) {
        this.showAll();
        var sStyle = new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 0.11],
            anchorOrigin: "bottom-left",
            scale: 0.45,
            opacity: 0.9,
            src: "assets/images/marker_s.png"
          })
        });
        this.get("mapa").addInteraction(new ol.interaction.Select({
          style: [sStyle]
        }));
        var element = jQuery['default']("<div class=\"ui segment\" id=\"overppp\"></div>").appendTo("body");
        var popup = new ol.Overlay({
          element: element,
          offset: [0, -25] });
        this.get("mapa").addOverlay(popup);

        var element2 = jQuery['default']("<div class=\"ui segment\" id=\"overppp2\"></div>").appendTo("body");
        var popup2 = new ol.Overlay({
          element: element2,
          offset: [0, -25] });
        this.get("mapa").addOverlay(popup2);
        var target = this.get("mapa").getTarget();
        var jTarget = typeof target === "string" ? jQuery['default']("#" + target) : jQuery['default'](target);
        this.get("mapa").on("moveend", (function (evt) {
          if (popup2.rendered_.visible) {
            var coord = this.get("mapa").getCoordinateFromPixel([38, 40]);
            popup2.setPosition(coord);
          }
        }).bind(this));
        this.get("mapa").on("pointerdrag", (function (evt) {
          this.get("mapa").getInteractions().forEach(function (interaction) {
            if (interaction instanceof ol.interaction.Select) {
              interaction.getFeatures().clear();
            }
          });
          jQuery['default']("#overppp3").hide();
          jQuery['default']("#overppp2").hide();
        }).bind(this));
        this.get("mapa").on("pointermove", (function (evt) {
          var feature = this.get("mapa").forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature;
          });
          if (feature) {
            var geometry = feature.getGeometry();
            var coord = geometry.getCoordinates();

            var pop_cords = this.get("mapa").getPixelFromCoordinate(coord);
            var map_size = this.get("mapa").getSize();
            if (pop_cords[0] > map_size[0] / 2 && pop_cords[1] > map_size[1] / 2) {
              popup.setPositioning("bottom-right");
            } else if (pop_cords[0] > map_size[0] / 2 && pop_cords[1] < map_size[1] / 2) {
              popup.setPositioning("top-right");
            } else if (pop_cords[0] < map_size[0] / 2 && pop_cords[1] > map_size[1] / 2) {
              popup.setPositioning("bottom-left");
            } else if (pop_cords[0] < map_size[0] / 2 && pop_cords[1] < map_size[1] / 2) {
              popup.setPositioning("top-left");
            }
            popup.setPosition(coord);
            //          (feature, geometry, coord);
            var html = "<h5 class=\"ui header\">" + feature.get("name") + "</h5>";
            jTarget.css("cursor", "pointer");
            jQuery['default'](element).html(html).fadeIn(500);
          } else {
            jTarget.css("cursor", "");
            jQuery['default'](element).hide();
          }
        }).bind(this));
        var lastFeature = null;
        var currentStyle = null;
        this.get("mapa").on("click", (function (evt) {
          jQuery['default']("#overppp3").remove();
          var selectedStyle = new ol.style.Style({
            image: new ol.style.Icon({
              anchor: [0.5, 0.11],
              anchorOrigin: "bottom-left",
              scale: 0.45,
              opacity: 0.9,
              src: "assets/images/marker_s.png"
            })
          });
          //238, 275
          var feature = this.get("mapa").forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature;
          });
          if (feature) {

            var geometry = feature.getGeometry();
            var coord = this.get("mapa").getCoordinateFromPixel([38, 40]);
            popup2.setPosition(coord);
            var html = "<h5 class=\"ui header\" style>" + feature.get("name") + "</h5>";
            html += "<div style=\"overflow: auto; max-height: 550px; width: 550px;\"><table class=\"ui celled small table\"><thead><tr>";
            html += "<th style=\"font-size: 12px\">" + this.get("i18n").t("technika.station.map.sensor") + "</th>";
            html += "<th style=\"font-size: 12px\">" + this.get("i18n").t("technika.station.map.sensor.date") + "</th>";
            html += "<th style=\"font-size: 12px\">" + this.get("i18n").t("technika.station.map.sensor.value") + "</th>";
            html += "<th style=\"font-size: 12px\">Trend</th>";
            html += "</tr></thead><tbody>";
            feature.get("sensors").forEach((function (el, index) {
              ;
              this.loadData(el).then((function (data, value, unit, max, min) {
                var unit = unit == null ? "" : unit;
                var value = value == null ? "" : value;
                var max = max == null ? 0 : max;
                var min = min == null ? 0 : min;
                if (min > 0) {
                  min = 0;
                }
                var chart = this.get("amcharts").smallLine(min, max);
                chart.dataProvider = data;
                jQuery['default'].when(chart.write(jQuery['default'](".chart")[index])).done(function () {
                  jQuery['default']("a:contains('JS chart by amCharts')").remove();
                });
                var speed = this.get("amcharts").windSpeedGauge(min, max, value, unit);
                jQuery['default'].when(speed.write(jQuery['default'](".current")[index])).done(function () {
                  jQuery['default']("a:contains('JS chart by amCharts')").remove();
                });
              }).bind(this));
              html += "<tr><td style=\"font-size: 11px; color:" + el.alarm + "\">" + el.name + "</td>";
              html += "<td style=\"font-size: 11px; color:" + el.alarm + "\">" + moment(el.date).format("DD.MM.YYYY, HH:mm") + "</td>";
              html += "<td style=\"font-size: 11px; color:" + el.alarm + "\"><div class=\"current\" style=\"width: 100px; height: 70px;\"></div></td>";
              html += "<td style=\"font-size: 11px; color:" + el.alarm + "\"><div class=\"chart\" style=\"width: 150px; height: 70px;\"></div></td>";
            }).bind(this));
            html += "</tr></tbody></table></div>";
            jQuery['default'](element2).html(html).css({ opacity: 0.85 }).show();
          } else {
            jQuery['default'](element2).hide();
          }
        }).bind(this));
      }
    }).observes("mapa"),
    //      let layer1 = new ol.layer.Tile({
    //        name: "OpenStreetMap (landscape)",
    //        source: new ol.source.OSM({
    //          attributions: [
    //  //          new ol.Attribution({
    //  //            html: 'All maps &copy; ' +
    //  //                '<a href="http://www.openseamap.org/">OpenStreetMap</a>'
    //  //          }),
    //            ol.source.OSM.ATTRIBUTION
    //          ],
    //          crossOrigin: null,
    //          url: 'http://tile.thunderforest.com/landscape/{z}/{x}/{y}.png'
    //        })
    //      });
    ////      let layer2 = new ol.layer.Vector({
    ////        source: new ol.source.Vector({
    ////          url: Admin.get('server') + 'api/technika/station/topo.json',
    ////          format: new ol.format.TopoJSON()
    ////        }),
    ////        style: function(feature, resolution) {
    ////          // don't want to render the full world polygon, which repeats all countries
    ////          return feature.getId() !== undefined ? styleArray : null;
    ////        }
    ////      });
    //      var style = new ol.style.Style({
    //        image: new ol.style.Icon(({
    //          anchor: [0.5, 0.11],
    //          anchorOrigin: "bottom-left",
    //          scale: 0.3,
    //          opacity: 0.9,
    //          src: 'assets/images/marker.png'
    //        }))
    //      });
    //      let layer2 = new ol.layer.Vector({
    //        name: "Stacje",
    //        style: style,
    //        source: new ol.source.TileVector({
    //          format: new ol.format.GeoJSON(),
    //          //projection: "EPSG:4326",
    //          tileGrid: new ol.tilegrid.createXYZ({
    //            maxZoom: 19
    //          }),
    //          url: Admin.get('server') + '/technika/station/geojson/{z}/{x}/{y}.json?session=' + this.get('authManager').get('session.id')
    //        })
    //      });
    //      this.set('mapa', new ol.Map({
    //        target: target,
    //        layers: [layer1, layer2],
    //        view: new ol.View({
    //          maxZoom: 18,
    //          center: [2068940.1538519368, 6492121.669630231],
    //          zoom: 13,
    ////          projection: 'EPSG:4326'
    ////          projection: 'EPSG:3857'
    //        })
    //      }));
    //
    //      this.get('mapa').on('click', function (evt) {
    ////        ('click');
    ////        var map = evt.map;
    ////        var lonlat = ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    //        //var lonlat = map.getEventCoordinate(evt);
    ////        (evt.coordinate, lonlat);
    //      }.bind(this));
    ////      this.get('mapa').getLayers().forEach(function (el) {
    ////        (el.get('name'));
    ////      });
    //  },

    actions: {
      showOnMap: function showOnMap(station) {
        var lonlat = ol.proj.transform([station.get("lon"), station.get("lat")], "EPSG:4326", "EPSG:3857");
        var size = this.get("mapa").getSize();
        this.get("mapa").getView().centerOn(lonlat, size, [size[0] / 2, size[1] / 2]);
        this.get("mapa").getView().setZoom(14);
      },
      showAllStations: function showAllStations() {
        //
        this.showAll();
      }
    }
  });

  //autoPan: true,
  //autoPanAnimation: {
  //duration: 500
  //},
  //positioning: 'center-center'

  //autoPan: true,
  //autoPanAnimation: {
  //duration: 500
  //},
  //positioning: 'center-center'

});