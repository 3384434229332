define('admin/routes/technika/stats/chart', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            this.controllerFor("technika.sensor.details").set("24h", "");
            this.controllerFor("technika.sensor.details").set("7d", "blue");
            this.controllerFor("technika.sensor.details").set("14d", "");
            this.controllerFor("technika.sensor.details").set("30d", "");
            this.controllerFor("technika.sensor.details").set("365d", "");
            this.controllerFor("technika.sensor.details").set("chartdata", []);
        },
        renderTemplate: function renderTemplate() {
            this._super();
            //      (this.get('config'));
            var controller = this.controllerFor("technika.stats.chart");
            controller.load();
        }
    });

});