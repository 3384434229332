define('admin/services/amcharts', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    isLoaded: false,
    isLoading: false,
    filesToLoad: 11,
    load: function load() {
      if (this.get("isLoaded") === false && this.get("isLoading") === false) {
        this.set("isLoading", true);
        jQuery['default'].getScript("assets/amcharts/amcharts/amcharts.js", (function () {
          this.set("filesToLoad", this.get("filesToLoad") - 1);
          jQuery['default'].getScript("assets/amcharts/amcharts/funnel.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/gantt.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/gauge.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/pie.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/radar.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/serial.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/xy.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/lang/pl.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/themes/light.js", (function () {
            AmCharts.theme = AmCharts.themes.light;
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
          jQuery['default'].getScript("assets/amcharts/amcharts/plugins/export/export.js", (function () {
            this.set("filesToLoad", this.get("filesToLoad") - 1);
          }).bind(this));
        }).bind(this));
      }
    },
    onFileLoaded: (function () {
      if (this.get("filesToLoad") === 0) {
        this.set("isLoaded", true);
        this.set("isLoading", false);
      }
    }).observes("filesToLoad"),

    multiLines: function multiLines(minValue, maxValue, objects, format) {
      var collors = ["#0000FF", "#FFA500", "#008000", "#FF0000", "#800000"];
      var sampling = [];
      for (var _i = 0; _i < objects.length; _i++) {
        //if (objects[i].sampling > sampling) {
        sampling.push(objects[_i].sampling);
        //}
      }
      //(sampling);
      var max_sampling = Math.max.apply(Math, sampling);
      var min_sampling = Math.min.apply(Math, sampling);
      var chart = new AmCharts.AmSerialChart();
      chart.language = "pl";
      //chart.dataProvider = data;
      //chart.dataDateFormat = "YYYY-MM-DD",
      chart.categoryField = "date";
      chart.synchronizeGrid = true;
      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/", chart.legend = {
        useGraphSettings: true
      };
      chart.categoryAxis = {
        parseDates: true,
        dashLength: 1,
        minorGridEnabled: true,
        minPeriod: min_sampling + "mm",
        equalSpacing: true
      };
      chart["export"] = {
        enabled: true,
        menu: [{
          format: "PDF",
          label: "Do PDF",
          title: "Zapisz jako PDF" }]
      };
      var bf = function bf(GraphDataItem, AmGraph) {
        //(GraphDataItem, AmGraph);
        if (format === "hh") {
          return moment(GraphDataItem.dataContext.date).format("LT") + ": <b>" + GraphDataItem.dataContext.value + "</b>";
        } else if (format === "DD") {
          return moment(GraphDataItem.dataContext.date).format("Do MMMM") + ": <b>" + GraphDataItem.dataContext.value + "</b>";
        } else if (format === "MM") {
          return moment(GraphDataItem.dataContext.date).format("MMMM YYYY") + ": <b>" + GraphDataItem.dataContext.value + "</b>";
        }
        return GraphDataItem.dataContext.date + ": <b>" + GraphDataItem.dataContext.value + "</b>";
      };
      //objects = [10];
      var offset = 0;
      var units = [];
      var added_axis = [];
      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        offset = offset + 80;
        var valueAxis = new AmCharts.ValueAxis();
        valueAxis.id = "v_" + object.id;
        valueAxis.tickLength = 2;
        valueAxis.axisColor = collors[i];
        valueAxis.maximum = maxValue;
        valueAxis.minimum = minValue;
        //valueAxis.baseValue = minValue;
        valueAxis.gridAlpha = 0;
        valueAxis.axisAlpha = 1;
        valueAxis.position = i == 0 ? "left" : "left";
        valueAxis.offset = offset;
        valueAxis.title = object.name + " " + object.unit;
        chart.addValueAxis(valueAxis);
        added_axis.push(object.unit);

        var graph = new AmCharts.AmGraph();
        graph.id = "g_" + object.id;
        graph.valueAxis = "v_" + object.id;
        graph.valueField = "value_" + object.id;
        graph.type = object.type == "water-level" || object.type == "water-temperature" ? "line" : "column";
        graph.bullet = "round";
        graph.lineColor = collors[i];
        graph.fillAlphas = object.type == "water-drop" ? 0.9 : 0;
        graph.bulletBorderAlpha = 1;
        graph.bulletColor = "#FFFFFF";
        graph.bulletSize = 5;
        graph.hideBulletsCount = 50;
        graph.lineThickness = 1;
        graph.title = object.name + " " + object.unit + " (" + object.station + ") ";
        graph.useLineColorForBulletBorder = true;
        //(object.sampling, min_sampling, max_sampling)
        if (object.type == "water-temperature" || object.sampling > min_sampling) {
          graph.connect = true;
        } else {
          graph.connect = false;
        }
        //graph.balloonFunction = bf;
        graph.balloonText = "[[value]]";
        //graph.connect = false;
        chart.addGraph(graph);

        var chartCursor = new AmCharts.ChartCursor();
        chartCursor.pan = true;
        chartCursor.valueLineEnabled = false;
        chartCursor.valueLineBalloonEnabled = false;
        chartCursor.cursorAlpha = 1;
        chartCursor.cursorColor = "#258cbb", chartCursor.limitToGraph = "g_" + object.id, chartCursor.valueLineAlpha = 0.2;
        chartCursor.zoomable = true;
        chartCursor.valueZoomable = true;
        chart.addChartCursor(chartCursor);
        chartCursor.categoryBalloonDateFormat = "DD MMMM YYYY HH:NN";
        chart["export"] = {
          enabled: true,
          position: "bottom-right"
        };
      }

      var scrollbar = new AmCharts.ChartScrollbar();
      //scrollbar.graph = "g1";
      scrollbar.oppositeAxis = false;
      scrollbar.offset = 30;
      scrollbar.scrollbarHeight = 80;
      scrollbar.backgroundAlpha = 0;
      scrollbar.selectedBackgroundAlpha = 0.1;
      scrollbar.selectedBackgroundColor = "#888888";
      scrollbar.graphFillAlpha = 0;
      scrollbar.graphLineAlpha = 0.5;
      scrollbar.selectedGraphFillAlpha = 0;
      scrollbar.selectedGraphLineAlpha = 1;
      scrollbar.autoGridCount = true;
      scrollbar.color = "#AAAAAA";
      scrollbar.updateOnReleaseOnly = true;
      chart.addChartScrollbar(scrollbar);
      var categoryAxis = chart.categoryAxis;
      categoryAxis.labelRotation = 45;

      return chart;
    },
    smallLine: function smallLine(minValue, maxValue) {
      var chart = new AmCharts.AmSerialChart(AmCharts.themes.none);
      chart.categoryField = "day";
      chart.autoMargins = false;
      chart.marginLeft = 0;
      chart.marginRight = 5;
      chart.marginTop = 0;
      chart.marginBottom = 0;

      var graph = new AmCharts.AmGraph();
      graph.valueField = "value";
      graph.bulletField = "bullet";
      graph.showBalloon = false;
      graph.lineColor = "#1E82EE";
      chart.addGraph(graph);

      var valueAxis = new AmCharts.ValueAxis();
      valueAxis.gridAlpha = 0;
      valueAxis.axisAlpha = 0;
      chart.addValueAxis(valueAxis);

      var categoryAxis = chart.categoryAxis;
      categoryAxis.gridAlpha = 0;
      categoryAxis.axisAlpha = 0;
      categoryAxis.startOnAxis = true;
      chart.write("line1");

      return chart;
    },
    waterLevelLine: function waterLevelLine(minValue, maxValue, warnings, format, mode, object) {
      var rawData = object.get("sendRawData");
      var sampling = parseInt(object.get("sampling"));
      if (!rawData) {}
      sampling = sampling + "mm";
      var chart = new AmCharts.AmSerialChart();
      chart.language = "pl";
      chart.marginRight = 70;
      chart.autoMarginOffset = 20;
      //chart.dataProvider = data;
      //chart.dataDateFormat = "YYYY-MM-DD hh",
      chart.legend = {
        useGraphSettings: true
      };
      chart.categoryField = "date";
      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/", chart.categoryAxis = {
        parseDates: true,
        dashLength: 1,
        minorGridEnabled: true,
        minPeriod: sampling
      };
      chart["export"] = {
        enabled: true,
        menu: [{
          format: "PDF",
          label: "Do PDF",
          title: "Zapisz jako PDF" }]
      };

      var graph = new AmCharts.AmGraph();
      graph.id = "g1";
      graph.valueField = "value";
      //graph.type = "line";
      graph.bullet = "round";
      graph.connect = mode == "demo" ? true : false;
      graph.bulletBorderAlpha = 1;
      graph.bulletColor = "#FFFFFF";
      graph.bulletSize = 2;
      graph.hideBulletsCount = 100;
      graph.lineThickness = 1;
      graph.title = object.get("name");
      graph.lineColor = mode == "demo" ? "#DCDCDC" : "#1E90FF";
      graph.useLineColorForBulletBorder = true;
      graph.valueField = "value";
      graph.balloonText = "<div style='margin:5px; font-size:19px;'><span style='font-size:18px;'>[[category]]</span><br>[[value]]</div>";
      graph.balloonFunction = function (GraphDataItem, AmGraph) {
        return moment(GraphDataItem.dataContext.date).format("Do MMMM - HH:mm") + " pomiar <b>" + GraphDataItem.dataContext.value + "</b>";
      };
      chart.addGraph(graph);

      var chartCursor = new AmCharts.ChartCursor();
      chartCursor.pan = true;
      chartCursor.valueLineEnabled = true;
      chartCursor.valueLineBalloonEnabled = true;
      chartCursor.cursorAlpha = 1;
      chartCursor.cursorColor = "#258cbb", chartCursor.limitToGraph = "g1", chartCursor.valueLineAlpha = 0.2;
      chartCursor.zoomable = true;
      chartCursor.valueZoomable = true;
      chartCursor.categoryBalloonDateFormat = "DD MMMM YYYY HH:NN";
      chart.addChartCursor(chartCursor);

      var scrollbar = new AmCharts.ChartScrollbar();
      scrollbar.graph = "g1";
      scrollbar.oppositeAxis = false;
      scrollbar.offset = 30;
      scrollbar.scrollbarHeight = 80;
      scrollbar.backgroundAlpha = 0;
      scrollbar.selectedBackgroundAlpha = 0.1;
      scrollbar.selectedBackgroundColor = "#888888";
      scrollbar.graphFillAlpha = 0;
      scrollbar.graphLineAlpha = 0.5;
      scrollbar.selectedGraphFillAlpha = 0;
      scrollbar.selectedGraphLineAlpha = 1;
      scrollbar.autoGridCount = true;
      scrollbar.color = "#AAAAAA";
      chart.addChartScrollbar(scrollbar);
      //        var categoryAxis = chart.categoryAxis;
      //        categoryAxis.labelRotation = 45;

      var valueAxis = new AmCharts.ValueAxis();
      valueAxis.tickLength = 4;
      valueAxis.maximum = maxValue;
      valueAxis.minimum = minValue;
      valueAxis.title = object.get("name") + " " + object.get("unit").get("value");
      chart.addValueAxis(valueAxis);
      warnings.forEach((function (warningL) {
        if (warningL.get("isActive")) {
          warningL.get("level_lte");
          var guide = new AmCharts.Guide();
          guide.value = warningL.get("level_lte") == null ? warningL.get("level_gte") : warningL.get("level_lte");
          guide.label = warningL.get("name");
          guide.lineAlpha = 1;
          guide.lineThickness = 2;
          guide.lineColor = guide.color = warningL.get("color");
          guide.above = true;
          guide.inside = true;
          this.addGuide(guide);
        }
      }).bind(valueAxis));

      return chart;
    },
    waterLevelCylinder: function waterLevelCylinder(minValue, maxValue, warnings, mode) {
      var chart = new AmCharts.AmSerialChart();
      chart.language = "pl";

      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/", chart.depth3D = 100;
      chart.angle = 30;
      chart.categoryField = "category";

      var graph = new AmCharts.AmGraph();
      graph.type = "column";
      graph.valueField = "value";
      graph.topRadius = 1;
      graph.columnWidth = 1;
      graph.showOnAxis = true;
      graph.lineThickness = 2;
      graph.lineAlpha = 0.5;
      graph.lineColor = "#FFFFFF";
      graph.fillColors = "#8d003b";
      graph.fillAlphas = 0.8;
      chart.addGraph(graph);

      var graph = new AmCharts.AmGraph();
      graph.type = "column";
      graph.valueField = "maxValue";
      graph.topRadius = 1;
      graph.columnWidth = 1;
      graph.showOnAxis = true;
      graph.lineThickness = 2;
      graph.lineAlpha = 0.5;
      graph.lineColor = "#cdcdcd";
      graph.fillColors = "#cdcdcd";
      graph.fillAlphas = 0.5;
      chart.addGraph(graph);
      //       
      //       
      var valueAxis = new AmCharts.ValueAxis();
      valueAxis.tickLength = 4;
      valueAxis.stackType = "regular";
      valueAxis.gridAlpha = 0;
      valueAxis.maximum = maxValue;
      valueAxis.minimum = minValue;
      //        valueAxis.title = this.get('model.unit');
      chart.addValueAxis(valueAxis);
      warnings.forEach((function (warningL) {
        if (warningL.get("isActive")) {
          var guide = new AmCharts.Guide();
          guide.value = warningL.get("level_lte") == null ? warningL.get("level_gte") : warningL.get("level_lte");
          guide.label = warningL.get("name");
          guide.lineAlpha = 1;
          guide.lineThickness = 2;
          guide.lineColor = guide.color = warningL.get("color");
          guide.above = true;
          guide.inside = true;
          this.addGuide(guide);
        }
      }).bind(valueAxis));

      return chart;
    },
    waterTemperatureBullet: function waterTemperatureBullet(minValue, maxValue, warnings, mode) {
      var chart = new AmCharts.AmSerialChart();
      chart.language = "pl";

      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/", chart.rotate = true;
      chart.categoryField = "category";
      chart.autoMargins = false;
      chart.marginTop = 30;
      chart.marginLeft = 80;
      chart.marginBottom = 30;
      chart.marginRight = 50;

      var graph = new AmCharts.AmGraph();
      graph.type = "column";
      graph.valueField = "value";
      graph.clustered = false;
      graph.columnWidth = 0.3;
      graph.fillAlphas = 1;
      graph.lineColor = "#000000";
      graph.stackable = false;
      chart.addGraph(graph);

      var graph = new AmCharts.AmGraph();
      graph.valueField = "maxValue";
      graph.showBalloon = false;
      graph.type = "column";
      graph.lineAlpha = 0;
      graph.fillAlphas = 0.8;
      graph.fillColors = ["#00FF99", "#f6d32b", "#fb2316"];
      graph.gradientOrientation = "horizontal";
      chart.addGraph(graph);
      var graph = new AmCharts.AmGraph();
      graph.valueField = "minValue";
      graph.showBalloon = false;
      graph.type = "column";
      graph.lineAlpha = 0;
      graph.fillAlphas = 0.8;
      graph.fillColors = ["#00FF99", "#0099FF", "#0000FF"];
      graph.gradientOrientation = "horizontal";
      chart.addGraph(graph);
      //       
      //       
      var valueAxis = new AmCharts.ValueAxis();
      valueAxis.tickLength = 4;
      valueAxis.stackType = "regular";
      valueAxis.gridAlpha = 0;
      valueAxis.maximum = maxValue;
      valueAxis.minimum = minValue;
      //        valueAxis.title = this.get('model.unit');
      chart.addValueAxis(valueAxis);
      warnings.forEach((function (warningL) {
        if (warningL.get("isActive")) {
          var guide = new AmCharts.Guide();
          guide.value = warningL.get("level_lte") == null ? warningL.get("level_gte") : warningL.get("level_lte");
          guide.labelRotation = "90";
          guide.label = warningL.get("name");
          guide.lineAlpha = 1;
          guide.lineThickness = 2;
          guide.lineColor = guide.color = warningL.get("color");
          guide.above = true;
          guide.inside = true;
          this.addGuide(guide);
        }
      }).bind(valueAxis));

      return chart;
    },
    waterTemperatureLine: function waterTemperatureLine(minValue, maxValue, warnings, format, mode, object) {
      var rawData = object.get("sendRawData");
      var sampling = parseInt(object.get("sampling"));
      sampling = sampling + "mm";
      var chart = new AmCharts.AmSerialChart();
      chart.language = "pl";
      chart.marginRight = 70;
      chart.autoMarginOffset = 20;
      //chart.dataProvider = data;
      chart.dataDateFormat = "YYYY-MM-DD hh", chart.legend = {
        useGraphSettings: true
      };
      chart.categoryField = "date";
      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/", chart.categoryAxis = {
        parseDates: true,
        //"dashLength": 1,
        minorGridEnabled: true,
        //"minPeriod": sampling
        equalSpacing: true
      };

      chart["export"] = {
        enabled: true,
        menu: [{
          format: "PDF",
          label: "Do PDF",
          title: "Zapisz jako PDF" }]
      };

      var graph = new AmCharts.AmGraph();
      graph.id = "g1";
      graph.valueField = "value";
      //graph.type = "line";
      graph.bullet = "round";
      graph.connect = true;
      graph.bulletBorderAlpha = 1;
      graph.bulletColor = "#FFFFFF";
      graph.bulletSize = 2;
      graph.hideBulletsCount = 100;
      graph.lineThickness = 1;
      graph.title = object.get("name");
      graph.lineColor = mode == "demo" ? "#DCDCDC" : "#1E90FF";
      graph.useLineColorForBulletBorder = true;
      graph.valueField = "value";
      graph.balloonText = "<div style='margin:5px; font-size:19px;'><span style='font-size:18px;'>[[category]]</span><br>[[value]]</div>";
      graph.balloonFunction = function (GraphDataItem, AmGraph) {
        return moment(GraphDataItem.dataContext.date).format("Do MMMM - HH:mm") + " pomiar <b>" + GraphDataItem.dataContext.value + "</b>";
      };
      chart.addGraph(graph);

      var chartCursor = new AmCharts.ChartCursor();
      chartCursor.pan = true;
      chartCursor.valueLineEnabled = true;
      chartCursor.valueLineBalloonEnabled = true;
      chartCursor.cursorAlpha = 1;
      chartCursor.cursorColor = "#258cbb", chartCursor.limitToGraph = "g1", chartCursor.valueLineAlpha = 0.2;
      chartCursor.zoomable = true;
      chartCursor.valueZoomable = true;
      chartCursor.categoryBalloonDateFormat = "DD MMMM YYYY";
      chart.addChartCursor(chartCursor);

      var scrollbar = new AmCharts.ChartScrollbar();
      scrollbar.graph = "g1";
      scrollbar.oppositeAxis = false;
      scrollbar.offset = 30;
      scrollbar.scrollbarHeight = 80;
      scrollbar.backgroundAlpha = 0;
      scrollbar.selectedBackgroundAlpha = 0.1;
      scrollbar.selectedBackgroundColor = "#888888";
      scrollbar.graphFillAlpha = 0;
      scrollbar.graphLineAlpha = 0.5;
      scrollbar.selectedGraphFillAlpha = 0;
      scrollbar.selectedGraphLineAlpha = 1;
      scrollbar.autoGridCount = true;
      scrollbar.color = "#AAAAAA";
      chart.addChartScrollbar(scrollbar);
      //        var categoryAxis = chart.categoryAxis;
      //        categoryAxis.labelRotation = 45;

      var valueAxis = new AmCharts.ValueAxis();
      valueAxis.tickLength = 4;
      valueAxis.maximum = maxValue;
      valueAxis.minimum = minValue;
      valueAxis.title = object.get("name") + " " + object.get("unit").get("value");
      chart.addValueAxis(valueAxis);
      warnings.forEach((function (warningL) {
        if (warningL.get("isActive")) {
          warningL.get("level_lte");
          var guide = new AmCharts.Guide();
          guide.value = warningL.get("level_lte") == null ? warningL.get("level_gte") : warningL.get("level_lte");
          guide.label = warningL.get("name");
          guide.lineAlpha = 1;
          guide.lineThickness = 2;
          guide.lineColor = guide.color = warningL.get("color");
          guide.above = true;
          guide.inside = true;
          this.addGuide(guide);
        }
      }).bind(valueAxis));

      return chart;
    },
    waterDropColumn: function waterDropColumn(minValue, maxValue, warnings, format, mode, object) {
      var chart = new AmCharts.AmSerialChart();
      chart.language = "pl";
      var sampling = parseInt(object.get("sampling"));
      sampling = sampling + "mm";
      //chart.dataProvider = data;
      //chart.dataDateFormat = "YYYY-MM-DD H:N",
      chart.categoryField = "date";
      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/";
      chart["export"] = {
        enabled: true,
        menu: [{
          format: "PDF",
          label: "Do PDF",
          title: "Zapisz jako PDF" }]
      }, chart.categoryAxis = {
        parseDates: true,
        dashLength: 1,
        minorGridEnabled: true,
        minPeriod: sampling
      };

      var graph = new AmCharts.AmGraph();
      graph.id = "g1";
      graph.valueField = "value";
      graph.type = "column";
      graph.balloonFunction = function (GraphDataItem, AmGraph) {
        if (format === "hh") {
          return moment(GraphDataItem.dataContext.date).format("LT") + ": <b>" + GraphDataItem.dataContext.value + "</b>";
        } else if (format === "DD") {
          return moment(GraphDataItem.dataContext.date).format("Do MMMM") + ": <b>" + GraphDataItem.dataContext.value + "</b>";
        } else if (format === "MM") {
          return moment(GraphDataItem.dataContext.date).format("MMMM YYYY") + ": <b>" + GraphDataItem.dataContext.value + "</b>";
        }
      };
      //graph.balloonText = "[[category]]: <b>[[value]]</b>";
      graph.fillAlphas = 0.8;
      graph.lineAlpha = 0.2;
      graph.lineColor = mode == "demo" ? "#DCDCDC" : "#1E90FF";
      chart.addGraph(graph);

      var chartCursor = new AmCharts.ChartCursor();
      chartCursor.pan = true;
      chartCursor.valueLineEnabled = true;
      chartCursor.valueLineBalloonEnabled = true;
      chartCursor.cursorAlpha = 1;
      chartCursor.cursorColor = "#258cbb", chartCursor.limitToGraph = "g1", chartCursor.valueLineAlpha = 0.2;

      chart.addChartCursor(chartCursor);

      var valueAxis = new AmCharts.ValueAxis();
      valueAxis.tickLength = 4;
      valueAxis.maximum = maxValue;
      valueAxis.minimum = minValue;
      valueAxis.title = object.get("name") + " " + object.get("unit").get("value");
      chart.addValueAxis(valueAxis);
      warnings.forEach((function (warningL) {
        if (warningL.get("isActive")) {
          var guide = new AmCharts.Guide();
          guide.value = warningL.get("level_gte") != null ? warningL.get("level_gte") : warningL.get("level_lte");
          guide.label = warningL.get("name");
          guide.lineAlpha = 1;
          guide.lineThickness = 2;
          guide.lineColor = guide.color = warningL.get("color");
          guide.above = true;
          guide.inside = true;
          this.addGuide(guide);
        }
      }).bind(valueAxis));

      var scrollbar = new AmCharts.ChartScrollbar();
      scrollbar.graph = "g1";
      scrollbar.oppositeAxis = false;
      scrollbar.offset = 30;
      scrollbar.scrollbarHeight = 80;
      scrollbar.backgroundAlpha = 0;
      scrollbar.selectedBackgroundAlpha = 0.1;
      scrollbar.selectedBackgroundColor = "#888888";
      scrollbar.graphFillAlpha = 0;
      scrollbar.graphLineAlpha = 0.5;
      scrollbar.selectedGraphFillAlpha = 0;
      scrollbar.selectedGraphLineAlpha = 1;
      scrollbar.autoGridCount = true;
      scrollbar.color = "#AAAAAA";
      scrollbar.graphType = "column";
      scrollbar.updateOnReleaseOnly = true;
      chart.addChartScrollbar(scrollbar);

      return chart;
    },
    windSpeedGauge: function windSpeedGauge(minValue, maxValue, value, unit) {
      var oValue = value;
      var maxLabel = maxValue;

      if (minValue < 0) {
        value += minValue;
      }

      var bands = [{
        color: "#1E82EE",
        endValue: maxLabel,
        startValue: minValue
      }];

      if (unit == "°") {
        if (value == 0) {
          value = parseFloat(parseInt(value) + 175);
        } else if (value > 0 && value <= 45) {
          value = parseFloat(parseInt(value) + 210);
        } else if (value > 45 && value <= 90) {
          value = parseFloat(parseInt(value) + 225);
        } else if (value > 90 && value <= 135) {
          value = parseFloat(parseInt(value) + 240);
        } else if (value > 135 && value <= 180) {
          value = parseFloat(parseInt(value) + 270);
        } else if (value > 180 && value <= 225) {
          value = parseFloat(parseInt(value) + 300);
        } else if (value > 225 && value <= 270) {
          value = parseFloat(parseInt(value) + 305);
        } else if (value > 270 && value <= 305) {
          value = parseFloat(parseInt(value) + 340);
        } else if (value > 305 && value <= 360) {
          value = parseFloat(parseInt(value) + 355);
        }
        minValue = 0;
        maxValue = 360;
        maxLabel = 360;
        var bands = [{
          color: "#1E82EE",
          endValue: maxLabel,
          startValue: minValue
        }, {
          color: "#1E82EE",
          endValue: 540,
          startValue: 360
        }];
      }

      var chart = new AmCharts.AmAngularGauge();
      chart.language = "pl";
      chart.type = "gauge";
      chart.pathToImages = "http://cdn.amcharts.com/lib/3/images/";
      chart.marginBottom = 10;
      chart.marginTop = 0;
      chart.marginLeft = 0;
      chart.marginRight = 0;

      chart.axes = [{
        axisThickness: 0,
        axisAlpha: 0,
        tickAlpha: 0,
        //"valueInterval": maxValue,
        bands: bands,
        bottomText: oValue + " " + unit,
        bottomTextYOffset: 20,
        fontSize: 0,
        endValue: maxValue,
        startValue: minValue
      }];
      chart.arrows = [{ value: value,
        color: "#00000" }];

      return chart;
    } });

  //sampling = sampling < 10 ? 10 : sampling;

});