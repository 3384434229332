define('admin/router', ['exports', 'ember', 'admin/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route("contact");
    this.route("dashboard", { path: "/" });
    this.resource("account", { path: "/account" }, function () {
      this.route("login");
      this.route("register");
      this.route("logout");
      this.route("password");
      this.route("edit");
      this.route("history");
    });
    this.resource("technika", { path: "/technika" }, function () {
      this.route("station", { path: "/station" }, function () {
        this.route("index");
        this.route("export");
        this.route("files");
        this.route("service");
        this.route("map");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("details", { path: "/details/:object_id" });
        this.route("new");
      });
      this.route("sensor", { path: "/sensor" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("details", { path: "/details/:object_id" });
        this.route("new");
        this.route("stats");
      });
      this.route("contact", { path: "/contact" }, function () {
        this.route("index");
      });
      this.route("stats", { path: "/stats" }, function () {
        this.route("chart");
      });
      this.route("import", { path: "/import" }, function () {
        this.route("index");
      });
      this.route("raport", { path: "/raport" }, function () {
        this.route("new");
        this.route("index", { path: "/:type" });
        this.route("create", { path: "/create/:type" });
        this.route("aggregate");
        this.route("single");
      });
    });
    this.resource("shop", { path: "/shop" }, function () {
      this.route("cashbox", { path: "/cashbox" }, function () {
        this.route("index");
        this.route("products");
        this.route("repertoire");
        this.route("orders");
        this.route("boxoffice");
      });
      this.route("order", { path: "/order" }, function () {
        this.route("index");
        this.route("details", { path: "/details/:object_id" });
      });
      this.route("product", { path: "/product" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("delivery", { path: "/delivery" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("payment", { path: "/payment" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("salegroup", { path: "/salegroup" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
    });
    this.resource("depot", { path: "/depot" }, function () {
      this.route("storehouse", { path: "/storehouse" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("item", { path: "/item" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
    });
    this.resource("admin", { path: "/admin" }, function () {
      this.route("user", { path: "/user" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("company", { path: "/company" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("group", { path: "/group" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("role", { path: "/role" }, function () {
        this.route("index");
        this.route("edit");
        this.route("new");
      });
      this.route("dictionary", { path: "/dictionary" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("tax", { path: "/tax" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
        this.route("new");
      });
      this.route("task", { path: "/task" }, function () {
        this.route("index");
        this.route("edit", { path: "/edit/:object_id" });
      });
      this.route("systeminfo", { path: "/systeminfo" }, function () {
        this.route("index");
        this.route("break");
        this.route("mysql");
      });
    });
    this.route("list");
    this.route("edit");
    this.route("new");

    this.route("station", function () {
      this.route("files");
    });
  });

  exports['default'] = Router;

});