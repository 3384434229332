define('admin/services/system-information', ['exports', 'ember', 'jquery', 'admin/mixins/serializable'], function (exports, Ember, jQuery, Serializable) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    host: null,
    isLoading: false,
    init: function init(host) {
      this.set("host", host);
    },
    locale: null,
    version: null,
    configuration: null,
    load: function load() {
      this.set("isLoading", true);
      return Ember['default'].$.getJSON(this.get("host") + "/system/initialization.json").then((function (data) {
        this.setProperties(data);
        this.set("isLoading", false);
      }).bind(this));
    }
  });

});