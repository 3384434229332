define('admin/routes/technika/station/edit', ['exports', 'admin/routes/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    config: {
      into: "technika/station/edit",
      controller: "technika/station/edit" } });

  //    renderTemplate: function() {
  //      this._super();
  ////      var controller = this.controllerFor('admin/company/list');
  //////      controller.load();
  ////      this.render('admin/company/list', {
  ////        into: 'technika/station/edit',
  ////        outlet: "stationcompany",
  ////        controller: controller
  ////      });
  //     
  //    }

});