define('admin/components/field-checkbox', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    label: null,
    name: null,
    model: null,
    value: 1,
    slider: false,
    translate: true,
    onChangeFunction: null,
    assignChecked: null, // do obslugi list multiselect
    didRender: (function () {
      this.onModel();
      this.$(".ui.checkbox").data("modelId", this.get("model.id"));
      this.$(".ui.checkbox").checkbox({
        onChange: (function (a, b, c, d) {
          var checked = this.$(".ui.checkbox").checkbox("is checked");
          if (this.get("assignChecked") === null && this.get("model") && this.get("model").get(this.get("name")) !== undefined && this.get("onChangeFunction") === null) {
            this.get("model").set(this.get("name"), checked);
          } else if (this.get("onChangeFunction")) {
            this.get("model")[this.get("onChangeFunction")](this.get("name"), checked);
          } else if (this.get("assignChecked") !== null) {
            this.set("checked", checked);
          }
        }).bind(this)
      });
    }).observes("model"),
    isChecked: (function () {
      this.$("input").prop("checked", this.get("checked"));
      if (this.get("assignChecked") != null) {
        if (this.get("checked")) {
          this.get("assignChecked").pushObject(this.get("model") ? this.get("model") : this.get("value"));
        } else {
          this.get("assignChecked").removeObject(this.get("model") ? this.get("model") : this.get("value"));
        }
      }
    }).observes("checked"),
    onModel: (function () {
      if (this.get("assignChecked") === null && this.get("model") && this.get("name") in this.get("model")) {
        this.set("checked", this.get("model." + this.get("name")));
        this.get("model").addObserver(this.get("name"), (function () {
          if (this.get("model." + this.get("name")) !== null) {
            this.set("checked", this.get("model." + this.get("name")));
          }
        }).bind(this));
      }
    }).observes("model"),
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("name"));
      }
      this.$(".ui.checkbox").remove();
    },
    checked: false
  });

});