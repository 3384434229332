define('admin/components/field-lonlat', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ["two", "flex", "fields"],
    model: null,
    latField: null,
    lonField: null,
    mapa: null,
    lon: null,
    lat: null,
    mapController: null,
    mapModalButtons: [{
      title: "field.lonlat.selectpoint",
      action: "selectPoint"
    }, {
      title: "app.cancel",
      action: "close"
    }],
    init: function init() {
      this._super();
      this.get("openlayers").load();
    },
    onModel: (function () {
      if (this.get("model")) {
        this.set("lon", parseFloat(this.get("model").get(this.get("lonField"))));
        this.set("lat", parseFloat(this.get("model").get(this.get("latField"))));
        this.get("model").addObserver(this.get("lonField"), (function () {
          this.set("lon", parseFloat(this.get("model").get(this.get("lonField"))));
        }).bind(this));
        this.get("model").addObserver(this.get("latField"), (function () {
          this.set("lat", parseFloat(this.get("model").get(this.get("latField"))));
        }).bind(this));
      }
    }).observes("model"),
    didInsertElement: function didInsertElement() {
      if (this.get("openlayers.isLoaded")) {
        this.onOpenLayerLoaded();
      }
    },
    onLonLatChange: (function () {
      if (this.get("mapa")) {

        var lonlat = ol.proj.transform([this.get("lon"), this.get("lat")], "EPSG:4326", "EPSG:3857");
        var size = this.get("mapa").getSize();
        this.get("mapa").getView().centerOn(lonlat, size, [size[0] / 2, size[1] / 2]);
        this.get("mapa").getView().setZoom(14);
        this.get("mapa").getLayers().item(1).getSource().getFeatureById(1).getGeometry().setCoordinates(lonlat);
      }
    }).observes("lon", "lat"),
    onOpenLayerLoaded: (function () {
      if (this._state === "inDOM" && this.get("openlayers.isLoaded") && !this.get("mapa")) {

        var startLonLat = [2068940.1538519368, 6492121.669630231];
        if (this.get("lon") && this.get("lat")) {
          startLonLat = ol.proj.transform([this.get("lon"), this.get("lat")], "EPSG:4326", "EPSG:3857");
        }

        var layer1 = new ol.layer.Tile({
          name: "OpenStreetMap (landscape)",
          source: new ol.source.OSM({
            attributions: [ol.source.OSM.ATTRIBUTION],
            crossOrigin: null,
            url: "http://{a-b}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          })
        });
        var style = new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 0.11],
            anchorOrigin: "bottom-left",
            scale: 0.3,
            opacity: 0.9,
            src: "assets/images/marker.png"
          })
        });
        var pointFeature = new ol.Feature(new ol.geom.Point(startLonLat));
        pointFeature.setId(1);
        var layer2 = new ol.layer.Vector({
          source: new ol.source.Vector({
            features: [pointFeature]
          }),
          style: style
        });
        this.set("mapa", new ol.Map({
          target: this.$(".map")[0],
          layers: [layer1, layer2],
          view: new ol.View({
            maxZoom: 18,
            center: startLonLat,
            zoom: 14 })
        }));
      }
    }).observes("openlayers.isLoaded"),
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("lonField"));
        this.get("model").removeObserver(this.get("latField"));
      }
      this.set("mapa", null);
      this.$().remove();
    },
    mapModalVisible: false,
    selectedLonLat: null,
    bindClick: (function () {
      if (this.get("mapController") && this.get("mapController").get("mapa")) {
        this.get("mapController").get("mapa").on("click", (function (evt) {
          var lonlat = ol.proj.transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
          this.set("selectedLonLat", lonlat);
          evt.map.getLayers().item(1).getSource().clear();
          var pointFeature = new ol.Feature(new ol.geom.Point(evt.coordinate));
          evt.map.getLayers().item(1).getSource().addFeature(pointFeature);
        }).bind(this));
        var style = new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 0.11],
            anchorOrigin: "bottom-left",
            scale: 0.3,
            opacity: 0.9,
            src: "assets/images/marker.png"
          })
        });
        var layer2 = new ol.layer.Vector({
          source: new ol.source.Vector({
            features: []
          }),
          style: style
        });
        this.get("mapController").get("mapa").addLayer(layer2);
      }
    }).observes("mapController.mapa"),
    actions: {
      showMapModal: function showMapModal() {
        this.set("mapModalVisible", true);
      },
      selectPoint: function selectPoint() {
        this.get("model").set(this.get("lonField"), this.get("selectedLonLat")[0]);
        this.get("model").set(this.get("latField"), this.get("selectedLonLat")[1]);
        this.set("mapModalVisible", false);
      },
      close: function close() {
        this.set("mapModalVisible", false);
      }
    }
  });

  //          projection: 'EPSG:4326'
  //          projection: 'EPSG:3857'

});