define('admin/controllers/technika/raport/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "technika/sensor",
    multiselect: true,
    selectedSensors: [],
    checkedSensors: [],
    raportBtnPr: "disabled",
    raportWarning: true,
    dateTo: null,
    dateFrom: null,
    type: null,
    init: function init() {
      this._super();
      this.get("filters").addObject(FilterElement['default'].create({
        name: "term",
        label: "app.filter.term.sensor"
      }));
    },
    isDaily: (function () {
      var type = this.get("type").split("-");
      this.set("dateFrom", moment().subtract(1, "days").format("DD.MM.YYYY"));
      return type[0] === "day";
    }).property("type"),
    isMonthly: (function () {
      var type = this.get("type").split("-");
      return type[0] === "month";
    }).property("type"),
    isYearly: (function () {
      var type = this.get("type").split("-");
      return type[0] === "year";
    }).property("type"),
    isAgregate: (function () {
      var type = this.get("type").split("-");
      return type[1] === "av";
    }).property("type"),
    isNew: (function () {
      var type = this.get("type");
      return type === "new";
    }).property("type"),

    raportBtn: (function () {
      if (this.get("selectedSensors").length > 0) {
        this.set("raportBtnPr", "");
        this.set("raportWarning", false);
      } else {
        this.set("raportBtnPr", "disabled");
        this.set("raportWarning", true);
      }
    }).observes("selectedSensors.[]"),

    actions: {
      addSensor: function addSensor(sensor) {
        var toAdd = this.get("selectedSensors").findBy("id", sensor.get("id"));
        if (Ember.isEmpty(toAdd)) {
          this.get("selectedSensors").pushObject(sensor);
        }
      },
      removeSensor: function removeSensor(sensor) {
        var toRemove = this.get("selectedSensors").findBy("id", sensor.get("id"));
        this.get("selectedSensors").removeObject(toRemove);
      },
      showStats: function showStats() {
        var selectedSensors = [];
        this.get("selectedSensors").forEach(function (item) {
          selectedSensors.push(item.get("id"));
        });

        if (selectedSensors.get("length") > 6 && this.get("isAgregate")) {
          noty({
            layout: "topCenter",
            theme: "relax",
            text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Formularz kontaktowy</div></h5> Możesz wybrać maksymalnie  6 czujników",
            type: "error",
            timeout: 5000
          });
          return false;
        }
        var dateFrom = null;
        var dateTo = null;
        var type = null;
        if (this.get("isDaily")) {
          dateFrom = jQuery(".dateFrom").val();
          dateTo = jQuery(".dateFrom").val();
          type = "daily";
        } else if (this.get("isMonthly")) {
          var monthFrom = jQuery("#monthFrom").val();
          var monthTo = jQuery("#monthTo").val();
          var yearFrom = jQuery("#yearFrom").val();
          var yearTo = jQuery("#yearTo").val();
          dateFrom = yearFrom + "-" + monthFrom + "-01";
          dateTo = yearTo + "-" + monthTo + "-31";
          type = "monthly";
        } else if (this.get("isYearly")) {
          var yearFrom = jQuery("#yearFrom").val();
          var yearTo = jQuery("#yearTo").val();
          dateFrom = yearFrom + "-01-01";
          dateTo = yearTo + "-12-31";
          type = "yearly";
        }

        if (selectedSensors.length == 0) {
          noty({
            layout: "topCenter",
            theme: "relax",
            text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Raporty</div></h5> Wybierz przynajmniej jeden czujnik",
            type: "error",
            timeout: 5000
          });
        } else {
          if (this.get("isAgregate") && selectedSensors.length > 1) {
            window.open(this.get("application").get("host") + "/#/technika/raport/aggregate?dateFrom=" + dateFrom + "&dateTo=" + dateTo + "&stations=" + selectedSensors + "&type=" + type);
          } else {
            window.open(this.get("application").get("host") + "/#/technika/raport/single?dateFrom=" + dateFrom + "&dateTo=" + dateTo + "&stations=" + selectedSensors + "&type=" + type);
          }
        }
      } }
  });

});