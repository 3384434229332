define('admin/services/auth-manager', ['exports', 'ember', 'jquery', 'admin/mixins/serializable'], function (exports, Ember, jQuery, Serializable) {

        'use strict';

        exports['default'] = Ember['default'].Service.extend({
                application: null,
                user: null,
                isLoading: false,
                isLoaded: false,
                loadSession: function loadSession() {
                        this.set("isLoading", true);
                        return Ember['default'].$.getJSON(this.get("application").get("server") + "/account.json").then((function (data) {
                                this.updateSessionInformation(data);
                                this.set("isLoading", false);
                                this.set("isLoaded", true);
                        }).bind(this));
                },
                updateSessionInformation: function updateSessionInformation(session) {
                        if (session.account === null) {
                                session.account = {
                                        roles: []
                                };
                        }
                        this.set("session", session);
                        var account = Ember['default'].Object.extend(Serializable['default'], {
                                application: this,
                                rollbackAttributes: function rollbackAttributes() {
                                        this.setProperties(this.get("application.session.account"));
                                } });
                        this.set("user", account.create(session.account));
                },
                companyLogo: (function () {
                        return this.get("application").get("static") + this.get("user.company_logo");
                }).property("user.companyLogo"),
                footerText: (function () {
                        return this.get("user.footerText");
                }).property("user.footerText"),
                headerText: (function () {
                        return this.get("user.headerText");
                }).property("user.headerText"),
                hasCompanyLogo: (function () {
                        return this.get("user.company_logo") == null ? false : true;
                }).property("user.companyLogo"),
                isAuthenticated: (function () {
                        return this.get("session.isAuthenticated");
                }).property("session"),
                login: function login(user, password) {
                        return jQuery['default'].post(this.get("application").get("server") + "/account/login.json", {
                                password: password,
                                email: user
                        }, "json").then((function () {
                                return this.loadSession();
                        }).bind(this));
                },
                logout: function logout() {
                        return jQuery['default'].get(this.get("application").get("server") + "/account/logout.json").then((function () {
                                this.loadSession();
                        }).bind(this));
                },
                session: null,
                // Load the current user if the cookies exist and is valid
                init: function init() {
                        this._super();
                        this.authenticate();
                },
                // Determine if the user is currently authenticated.

                // Authenticate the user. Once they are authenticated, set the access token to be submitted with all
                // future AJAX requests to the server.
                authenticate: function authenticate() {},
                // Log out the user
                reset: function reset() {
                        this.set("session", null);
                        this.get("application").__container__.lookup("route:application").transitionTo("index");
                },
                //                user: function() {
                //                  return this.get('session.account');
                //                }.property('session'),
                ROLE_USER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_USER");
                }).property("user"),
                ROLE_CASHIER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_CASHIER");
                }).property("user"),
                ROLE_MAIN_CASHIER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_MAIN_CASHIER");
                }).property("user"),
                ROLE_PRODUCT_MANAGER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_PRODUCT_MANAGER");
                }).property("user"),
                ROLE_DELIVERY_MANAGER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_DELIVERY_MANAGER");
                }).property("user"),
                ROLE_PAYMENT_MANAGER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_PAYMENT_MANAGER");
                }).property("user"),
                ROLE_SHOP_MANAGER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_SHOP_MANAGER");
                }).property("user"),
                ROLE_MY_ORDERS: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_MY_ORDERS");
                }).property("user"),
                ROLE_ONLINE_ORDERS: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_ONLINE_ORDERS");
                }).property("user"),
                ROLE_ORDERS: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_ORDERS");
                }).property("user"),
                ROLE_OWNER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_OWNER");
                }).property("user"),
                ROLE_USER_MANAGER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_USER_MANAGER");
                }).property("user"),
                ROLE_ADMIN: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_ADMIN");
                }).property("user"),
                ROLE_SUPER_ADMIN: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_SUPER_ADMIN");
                }).property("user"),
                ROLE_TECHNIKA_USER: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_TECHNIKA_USER");
                }).property("user"),
                ROLE_TECHNIKA_ADMIN: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_TECHNIKA_ADMIN");
                }).property("user"),
                ROLE_TECHNIKA_STATION: (function () {
                        return this.get("user") !== null && this.get("user.roles").contains("ROLE_TECHNIKA_STATION");
                }).property("user") });

        //Ember.$.getJSON(SERVER_HOST + "/account.json").then(function(data) {
        // console.debug(store.createRecord('User', data.user));
        //      var user = App.User.createRecord(data.user);
        //      data.user = user;
        //                var session = data;
        //                        App.set('culture', data.culture);
        //                        this.set('session', session);
        //                }.bind(this));
        //   
        //    $.ajaxSetup({
        //      headers: { 'Authorization': 'Bearer ' + accessToken }
        //    });
        //    //var user = User.find(userId);

        // Ensure that when the apiKey changes, we store the data in cookies in order for us to load
        // the user when the browser is refreshed.
        //  apiKeyObserver: function() {
        //    if (Ember.isEmpty(this.get('session'))) {
        //      $.removeCookie('access_token');
        //      $.removeCookie('auth_user');
        //    } else {
        //      $.cookie('access_token', this.get('session.id'));
        //      $.cookie('auth_user', this.get('session.user.id'));
        //    }
        //  }.observes('session')

});