define('admin/models/technika/station/maintenance', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    date: DS['default'].attr("string"),
    description: DS['default'].attr("string"),
    station: DS['default'].attr("number"), //DS.belongsTo('technika/station', { async: false }),
    user: DS['default'].belongsTo("user", { async: false }),
    isInternal: DS['default'].attr("boolean") });

});