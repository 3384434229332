define('admin/controllers/account/register', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    url: (function () {
      return this.get("target").generate("account.login");
    }).property("target"),
    register: function register(data) {
      this.set("isLoading", true);
      var url = this.get("application.server") + "/account/register.json";
      jQuery['default'].ajax(url, {
        method: "POST",
        data: data
      }).then((function () {
        this.get("application").message("app.register.success.title", "app.register.success");
        this.get("target").transitionTo("account.login");
        this.set("isLoading", false);
      }).bind(this), (function (response) {
        if (response.responseJSON) {
          this.set("formErrors", response.responseJSON.fields);
          this.get("application").error("app.register.failure", response.responseJSON.message);
        }
        this.set("isLoading", false);
      }).bind(this));
    }
  });

});