define('admin/controllers/admin/task/list', ['exports', 'admin/controllers/list', 'jquery', 'admin/utils/filter-element'], function (exports, List, jQuery, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "task",
    init: function init() {
      this._super();
      this.get("filters").addObject(FilterElement['default'].create({
        name: "term",
        label: "app.filter.term"
      }));
      this.get("navigation").pushObject({
        route: "admin.task.index",
        text: "admin.task.navigation.list"
      });
    },
    offset: (function () {
      return moment(this.get("meta.date")).diff(new Date());
    }).property("meta.date"),
    actions: {
      run: function run(p1, task) {
        var url = task.get("store").adapterFor(this.get("modelName")).buildURL(this.get("modelName"), task.get("id"), task, null, null, "run");
        this.set("isLoading", true);
        jQuery['default'].ajax({
          url: url,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          error: (function (response) {
            this.set("isLoading", false);
            if (response.responseJSON) {
              //(response.responseJSON);
              this.set("formErrors", response.responseJSON.fields);
              this.get("application").error("app.error.form.save", response.responseJSON.message);
            }
          }).bind(this),
          success: (function (data) {
            //data, status, response
            task.setProperties(data.task);
            this.set("isLoading", false);
          }).bind(this)
        });
      }
    }
  });

});