define('admin/tests/unit/utils/navigation/item-test', ['admin/tests/utils/navigation/item', 'qunit'], function (navigationItem, qunit) {

  'use strict';

  qunit.module("navigationItem");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = navigationItem['default']();
    assert.ok(result);
  });

});