define('admin/components/ui-floatcharts', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    isLoading: false,
    warnings: [],
    data: [],
    type: null,
    raport: false,
    isDaily: true,
    maxTime: null,
    minTime: null,
    sensorId: null,
    collors: ["#1E90FF", "#0000FF", "#FFA500", "#008000", "#FF0000", "#800000"],
    collors_max: ["#FF0000", "#0000FF", "#FFA500", "#008000", "#FF0000", "#800000"],
    collors_min: ["#008000", "#0000FF", "#FFA500", "#008000", "#FF0000", "#800000"],

    _loadHourlyData: function _loadHourlyData(isLoading, sensorId, maxTime, minTime) {
      if (this.get("maxTime") == maxTime && this.get("minTime") == minTime) {
        return false;
      }
      this.set("minTime", minTime);
      this.set("maxTime", maxTime);
      maxTime = new Date(maxTime);
      minTime = new Date(minTime);
      return jQuery.ajax({
        url: Admin.get("server") + "/technika/sensor/data/selection.json",
        data: {
          sensorId: window.location.hash,
          from: moment(minTime).format(),
          to: moment(maxTime).format() }
      });
    },

    _setTooltip: function _setTooltip() {
      $("<div id='tooltip'></div>").css({
        position: "absolute",
        display: "none",
        padding: "2px",
        "background-color": "#ffffff",
        width: "190px",
        opacity: 0.8
      }).appendTo("body");
    },

    _getTimeFormat: function _getTimeFormat() {
      var data = this.get("data");
      var format = undefined;
      data.forEach(function (d) {
        if (d.data.length > 0) {
          var period = d.data[d.data.length - 1][0] - d.data[0][0];
          if (period <= 6000000) {
            format = "%d.%m.%y %H:%M";
          } else if (period <= 144300000) {
            format = "%d.%m.%y %H:00";
          } else {
            format = "%d.%m.%y";
          }
        }
      });
      return format;
    },

    _getTimeTick: function _getTimeTick() {
      var data = this.get("data");
      var format = undefined;
      data.forEach(function (d) {
        if (d.data.length > 0) {
          var period = d.data[d.data.length - 1][0] - d.data[0][0];
          console.log(period);
          if (period <= 6000000) {
            format = [15, "minute"];
          } else if (period <= 60000000) {
            format = [1, "hour"];
          } else if (period <= 144300000) {
            format = [4, "hour"];
          } else if (period > 144300000 && period <= 1035300000) {
            format = [1, "day"];
          } else if (period > 1035300000 && period <= 5324400000) {
            format = [5, "day"];
          } else if (period > 5324400000 && period <= 15324400000) {
            format = [10, "day"];
          } else if (period > 15324400000 && period <= 26320560000) {
            format = [30, "day"];
          } else {
            format = [60, "day"];
          }
        }
      });
      return format;
    },

    _getZoomTimeFormat: function _getZoomTimeFormat(from, to) {
      var format = to - from <= 144300000 ? "%d.%m.%y %H:%M" : "%d.%m.%y";
      return format;
    },

    _getLegend: function _getLegend() {
      var data = this.get("data");
      if (!Em.isEmpty(data)) {
        var legends = $(".legendLabel");
        legends.each(function (index) {
          if (typeof data[index] !== "undefined") {
            if (data[index].data == 0) {
              return;
            }
            var d = data[index].data;
            var unit = data[index].unit;
            $(this).css("width", 150);
            $(this).html("&nbsp;" + moment(d[0][0]).format("DD.MM.YYYY H:mm") + "&nbsp;<b>" + d[0][1] + "&nbsp;" + unit + "</b>");
          }
        });
      }
    },

    _getWarnings: function _getWarnings() {
      var markings = [];
      var warnings = this.get("warnings");
      warnings.forEach(function (item) {
        if (item.get("isActive")) {
          if (!Em.isEmpty(item.get("level_gte"))) {
            markings.push({
              yaxis: {
                from: item.get("level_gte"),
                to: item.get("level_gte")
              },
              color: item.get("color"),
              text: item.get("name")
            });
          }
          if (!Em.isEmpty(item.get("level_lte"))) {
            markings.push({
              yaxis: {
                from: item.get("level_lte"),
                to: item.get("level_lte")
              },
              color: item.get("color"),
              text: item.get("name")
            });
          }
        }
      });
      return markings;
    },

    _getWarningLabels: function _getWarningLabels(ploter, markings, placeholder) {
      var data = this.get("data");
      var d = data[0].data;
      if (d.length > 0) {
        markings.forEach(function (mark) {
          var o = ploter.pointOffset({
            x: d[0][0],
            y: mark.yaxis.from
          });
          if (o.top > 0) {
            placeholder.append("<div style='color: " + mark.color + ";position:absolute;left:" + o.left + "px;top:" + o.top + "px;font-size:smaller'>" + mark.text + "</div>");
          }
        });
      }
    },

    _getMax: function _getMax(unit) {
      var self = this;
      var yaxis = self.get("yaxis");
      for (var property in yaxis) {
        if (yaxis.hasOwnProperty(property)) {
          //(property, unit)
          if (property == unit) {
            return yaxis[property].max;
          }
        }
      }
    },

    _getMin: function _getMin(unit) {
      var self = this;
      var yaxis = self.get("yaxis");
      for (var property in yaxis) {
        if (yaxis.hasOwnProperty(property)) {
          //(property, unit)
          if (property == unit) {
            return yaxis[property].min;
          }
        }
      }
    },

    _getBarWidth: function _getBarWidth(data) {
      if (data.length > 0) {
        return Math.abs(data[0][0] - data[1][0]);
      }
    },

    _getYAxes: function _getYAxes() {
      var self = this;
      var yaxes = [];
      var data = this.get("data");
      var collors = this.get("collors");
      data.forEach(function (d, index) {
        var decimal = d.decimal == 0 ? 2 : d.decimal;
        var ax = {
          min: self._getMin(d.unit),
          max: self._getMax(d.unit),
          tickDecimals: decimal,
          //ticks: jQuery.unique(ticks),
          tickFormatter: function tickFormatter(v) {
            return "<div style=\"color: " + collors[index] + "\">" + v.toFixed(decimal) + " " + d.unit + "</div>";
          }
        };
        yaxes.push(ax);
      });
      return yaxes;
    },

    _getExternalLegend: function _getExternalLegend() {
      var self = this;
      this.$("#chartLegend").html("");
      var data = this.get("data");
      var isDaily = this.get("isDaily");
      var collors = this.get("collors");
      var collors_max = this.get("collors_max");
      var collors_min = this.get("collors_min");
      data.forEach((function (d, index) {
        if (!Ember['default'].isEmpty(d.max) && d.max.length > 0) {
          this.$("#chartLegend").append("<div style=\"float:left; margin: 2px; background-color: " + collors_max[index] + "; width: 15px; height: 15px\"></span></div><div style=\"float:left\">" + d.station + ",  " + d.name + "</div><span id=\"" + collors_max[index].replace("#", "") + "\"></span>&nbsp;(max)<div style=\"clear:both\"></div>");
        }
        if (!Ember['default'].isEmpty(d.max) && d.max.length > 0 && (!Ember['default'].isEmpty(d.min) && d.min.length > 0)) {
          this.$("#chartLegend").append("<div style=\"float:left; margin: 2px; background-color: " + collors[index] + "; width: 15px; height: 15px\"></span></div><div style=\"float:left\">" + d.station + ",  " + d.name + "</div><span id=\"" + collors[index].replace("#", "") + "\"></span>&nbsp;(avg)<div style=\"clear:both\"></div>");
        } else if (!isDaily) {
          this.$("#chartLegend").append("<div style=\"float:left; margin: 2px; background-color: " + collors[index] + "; width: 15px; height: 15px\"></span></div><div style=\"float:left\">" + d.station + ",  " + d.name + "</div><span id=\"" + collors[index].replace("#", "") + "\"></span>&nbsp;(avg)<div style=\"clear:both\"></div>");
        } else {
          this.$("#chartLegend").append("<div style=\"float:left; margin: 2px; background-color: " + collors[index] + "; width: 15px; height: 15px\"></span></div><div style=\"float:left\">" + d.station + ",  " + d.name + "</div><span id=\"" + collors[index].replace("#", "") + "\"></span>&nbsp;<div style=\"clear:both\"></div>");
        }

        if (!Ember['default'].isEmpty(d.min) && d.min.length > 0) {
          this.$("#chartLegend").append("<div style=\"float:left; margin: 2px; background-color: " + collors_min[index] + "; width: 15px; height: 15px\"></span></div><div style=\"float:left\">" + d.station + ",  " + d.name + "</div><span id=\"" + collors_min[index].replace("#", "") + "\"></span>&nbsp;(min)<div style=\"clear:both\"></div>");
        }
      }).bind(this));
    },

    _getSeries: function _getSeries() {
      var self = this;
      var plotSeries = [];
      var collors = this.get("collors");
      var collors_max = this.get("collors_max");
      var collors_min = this.get("collors_min");
      var settings = {};
      //let data = [{type: 'flot-line', unit: 'Q', data: this.get('data')}];
      var data = this.get("data");
      var type = this.get("type");
      data.forEach(function (d, index) {
        if (d.type === "flot-line" || d.type === "water-level" || d.type === "water-temperature") {
          settings = {
            color: collors[index],
            data: d.data,
            unit: d.unit,
            decimal: d.decimal == 0 ? 2 : d.decimal,
            label: !self.get("legend") ? d.unit : false,
            shadowSize: 0,
            lines: {
              show: true,
              lineWidth: d.data.length > 2500 ? 2 : 1 },
            points: {
              show: true,
              radius: 0.8,
              lineWidth: 1
            },
            yaxis: index + 1
          };
        } else if (d.type === "flot-bar" || d.type === "water-drop") {
          settings = {
            color: collors[index],
            data: d.data,
            unit: d.unit,
            decimal: d.decimal == 0 ? 2 : d.decimal,
            label: !self.get("legend") ? d.unit : false,
            shadowSize: 0,
            bars: {
              show: true,
              barWidth: self._getBarWidth(d.data) },
            points: {
              show: true,
              radius: 1,
              lineWidth: d.data.length > 2500 ? 2 : 1
            },
            yaxis: index + 1
          };
        }
        plotSeries.push(settings);
      });
      data.forEach(function (d, index) {
        if (d.type === "flot-line" || d.type === "water-level" || d.type === "water-temperature") {
          settings = {
            color: collors_max[index],
            data: d.max,
            unit: d.unit,
            label: !self.get("legend") ? d.unit : false,
            shadowSize: 0,
            lines: {
              show: true,
              lineWidth: 1 },
            points: {
              show: true,
              radius: 0.8,
              lineWidth: 1
            },
            yaxis: index + 1
          };
        } else if (d.type === "flot-bar" || d.type === "water-drop") {
          settings = {
            color: collors_max[index],
            data: d.max,
            unit: d.unit,
            label: !self.get("legend") ? d.unit : false,
            shadowSize: 0,
            bars: {
              show: true,
              barWidth: self._getBarWidth(d.data) },
            points: {
              show: true,
              radius: 1,
              lineWidth: 1.5
            },
            yaxis: index + 1
          };
        }
        plotSeries.push(settings);
      });
      data.forEach(function (d, index) {
        if (d.type === "flot-line" || d.type === "water-level" || d.type === "water-temperature") {
          settings = {
            color: collors_min[index],
            data: d.min,
            unit: d.unit,
            label: !self.get("legend") ? d.unit : false,
            shadowSize: 0,
            lines: {
              show: true,
              lineWidth: 1 },
            points: {
              show: true,
              radius: 0.8,
              lineWidth: 1
            },
            yaxis: index + 1
          };
        } else if (d.type === "flot-bar" || d.type === "water-drop") {
          settings = {
            color: collors_min[index],
            data: d.max,
            unit: d.unit,
            label: !self.get("legend") ? d.unit : false,
            shadowSize: 0,
            bars: {
              show: true,
              barWidth: self._getBarWidth(d.data),
              align: "left" },
            points: {
              show: true,
              radius: 1,
              lineWidth: 1.5
            },
            yaxis: index + 1
          };
        }
        plotSeries.push(settings);
      });
      return plotSeries;
    },

    willDestroyElement: function willDestroyElement() {
      $("#tooltip").hide();
      $("body").unbind("mousemove");
      this.set("data", []);
    },

    didRender: function didRender() {
      var sensor = this.get("sensor");
      var data = this.get("data");
      var type = this.get("type");
      $("body").bind("mousemove", function (e) {
        $("#tooltip").hide();
      });
      this.$("#chart").bind("mousemove", function (e) {
        e.stopPropagation();
      });
      Ember['default'].run("afterRender", (function () {
        //timezoneJS.timezone.zoneFileBasePath = "tz";
        //timezoneJS.timezone.defaultZoneFile = 'europe';
        //timezoneJS.timezone.init();
        var self = this;
        var markings = this._getWarnings();
        var plotSeries = this._getSeries();
        var placeholder = this.$("#chart");

        if (!Em.isEmpty(data)) {
          if (this.get("raport")) {
            var ploter = $.plot(placeholder, plotSeries, {
              xaxes: [{
                mode: "time",
                timeformat: self._getTimeFormat(),
                timezone: "browser",
                tickSize: self._getTimeTick() }],
              yaxes: self._getYAxes(),
              grid: {
                //autoHighlight: false,
                borderWidth: 1,
                clickable: true,
                markings: markings
              }
            });
          } else {
            var ploter = $.plot(placeholder, plotSeries, {
              xaxes: [{
                mode: "time",
                timeformat: self._getTimeFormat(),
                timezone: "browser",
                tickSize: self._getTimeTick() }],
              yaxes: self._getYAxes(),
              crosshair: {
                mode: "x",
                color: "#0076e3"
              },
              grid: {
                hoverable: true,
                //autoHighlight: false,
                borderWidth: 1,
                clickable: true,
                markings: markings
              },
              selection: {
                mode: "x"
              }
            });
          }
          this._getWarningLabels(ploter, markings, placeholder);
          //this.set('isLoading', false);

          this._setTooltip();
          if (!this.get("legend")) {
            this._getLegend();
          } else {
            this._getExternalLegend();
          }

          this.set("data", []);
        }

        this.$("#chart").resize((function () {
          if (!this.get("legend")) {
            this._getLegend();
          }
        }).bind(this));

        if (!Em.isEmpty(data)) {
          var plotOptions = ploter.getOptions();
          this.$("#chart").bind("plotselected", (function (event, ranges) {
            var uri_path = window.location.hash;
            if (uri_path.match(/details/g) != null) {
              var ids = [];
              data.forEach(function (d, index) {
                ids.push(d.id);
              });
              var pm = this._loadHourlyData(true, ids.join(","), ranges.xaxis.to, ranges.xaxis.from);
              if (typeof pm.then === "function") {
                pm.then((function (data) {
                  this.set("data", data.floatData);
                  ploter.setupGrid();
                  ploter.draw();
                  plotOptions.xaxes[0].timeformat = this._getZoomTimeFormat(ranges.xaxis.from, ranges.xaxis.to);
                  ploter.clearSelection();
                }).bind(this));
              }
            } else {
              plotOptions.xaxes[0].min = ranges.xaxis.from;
              plotOptions.xaxes[0].max = ranges.xaxis.to;
              //console.log(ranges.xaxis.from,ranges.xaxis.to)
              //ploter.setData([0,0000000]);
              ploter.setupGrid();
              ploter.draw();
              plotOptions.xaxes[0].timeformat = this._getZoomTimeFormat(ranges.xaxis.from, ranges.xaxis.to);
              ploter.clearSelection();
            }
          }).bind(this));
        }

        this.$("#chart").bind("plothover", (function (event, pos, item) {
          var legends = $(".legendLabel");
          if (item !== null && !this.get("raport")) {
            $("#tooltip").html("&nbsp;" + moment.utc(item.datapoint[0]).format("DD.MM.YYYY H:mm") + "&nbsp;<b>" + item.datapoint[1].toFixed(item.series.decimal) + "&nbsp;" + item.series.unit + "</b>");
            $("#tooltip").css({
              border: "1px solid " + item.series.color,
              color: item.series.color,
              left: item.pageX > $(document).width() / 2 ? item.pageX - ($("#tooltip").width() + 20) : item.pageX + 20,
              top: item.pageY - 20
            });
            $("#tooltip").fadeIn(200);
            if (!this.get("legend")) {
              legends.html("&nbsp;" + moment.utc(item.datapoint[0]).format("DD.MM.YYYY H:mm") + "&nbsp;<b>" + item.datapoint[1].toFixed(item.series.decimal) + "&nbsp;" + item.series.unit + "</b>");
            }
            $(item.series.color).html("&nbsp;<b>" + item.datapoint[1] + "&nbsp;" + item.series.unit + "</b>");
          } else {
            $("#tooltip").hide();
          }
        }).bind(this));
      }).bind(this));
    }
  });

});