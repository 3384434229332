define('admin/routes/admin/task/index', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "admin/task/index",
      controller: "admin/task/list",
      hasPager: false,
      hasFilter: false,
      hasNavigation: true
    }
  });

});