define('admin/utils/navigation/item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    displayText: "",
    route: "",
    icon: "",
    roles: [],
    action: null,
    application: null,
    isAvailable: (function () {
      var has_role = false;
      if (this.get("application.authManager.isAuthenticated")) {
        //(this.get('application.authManager.isAuthenticated'), this.get('roles'), this.get('displayText'));
        if (this.get("roles").length > 0) {
          this.get("roles").forEach((function (role) {
            // (this.get('application.authManager.' + role));
            if (this.get("application.authManager." + role)) {
              has_role = true;
            }
          }).bind(this));
        } else {
          has_role = true;
        }
      }
      return has_role;
    }).property("application.authManager"),
    session: (function () {
      return this.get("application.authManager.session");
    }).property("application.authManager.session")
  });

});