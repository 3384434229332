define('admin/serializers/technika/sensor', ['exports', 'admin/serializers/application'], function (exports, ApplicationSerializer) {

  'use strict';

  exports['default'] = ApplicationSerializer['default'].extend({
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      if (relationship && relationship.key === "warnings" && record.hasMany("warnings")) {
        json.warnings = [];
        record.hasMany("warnings").forEach(function (rec) {
          json.warnings.push({
            id: rec.record.get("id"),
            name: rec.record.get("name"),
            level_lte: rec.record.get("level_lte"),
            level_gte: rec.record.get("level_gte"),
            color: rec.record.get("color"),
            pin: rec.record.get("pin"),
            isActive: rec.record.get("isActive"),
            to: rec.record.get("to"),
            message: rec.record.get("message"),
            subject: rec.record.get("subject") });
        });
      } else {
        return this._super(record, json, relationship);
      }
    }
  });

});