define('admin/routes/admin/role/index', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "admin/role/index",
      controller: "admin/role/list",
      hasPager: true,
      hasFilter: true,
      hasNavigation: false
    }
  });

});