define('admin/models/account', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    firstName: DS['default'].attr("string"),
    lastName: DS['default'].attr("string"),
    email: DS['default'].attr("string"),
    city: DS['default'].attr("string"),
    zip: DS['default'].attr("string"),
    addresse: DS['default'].attr("string"),
    phone: DS['default'].attr("string"),
    fullName: (function () {
      var res = this.get("firstName");
      if (this.get("lastName")) {
        res += " ";
        res += this.get("lastName");
      }
      return res;
    }).property("firstName", "lastName"),
    company: DS['default'].belongsTo("company", { async: true })
  });

});