define('admin/views/list-filter', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    actions: {
      change: function change(filter) {
        filter.set("value", this.$().find("input[name=" + filter.name + "]").val());
      }
    }
  });

});