define('admin/components/field-cpicker', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    classNames: ["ui", "inline", "dropdown"],
    key: null,
    model: null,
    name: null,
    addEmpty: false,
    items: ["#660033", "#990033", "#CC0033", "#FF0033", "#000033", "#330099", "#0066FF", "#66CCFF", "#33CC00", "#99CC66", "#99CC33", "#669933", "#FFFF00", "#FFCC00", "#CC9933", "#FF9900"],

    didInsertElement: function didInsertElement() {
      this.$().dropdown("set selected", "<div id=\"" + this.get("model." + this.get("name")) + "\" style=\"background-color: " + this.get("model." + this.get("name")) + "; width: 20px; height:20px\"></div>");
      this.$().dropdown({
        //action: "combo",
        onChange: (function (value) {
          //, text, $choice
          var val = typeof $(value).attr("id") === "undefined" ? this.get("model." + this.get("name")) : $(value).attr("id");
          this.get("model").set(this.get("name"), val);
        }).bind(this) });
    }
  });

});