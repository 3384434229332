define('admin/locales/pl/config', ['exports'], function (exports) {

  'use strict';

  // Ember-I18n inclues configuration for common locales. Most users
  // can safely delete this file. Use it if you need to override behavior
  // for a locale or define behavior for a locale that Ember-I18n
  // doesn't know about.
  currency.addTier2Support();
  NumberFormat.PATTERN_GROUPING_SEPARATOR_ = " ";
  NumberFormat.locale.pl_PL = {
    DECIMAL_SEP: ".",
    GROUP_SEP: " ",
    PERCENT: "%",
    ZERO_DIGIT: "0",
    PLUS_SIGN: "+",
    MINUS_SIGN: "-",
    EXP_SYMBOL: "E",
    PERMILL: "‰",
    INFINITY: "∞",
    NAN: "NaN",
    DECIMAL_PATTERN: "#,##0.###",
    SCIENTIFIC_PATTERN: "#E0",
    PERCENT_PATTERN: "#,##0%",
    CURRENCY_PATTERN: "# ##0.00 ¤",
    CURRENCY_PATTERN2: "# ##0.00",
    DEF_CURRENCY_CODE: "PLN"
  };
  exports['default'] = {
    pluralForm: function pluralForm(count) {
      if (count === 0) {
        return "zero";
      } else if (count === 1) {
        return "one";
      } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return "few";
      } else {
        return "other";
      }
    }
  };

});