define('admin/routes/admin/systeminfo/mysql', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "admin/systeminfo/mysql",
      controller: "admin/systeminfo/mysql",
      hasPager: false,
      hasFilter: false,
      hasNavigation: true
    }
  });

});