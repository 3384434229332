define('admin/components/nav-menu-container', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    item: null,
    classNames: ["item", "dropdown", "ui"],
    didInsertElement: function didInsertElement() {
      if (!this.get("item.route")) {
        this.$().dropdown({
          transition: "slide down"
        });
      }
    },
    init: function init() {
      if (this.get("item.route")) {
        this.tagName = "a";
        this.attributeBindings = ["href"];
      }
      this._super();
      var action = this.get("item.action");
      if (!this.get("route") && action && typeof this.get("item")[action] === "function") {
        this.click = this.get("item")[action].bind(this.get("item"));
      }
    },
    href: (function () {
      if (this.get("item.param")) {
        return Admin.__container__.lookup("route:application").get("router").generate(this.get("item.route"), this.get("item.param"));
      } else {
        return Admin.__container__.lookup("route:application").get("router").generate(this.get("item.route"));
      }
    }).property("item.route")
  });

});