define('admin/initializers/i18n', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    ["component", "controller", "model", "route", "view"].forEach(function (type) {
      application.inject(type, "i18n", "service:i18n");
    });
    ["component", "controller", "model", "route", "view"].forEach(function (type) {
      application.inject(type, "cookie", "service:cookie");
    });
  }

  ;
  exports['default'] = {
    name: "i18n",
    initialize: initialize
  };

});