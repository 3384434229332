define('admin/routes/technika/import/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    templateName: "listfull",
    config: {
      into: "technika/import/index",
      controller: "technika/import/list" },
    renderTemplate: function renderTemplate() {
      this._super();
      //      (this.get('config'));
      var controller = this.controllerFor(this.get("config.controller"));
      controller.load();
      if (this.get("config.hasPager")) {
        this.render("pager", {
          outlet: "pager",
          into: this.get("config.into"),
          controller: controller
        });
      }

      this.render(this.get("config.controller"), {
        outlet: "list",
        into: this.get("config.into"),
        controller: controller
      });
    }
  });

});