define('admin/controllers/technika/import/list', ['exports', 'admin/controllers/edit', 'admin/utils/filter-element', 'admin/utils/accordion', 'jquery'], function (exports, Edit, FilterElement, Accoridion, jQuery) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "technika/import",
    fastFilter: false,
    deleted: [],

    init: function init() {
      this._super();
      this.set("operations", []);
    },
    load: function load() {
      this.set("isLoading", true);
      this.set("error", false);
      this.get("store").query(this.get("modelName"), {}).then((function (data) {
        data.toArray();
        this.set("model", data.toArray()); //data.content
        this.set("meta", data.meta);
        this.set("isLoading", false);
      }).bind(this), (function () {
        //(a,b,c,d);
        this.set("error", true);
        this.set("isLoading", false);
      }).bind(this));
    },
    save: function save(redirect, callback) {
      this._super(redirect, (function () {
        this.set("model", this.get("model"));
        this.set("isLoading", true);
        var arr = [];
        this.get("model.images").forEach((function (el) {
          if (!el.get("id")) {
            el.set("object", "\\Cyfra\\Technika\\StationBundle\\Model\\Station");
            el.set("objectId", this.get("model.id"));
            arr.push(el.get("_internalModel"));
          }
        }).bind(this));
        this.get("deleted").forEach(function (el) {
          arr.push(el.get("_internalModel"));
        });
        var ra = DS.RecordArray.create({ content: Ember.A(arr) });
        ra.save().then((function () {
          this.set("isLoading", false);
          this.set("deleted", []);
          this.load();
        }).bind(this), (function () {
          this.set("isLoading", false);
        }).bind(this));
      }).bind(this));
    },

    actions: {
      saveRec: function saveRec(params) {
        this.set("model", params.value);
        this.save();
      }
    }
  });

});