define('admin/tests/integration/components/ui-floatcharts-test', ['ember-qunit', 'htmlbars-inline-precompile'], function (ember_qunit, hbs) {

  'use strict';

  var _taggedTemplateLiteral = function (strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); };

  ember_qunit.moduleForComponent("ui-floatcharts", "Integration | Component | ui floatcharts", {
    integration: true
  });

  ember_qunit.test("it renders", function (assert) {
    assert.expect(2);

    // Set any properties with this.set('myProperty', 'value');
    // Handle any actions with this.on('myAction', function(val) { ... });

    this.render(hbs['default'](_taggedTemplateLiteral(["{{ui-floatcharts}}"], ["{{ui-floatcharts}}"])));

    assert.equal(this.$().text(), "");

    // Template block usage:
    this.render(hbs['default'](_taggedTemplateLiteral(["\n    {{#ui-floatcharts}}\n      template block text\n    {{/ui-floatcharts}}\n  "], ["\n    {{#ui-floatcharts}}\n      template block text\n    {{/ui-floatcharts}}\n  "])));

    assert.equal(this.$().text().trim(), "template block text");
  });

});