define('admin/views/pager', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].View.extend({
    pages: (function () {
      //(this.get('controller.meta'));
      var result = [];
      if (this.get("controller.meta.pages")) {
        result.push({
          page: this.get("controller.meta.page") - 1 <= 0 ? 1 : this.get("controller.meta.page") - 1,
          icon: "left arrow icon",
          disabled: this.get("controller.meta.page") <= 1 ? true : false
        });
        this.get("controller.meta.pages").forEach((function (el, i, pages) {
          if (i === 0 && el > 1) {
            result.push({
              page: 1,
              text: 1,
              disabled: false
            });
            if (el > 2) {
              result.push({
                page: 0,
                text: "...",
                disabled: true
              });
            }
          }
          result.push({
            page: el,
            text: el,
            disabled: el === this.get("controller.meta.page") ? true : false
          });
          if (pages.length - 1 === i && el < this.get("controller.meta.allPages")) {
            if (this.get("controller.meta.allPages") - 1 > el) {
              result.push({
                page: 0,
                text: "...",
                disabled: true
              });
            }
            result.push({
              page: this.get("controller.meta.allPages"),
              text: this.get("controller.meta.allPages"),
              disabled: false
            });
          }
        }).bind(this));
        result.push({
          page: this.get("controller.meta.page") + 1 > this.get("controller.meta.allPages") ? this.get("controller.meta.allPages") : this.get("controller.meta.page") + 1,
          icon: "right arrow icon",
          disabled: this.get("controller.meta.pages").length <= 0 || this.get("controller.meta.page") === this.get("controller.meta.allPages") ? true : false
        });
      }
      return result;
    }).property("controller.meta.pages"),
    nbResults: (function () {
      return this.get("controller.meta.nbResults");
    }).property("controller.meta.nbResults")
  });

});