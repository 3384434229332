define('admin/components/field-search-openlayers', ['exports', 'ember', 'jquery', 'admin/components/field-search'], function (exports, Ember, jQuery, fieldSearch) {

  'use strict';

  exports['default'] = fieldSearch['default'].extend({
    layoutName: "components/field-search",
    model: [],
    xhr: null,
    load: function load(term) {
      this.set("isLoading", true);
      if (this.get("xhr")) this.get("xhr").abort();
      this.set("xhr", $.ajax({
        url: "http://nominatim.openstreetmap.org/search/" + term + "?format=json&addressdetails=1&polygon_geojson=1",
        jsonpCallback: "openlayers",
        jsonp: "json_callback",
        contentType: "application/json",
        dataType: "jsonp",
        success: (function (data, textStatus, jqxhr) {
          this.set("xhr", null);
          this.set("model", []);
          this.set("model", data);
          this.$(".results").fadeIn();
          this.set("isLoading", false);
        }).bind(this)
      }));
    }
  });

});