define('admin/controllers/admin/user/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "user",
    init: function init() {
      this._super();
      this.get("filters").addObject(FilterElement['default'].create({
        name: "term",
        label: "app.filter.term"
      }));
      this.get("navigation").pushObject({
        route: "admin.user.index",
        text: "admin.user.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.user.new",
        text: "admin.user.navigation.new"
      });
    },
    actions: {
      activate: function activate(params) {

        var object = params.value;
        var message = params.param;
        this.set("isLoading", true);
        object.set("isActive", !object.get("isActive"));
        //object.set('securityRoles', object.get('securityRoles'));
        object.save().then((function () {
          this.set("isLoading", false);
          this.load();
        }).bind(this), (function (adapterError) {
          if (adapterError.errors) {
            this.get("application").error("app.error.form.save", adapterError.message);
          }
          this.set("isLoading", false);
        }).bind(this));
      } }
  });

});