define('admin/controllers/admin/group/edit', ['exports', 'admin/controllers/edit', 'jquery'], function (exports, Edit, jQuery) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "group",
    redirect: "admin.group.edit",
    securityrolesModalVisible: false,
    securityrolesController: Ember.inject.controller("admin/role/list"),
    securityRoleButtons: [{
      title: "app.select.continue",
      action: "addSecurityRoles"
    }, {
      title: "app.select.end",
      action: "addSecurityRolesAndClose"
    }],
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "admin.group.index",
        text: "admin.group.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.group.new",
        text: "admin.group.navigation.new"
      });
    },
    multiselect: function multiselect(view) {
      view.$().find("input[type=checkbox]:checked").each((function (i, el) {
        this.get("model.securityRoles").pushObject(view.get("controller.store").peekRecord("securityrole", jQuery['default'](el).val()));
      }).bind(this));
    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "admin.group.new.title";
      } else {
        return "admin.group.edit.title";
      }
    }).property("model.isNew"),
    actions: {
      addSecurityRoles: function addSecurityRoles() {
        var controller = this.get("securityrolesController");
        this.get("model.securityRoles").pushObjects(controller.get("multiselectCollection"));
      },
      addSecurityRolesAndClose: function addSecurityRolesAndClose() {
        var controller = this.get("securityrolesController");
        this.get("model.securityRoles").pushObjects(controller.get("multiselectCollection"));
        this.set("securityrolesModalVisible", false);
      },
      showSecurityrolesModal: function showSecurityrolesModal() {
        var controller = this.get("securityrolesController");
        controller.setMultiselect(true);
        //        controller.set('multiselectType', "company");
        //        controller.set('multiselectTarget', this);
        controller.load();
        this.set("securityrolesModalVisible", true);
      },
      deleteRole: function deleteRole(p1, role) {
        this.get("model.securityRoles").removeObject(role);
      }
    }
  });

});