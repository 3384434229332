define('admin/initializers/settings', ['exports', 'ember', 'jquery'], function (exports, Ember, jQuery) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    //application.deferReadiness();
    application.set("static", STATIC);
    application.set("server", SERVER);
    application.set("host", HOST);
    //application.set('static', "http://beta.api.hydrowskaz.pl");
    //application.set('server', "http://beta.api.hydrowskaz.pl/app_dev.php/api");
    //application.set('host', "http://beta.hydrowskaz.pl");
    //application.set('static', "http://api.hydrowskaz.acrux.net.pl/");
    //application.set('server', "http://api.hydrowskaz.acrux.net.pl/app_dev.php/api");
    //application.set('host', "http://hydrowskaz.acrux.net.pl");
    //application.set('static', "http://api.hydrowskaz2.acrux.net.pl/");
    //application.set('server', "http://api.hydrowskaz2.acrux.net.pl/app_dev.php/api");
    //application.set('host', "http://hydrowskaz2.acrux.net.pl");
    //application.set('static', "http://api.hydrowskaz3.acrux.net.pl/");
    //application.set('server', "http://api.hydrowskaz3.acrux.net.pl/app_dev.php/api");
    //application.set('host', "http://hydrowskaz3.acrux.net.pl");
    jQuery['default'].ajaxSetup({
      dataType: "json",
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true
    });
    Ember['default'].Route.reopen({
      application: application,
      activate: function activate() {
        this._super();
        //      if (application.get('systemInformation').get('technicalBreak')) {
        //        this.transitionTo('admin.systeminfo.break');
        //      }
        var routes = ["account.login", "account.register", "application", "account", "contact"];
        if (routes.indexOf(this.get("routeName")) === -1) {
          if (!this.get("application.authManager.isAuthenticated")) {
            this.transitionTo("account.login");
          }
        }
      },
      loginStatusChanged: (function () {
        if (this.get("authManager.isLoaded") === true) {
          var routes = ["account.login", "account.register", "application", "account", "technika.contact"];
          if (!this.get("authManager").get("isAuthenticated")) {
            this.transitionTo("account.login");
          }
        }
      }).observes("authManager.isLoaded")
    });
    Ember['default'].Controller.reopen({
      application: application,
      isLoading: false,
      formErrors: [],
      isAuthenticated: (function () {
        return this.get("application.authManager.isAuthenticated") ? true : false;
      }).property("application.authManager.isAuthenticated")
    });
    Ember['default'].Component.reopen({
      target: (function () {
        return this.get("targetObject");
      }).property("targetObject")
    });
    //  jQuery(window).on('resize', function () {
    //    var leftmenu = jQuery('.admin-main .admin-leftmenu');
    //    var win = jQuery(document).width() - 40 - leftmenu.outerWidth();
    //    jQuery('.admin-main .admin-content').width(win);
    //  });
    //  Ember.View.reopen({
    //    application: application,
    //    inserted: false,
    //    isLoading: function () {
    //      return this.get('controller.isLoading');
    //    }.property('controller.isLoading'),
    ////    didInsertElement: function () {
    ////      var result = this._super.apply(this, arguments);
    //////      if (this.$().find('.admin-main').length) {
    //////        var leftmenu = this.$().find('.admin-main .admin-leftmenu');
    //////        var win = jQuery(document).width() - 40 - leftmenu.outerWidth();
    //////        if (leftmenu.closest('.modal').length > 0) {
    //////         win = leftmenu.closest('.modal').outerWidth() - leftmenu.outerWidth() - 40;
    //////        }
    //////        this.$().find('.admin-main .admin-content').width(win);
    //////      }
    ////      return result;
    ////    },
    //    didInsertElement: function () {
    //      var result = this._super.apply(this, arguments);
    //
    ////      jQuery.find('.admin-main').forEach(function (i, element) {
    ////         var leftmenu = jQuery(element).children('.admin-leftmenu');
    ////         (leftmenu);
    ////      })
    ////        var leftmenu = jQuery.find('.admin-main .admin-leftmenu');
    ////        (jQuery.find('.admin-main .admin-leftmenu'));
    ////        var win = jQuery(document).width() - 40 - leftmenu.outerWidth();
    ////        jQuery.find('.admin-main .admin-content').width(win);
    ////        this.set('inserted', true);
    //      //}
    //      return result;
    //    },
    ////    init: function() {
    ////      this._super();
    ////
    ////      // bind attributes beginning with 'data-'
    ////      Ember.keys(this).forEach(function(key) {
    ////        if (key.substr(0, 5) === 'data-') {
    ////            console.info(key, this.get('attributeBindings'));
    ////            this.get('attributeBindings').pushObject(key);
    ////        }
    ////      }.bind(this));
    ////    }
    //  });
    //Ember.View.reopen(Ember.I18n.TranslateableAttributes);
  }

  exports['default'] = {
    name: "settings",
    initialize: initialize
  };

});