define('admin/utils/price', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    netFromGross: function netFromGross(gross, tax) {
      var taxValue = this.taxFromGross(gross, tax);
      return gross - taxValue;
    },
    grossFromNet: function grossFromNet(net, tax) {
      var taxValue = this.taxFromNet(net, tax);
      return net + taxValue;
    },
    taxFromNet: function taxFromNet(net, tax) {
      tax = tax / 100;
      var taxValue = net * tax;
      return taxValue;
    },
    taxFromGross: function taxFromGross(gross, tax) {
      tax = tax / 100;
      var taxValue = gross * tax / (1 + tax);
      return taxValue;
    }
  };

});