define('admin/models/company', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    licenseKey: DS['default'].attr("string"),
    continiuedName: DS['default'].attr("string"),
    isContractor: DS['default'].attr("boolean"),
    isOrganizer: DS['default'].attr("boolean"),
    isActive: DS['default'].attr("boolean"),
    fullName: (function () {
      var res = this.get("name");
      if (this.get("continiuedName")) {
        res += " ";
        res += this.get("continiuedName");
      }
      return res;
    }).property("name", "continiuedName"),
    users: DS['default'].hasMany("user", { async: false }),
    storehouses: DS['default'].hasMany("storehouse", { async: false }),
    stations: DS['default'].hasMany("technika/station", { async: false }),
    city: DS['default'].attr("string"),
    addresse: DS['default'].attr("string"),
    zip: DS['default'].attr("string"),
    phone: DS['default'].attr("string"),
    infoEmail: DS['default'].attr("string"),
    maxUsers: DS['default'].attr("string"),
    maxInstances: DS['default'].attr("string"),
    images: DS['default'].hasMany("file", { async: false }),
    activeUserNb: DS['default'].attr("number"),
    userNb: DS['default'].attr("number"),
    hasMaxUsers: (function () {
      //(this.get('userNb'),this.get('maxUsers'));
      if (this.get("activeUserNb") >= this.get("maxUsers")) {
        return true;
      } else {
        return false;
      }
    }).property("maxUsers", "userNb") });

});