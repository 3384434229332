define('admin/templates/components/ui-map', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.8",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 14
          }
        }
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("<div class=\"\" style=\"position: absolute; width: 350px; top: 8px; left: 45px; border: red 2px solod; z-index: 1000;\"> ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n{{#field-search-openlayers model=[] as |search|}}\n  {{#each search as |object|}}\n    <div class=\"result\">\n            <div class=\"content\" {{action \"mapOnObject\" object}}>\n              <div class=\"title\">{{object.display_name}}</div>\n              <div class=\"description\">\n                {{object.licence}}\n              </div>\n              <div class=\"price\">\n                {{object.lon}} {{object.lat}}\n              </div>\n            </div>\n    </div>\n  {{else}}\n    <h3 style=\"text-align: center;\">{{t \"cashbox.boxoffice.customer.notfound\"}}</h3>\n  {{/each}}\n{{/field-search-openlayers}}");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("</div> ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});