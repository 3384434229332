define('admin/controllers/account/login', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    url: (function () {
      return this.get("target").generate("account.register");
    }).property("target"),
    login: (function (_login) {
      var _loginWrapper = function login(_x, _x2) {
        return _login.apply(this, arguments);
      };

      _loginWrapper.toString = function () {
        return _login.toString();
      };

      return _loginWrapper;
    })(function (login, password) {
      this.get("amcharts").load();
      this.get("application.authManager").login(login, password).then((function () {
        this.get("application").message("app.login.success.title", "app.login.success");
        //this.transitionTo('technika.station.map');
        if (this.get("authManager").get("ROLE_TECHNIKA_STATION")) {
          this.get("target").replaceWith("/technika/station/service");
          this.get("target").transitionTo("technika.station.service");
        } else {
          this.get("target").replaceWith("/technika/station/map");
          this.get("target").transitionTo("technika.station.map");
        }
      }).bind(this), (function (response) {
        if (response.responseJSON) {
          this.get("application").error("app.login.failure", response.responseJSON.message);
        }
      }).bind(this));
    }),
    username: null,
    password: null,
    actions: {
      login: function login() {
        this.login(this.get("username"), this.get("password"));
      }
    }
  });

});