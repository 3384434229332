define('admin/models/shop/delivery', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    isOnline: DS['default'].attr("boolean"),
    locale: DS['default'].attr("string"),
    type: DS['default'].attr("string"),
    receiptName: DS['default'].attr("string"),
    displayType: (function () {
      var res = this.get("i18n").t("shop.delivery.types").findBy("key", this.get("type"));
      if (res) {
        return res.value;
      }
    }).property("name"),
    salegroups: DS['default'].hasMany("shop/salegroup/delivery", { async: false })
  });

});