define('admin/helpers/currency-sign', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.helperfunc = helperfunc;

  function helperfunc(currency_name) {
    if (currency_name) {
      return currency.getLocalCurrencySign(currency_name);
    }
  }

  exports['default'] = Ember['default'].Helper.helper(helperfunc);

});