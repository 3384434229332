define('admin/controllers/technika/station/list', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    modelName: "technika/station",
    fastFilter: false,
    init: function init() {
      this._super();
      if (this.get("authManager").get("ROLE_TECHNIKA_USER")) {
        this.get("filters").addObject(FilterElement['default'].create({
          name: "term",
          label: "app.filter.term"
        }));
      } else {
        this.get("filters").addObject(FilterElement['default'].create({
          name: "term",
          label: "app.filter.term"
        }));
        this.get("filters").addObject(FilterElement['default'].create({
          name: "companyName",
          label: "technika.station.filter.client"
        }));
      }
      /*
      this.get('navigation').pushObject({
        route: 'technika.station.index',
        text: "technika.station.navigation.list"
      });
      this.get('navigation').pushObject({
        route: 'technika.station.map',
        text: "technika.station.navigation.map"
      })
      */
      if (this.get("authManager").get("ROLE_OWNER")) {
        this.get("navigation").pushObject({
          route: "technika.station.new",
          text: "technika.station.navigation.new"
        });
      }
    }
  });

});