define('admin/models/language', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    locale: DS['default'].attr("string"),
    name: DS['default'].attr("string"),
    currency: DS['default'].attr("string"),
    language: DS['default'].attr("string"),
    isDefault: DS['default'].attr("boolean"),
    flag: (function () {
      return "flag " + this.get("language");
    }).property("locale")
  });

});