define('admin/routes/account/history', ['exports', 'admin/routes/list'], function (exports, List) {

  'use strict';

  exports['default'] = List['default'].extend({
    config: {
      into: "account/history",
      controller: "account/history",
      hasPager: false,
      hasFilter: false,
      hasNavigation: true
    }
  });

});