define('admin/components/form-tabs-tab', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "a",
    "data-tab": null,
    disabled: false,
    active: false,
    classNames: ["item", "tab-info"],
    classNameBindings: ["disabled", "active"],
    attributeBindings: ["data-tab"],
    onVisible: null,
    didRender: function didRender() {
      if (this.get("disabled") === false) {
        this.$().tab();
        if (this.get("onVisible")) {
          this.$().on("click", (function (event) {
            this.triggerAction({
              action: this.get("onVisible"),
              actionContext: { tab: this.$().data("tab") }
              //target: this
            });
          }).bind(this));
        }
      }
    },
    fields: null,
    errors: null,
    error: (function () {
      if (this._state === "inDOM") {
        //       (typeof this.get('fields'));
        //       (this.get('fields'));
        var error = false;
        if (typeof this.get("fields") === "string") {
          this.get("fields").split(",").forEach((function (field) {
            if (this.get("errors." + field)) {
              error = true;
            }
          }).bind(this));
        }
        if (error) {
          this.$().addClass("red");
        } else {
          this.$().removeClass("red");
        }
        return error;
      }
      return null;
    }).property("errors", "errors.length", "fields")
  });

});