define('admin/models/shop/salegroup', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    name: DS['default'].attr("string"),
    isOnline: DS['default'].attr("boolean"),
    isActive: DS['default'].attr("boolean"),
    isDefaultOnline: DS['default'].attr("boolean"),
    apiKey: DS['default'].attr("string"),
    companies: DS['default'].hasMany("company", { async: false }),
    payments: DS['default'].hasMany("shop/salegroup/payment", { async: false }),
    deliveries: DS['default'].hasMany("shop/salegroup/delivery", { async: false })
  });

});