define('admin/controllers/admin/company/edit', ['exports', 'admin/controllers/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    modelName: "company",
    redirect: "admin.company.edit",
    init: function init() {
      this._super();
      this.get("navigation").pushObject({
        route: "admin.company.index",
        text: "admin.company.navigation.list"
      });
      this.get("navigation").pushObject({
        route: "admin.company.new",
        text: "admin.company.navigation.new"
      });
    },
    deleted: [],
    save: function save(redirect, callback) {
      this._super(redirect, (function () {
        this.set("isLoading", true);
        var arr = [];
        this.get("model.images").forEach((function (el) {
          if (!el.get("id")) {
            el.set("object", "\\Cyfra\\UserBundle\\Model\\Company");
            el.set("objectId", this.get("model.id"));
            arr.push(el.get("_internalModel"));
          }
        }).bind(this));

        this.get("deleted").forEach(function (el) {
          arr.push(el.get("_internalModel"));
        });

        var ra = DS.RecordArray.create({ content: Ember.A(arr) });
        ra.save().then((function () {
          this.set("isLoading", false);
          this.set("deleted", []);
          if (typeof this.get("afterSave") !== "undefined") {
            var transit = this.get("afterSave");
            this.set("afterSave", undefined);
            this.transitionToRoute(transit);
          }
        }).bind(this), (function () {
          this.set("isLoading", false);
        }).bind(this));
      }).bind(this));
    },
    title: (function () {
      if (this.get("model.isNew")) {
        return "admin.company.new.title";
      } else {
        return "admin.company.edit.title";
      }
    }).property("model") });

  //    actions: {
  //      deleteRole: function (role) {
  //        this.get('model.securityRoles').removeObject(role);
  //      }
  //    }

});