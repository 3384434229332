define('admin/tests/unit/utils/filter-element-test', ['admin/utils/filter-element', 'qunit'], function (filterElement, qunit) {

  'use strict';

  qunit.module("filterElement");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = filterElement['default']();
    assert.ok(result);
  });

});