define('admin/components/field-remote-select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: "div",
    classNames: ["ui", "action", "input", "remote-select"],
    classNameBindings: ["disabled"],
    disabled: false,
    remoteModel: null,
    remoteDisplay: null,
    remoteKey: "id",
    remoteIcon: null,
    remoteFilter: [],
    model: null,
    valueAsObject: false,
    name: null,
    data: [],
    remoteDefault: null,
    action: null,
    currentValue: null,
    showRefresh: true,
    isLoading: true,
    displayText: null,
    value: null,
    text: (function () {
      if (this.get("displayText")) {
        return this.get("displayText");
      }
      if (!this.get("value")) {
        return this.get("i18n").t("app.select.continue");
      } else if (this.get("data").length > 0) {
        var ret = this.get("data").findBy("key", this.get("value"));
        if (ret) {
          return ret.value;
        } else {
          return this.get("i18n").t("app.select.continue");
        }
      }
    }).property("value", "data.length"),
    onModel: (function () {
      if (this.get("model") && this.get("name") in this.get("model")) {
        if (this.get("valueAsObject")) {
          if (typeof this.get("model." + this.get("name") + "." + this.get("remoteKey")) === "undefined") {
            this.$(".ui.dropdown").dropdown("set selected", 0);
          } else {
            this.set("value", this.get("model." + this.get("name") + "." + this.get("remoteKey")));
          }
        } else {
          this.set("value", this.get("model." + this.get("name")));
        }
        this.get("model").addObserver(this.get("name"), (function () {
          if (this.get("valueAsObject")) {
            this.set("value", this.get("model." + this.get("name") + "." + this.get("remoteKey")));
          } else {
            this.set("value", this.get("model." + this.get("name")));
          }
        }).bind(this));
      }
    }).observes("model"),
    willDestroyElement: function willDestroyElement() {
      if (this.get("model")) {
        this.get("model").removeObserver(this.get("name"));
      }
      this.$().remove();
    },

    init: function init() {
      this.load();
      this._super();
    },
    store: Ember['default'].inject.service(),
    _model: null,
    load: function load() {
      this.set("isLoading", true);
      return this.get("store").query(this.get("remoteModel"), this.get("remoteFilter")).then((function (model) {
        this.set("data", []);
        model.forEach((function (el) {
          this.get("data").pushObject({
            key: this.get("remoteKey") ? el.get(this.get("remoteKey")) : null,
            value: this.get("remoteDisplay") ? el.get(this.get("remoteDisplay")) : null,
            icon: this.get("remoteIcon") ? el.get(this.get("remoteIcon")) : null,
            isDefault: this.get("remoteDefault") ? el.get(this.get("remoteDefault")) : null
          });
        }).bind(this));
        this.set("isLoading", false);
      }).bind(this));
    },
    disabled: (function () {
      return this.get("readonly") == true ? "disabled" : "";
    }).property("readolny"),
    actions: {
      refresh: function refresh() {
        this.load();
      }
    },
    onValueChange: (function () {
      this.$(".ui.dropdown").dropdown("set selected", this.get("value"));
    }).observes("value", "_rendered"),
    _rendered: false,
    dataLoaded: (function () {
      this.set("_rendered", false);
      this.$(".ui.dropdown").dropdown({
        onChange: (function (value) {
          //, text, $choice
          if (value === this.get("value")) {
            return;
          }
          if (this.get("model")) {

            if (this.get("valueAsObject")) {
              this.get("model").set(this.get("name"), this.get("targetObject.store").peekRecord(this.get("remoteModel"), value));
              //(this.get('model').get(this.get('name')), this.get('model'));
            } else {
              this.get("model").set(this.get("name"), value);
            }
          }
          if (this.action) {
            this.triggerAction({
              action: this.get("action"),
              actionContext: value
              //target: this
            });
          }
        }).bind(this) });
      this.set("_rendered", true);
    }).observes("data.length")
  });

});