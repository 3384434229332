define('admin/routes/technika/sensor/stats', ['exports', 'admin/routes/list'], function (exports, List) {

    'use strict';

    exports['default'] = List['default'].extend({
        config: {
            into: "technika/sensor/stats",
            controller: "technika/sensor/stats",
            hasPager: true,
            hasFilter: true,
            hasNavigation: true,
            hasAccoridionNav: true
        },
        setupController: function setupController(controller, model) {
            var controller = this.controllerFor(this.get("config.controller"));
            controller.resetFilters();
        }
    });

});