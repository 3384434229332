define('admin/controllers/technika/stats/chart', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

  'use strict';

  exports['default'] = List['default'].extend({
    queryParams: ["stations"],
    stations: null,
    isLoading: false,
    charLoading: false,
    chart: null,
    table: null,
    sensors: null,
    init: function init() {
      this.set("24h", "");
      this.set("7d", "blue");
      this.set("14d", "");
      this.set("30d", "");
      this._super();
      this.get("amcharts").load();
      this.get("navigation").pushObject({
        route: "technika.station.map",
        text: "technika.station.navigation.map"
      });
      this.set("chartdata", []);
    },
    load: (function () {
      //if (this.get('amcharts.isLoaded')) {
      this.set("isLoading", true);
      this.loadHourlyData("isLoading", this.get("stations"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
        Ember.run("afterrender", function () {
          jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
          jQuery(".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
        });
        //var chart = this.get('amcharts').multiLines(data.minValue, data.maxValue, data.sensors, 'hh');
        //chart.dataProvider = data.data;
        this.set("table", data.data);
        this.set("sensors", data.sensors);
        //this.set('chart', chart);
        this.set("isLoading", false);
        //this.set('stations', this.get('stations'));
        this.set("chartdata", data.floatData);
        this.set("yaxis", data.yaxis);
      }).bind(this));
      //}
    }).observes("amcharts.isLoaded"),
    loadHourlyData: function loadHourlyData(isLoading, id, maxTime, minTime) {
      var timeFrom = moment(new Date(minTime.split(".").reverse().join("-")));
      var timeTo = moment(new Date(maxTime.split(".").reverse().join("-")));
      var days = timeTo.diff(timeFrom, "days");
      if (days > 365) {
        noty({
          layout: "topCenter",
          theme: "relax",
          text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Zakres pomiaru</div></h5> Różnica pomiędzy datami może wynosić maksymalnie 365 dni. Zmieniono zakres dat",
          type: "error",
          timeout: 5000
        });
        maxTime = timeTo.subtract(days - 365, "d").format("DD.MM.YYYY");
        jQuery(".dateTo").val(maxTime);
      }
      this.set("charLoading", true);
      return jQuery.ajax({
        url: this.get("application").get("server") + "/technika/sensor/" + id + "/data/hourly.json",
        data: {
          from: minTime,
          to: maxTime
        },
        complete: (function () {
          this.set(isLoading, false);
          this.set("charLoading", false);
        }).bind(this)
      });
    },
    actions: {
      goBack: function goBack() {
        window.history.back();
      },
      stats24Hours: function stats24Hours(chart, dataLoading) {
        this.set("chartdata", []);
        jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery(".dateFrom").val(moment().subtract(24, "hours").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("stations"), moment().format(), moment().subtract(24, "hours").format()).then((function (data) {
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          this.set("24h", "blue");
          this.set("7d", "");
          this.set("14d", "");
          this.set("30d", "");
          this.set("365d", "");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
          this.set("yaxis", data.yaxis);
        }).bind(this));
      },
      stats7Days: function stats7Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery(".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("stations"), moment().format(), moment().subtract(7, "days").format()).then((function (data) {
          this.set("24h", "");
          this.set("7d", "blue");
          this.set("14d", "");
          this.set("30d", "");
          this.set("365d", "");
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
          this.set("yaxis", data.yaxis);
        }).bind(this));
      },
      stats30Days: function stats30Days(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
        jQuery(".dateFrom").val(moment().subtract(30, "days").format("DD.MM.YYYY"));
        this.loadHourlyData(dataLoading, this.get("stations"), moment().format(), moment().subtract(30, "days").format()).then((function (data) {
          this.set("24h", "");
          this.set("7d", "");
          this.set("14d", "");
          this.set("30d", "blue");
          this.set("365d", "");
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
          this.set("yaxis", data.yaxis);
        }).bind(this));
      },
      statsCustom: function statsCustom(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        var dateTo = jQuery(".dateTo").val();
        var dateFrom = jQuery(".dateFrom").val();
        this.loadHourlyData(dataLoading, this.get("stations"), dateTo, dateFrom).then((function (data) {
          var chart = this.get("amcharts").multiLines(data.minValue, data.maxValue, data.sensors, "hh");
          this.set("24h", "");
          this.set("7d", "");
          this.set("14d", "");
          this.set("30d", "");
          this.set("365d", "");
          chart.dataProvider = data.data;
          this.set("table", data.data);
          this.set("sensors", data.sensors);
          this.set("chart", chart);
          this.set("isLoading", false);
          this.set("stations", this.get("stations"));
          this.set("chartdata", data.floatData);
          this.set("yaxis", data.yaxis);
        }).bind(this));
      },
      statsCustomCSVzip: function statsCustomCSVzip(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        var dateTo = jQuery(".dateTo").val();
        var dateFrom = jQuery(".dateFrom").val();
        window.open(this.get("application").get("server") + "/technika/senesor/export/" + dateFrom + "/" + dateTo + "/" + this.get("stations") + ".html");
      },
      statsCustomCSVtable: function statsCustomCSVtable(chart, dataLoading) {
        this.set("chartdata", []);
        this.set("table", []);
        var dateTo = jQuery(".dateTo").val();
        var dateFrom = jQuery(".dateFrom").val();
        window.open(this.get("application").get("server") + "/technika/senesor/csv/" + dateFrom + "/" + dateTo + "/" + this.get("stations") + ".html");
      }
    }
  });

});