define('admin/routes/technika/station/new', ['exports', 'admin/routes/new'], function (exports, New) {

  'use strict';

  exports['default'] = New['default'].extend({
    config: {
      into: "technika/station/new",
      controller: "technika/station/edit" } });

  //    renderTemplate: function() {
  //      this._super();
  //      var controller = this.controllerFor('admin/company/list');
  ////      controller.setSingleselect(true);
  ////      controller.set('singleselectType', "company");
  ////      controller.set('singleselectTarget', this.controllerFor(this.get('config.controller')));
  ////      controller.load();
  //      this.render('admin/company/list', {
  //        into: 'technika/station/edit',
  //        outlet: "stationcompany",
  //        controller: controller
  //      });
  //    }

});