define('admin/models/securityrole', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    description: DS['default'].attr("string"),
    displayName: DS['default'].attr("string"),
    isActive: DS['default'].attr("boolean"),
    users: DS['default'].hasMany("user", { async: false })
  });

});