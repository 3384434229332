define('admin/tests/unit/initializers/system-information-test', ['ember', 'admin/initializers/system-information', 'qunit'], function (Ember, system_information, qunit) {

  'use strict';

  var container, application;

  qunit.module("SystemInformationInitializer", {
    beforeEach: function beforeEach() {
      Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        container = application.__container__;
        application.deferReadiness();
      });
    }
  });

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    system_information.initialize(container, application);

    // you would normally confirm the results of the initializer here
    assert.ok(true);
  });

});