define('admin/tests/unit/mixins/serializable-test', ['ember', 'admin/mixins/serializable', 'qunit'], function (Ember, SerializableMixin, qunit) {

  'use strict';

  qunit.module("SerializableMixin");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var SerializableObject = Ember['default'].Object.extend(SerializableMixin['default']);
    var subject = SerializableObject.create();
    assert.ok(subject);
  });

});