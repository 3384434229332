define('admin/controllers/technika/station/export', ['exports', 'admin/controllers/list', 'admin/utils/filter-element'], function (exports, List, FilterElement) {

   'use strict';



   exports['default'] = List['default'].extend({
      modelName: "technika/station",
      multiselect: true,
      selectedStations: [],
      init: function init() {
         this._super();
         this.set("24h", "");
         this.set("7d", "blue");
         this.set("14d", "");
         this.set("30d", "");
         this.set("365d", "");
         if (this.get("authManager").get("ROLE_TECHNIKA_USER")) {
            this.get("filters").addObject(FilterElement['default'].create({
               name: "term",
               label: "app.filter.term"
            }));
         } else {
            this.get("filters").addObject(FilterElement['default'].create({
               name: "term",
               label: "app.filter.term"
            }));
            this.get("filters").addObject(FilterElement['default'].create({
               name: "companyName",
               label: "technika.station.filter.client"
            }));
         }
         this.get("filters").addObject(FilterElement['default'].create({
            name: "isAvail",
            defaultValue: 1,
            type: "hidden"
         }));
      },
      onModel: (function () {
         Ember.run("afterrender", function () {
            jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
            jQuery(".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
         });
      }).observes("model"),
      actions: {
         addStation: function addStation(sensor) {
            var toAdd = this.get("selectedStations").findBy("id", sensor.get("id"));
            if (Ember.isEmpty(toAdd)) {
               this.get("selectedStations").pushObject(sensor);
            }
         },
         removeStation: function removeStation(sensor) {
            var toRemove = this.get("selectedStations").findBy("id", sensor.get("id"));
            this.get("selectedStations").removeObject(toRemove);
         },
         export24Hours: function export24Hours(chart, dataLoading) {
            jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
            jQuery(".dateFrom").val(moment().subtract(24, "hours").format("DD.MM.YYYY"));
         },
         export7Days: function export7Days(chart, dataLoading) {
            ;
            jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
            jQuery(".dateFrom").val(moment().subtract(7, "days").format("DD.MM.YYYY"));
         },
         export30Days: function export30Days(chart, dataLoading) {
            ;
            jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
            jQuery(".dateFrom").val(moment().subtract(30, "days").format("DD.MM.YYYY"));
         },
         export365Days: function export365Days(chart, dataLoading) {
            ;
            jQuery(".dateTo").val(moment().format("DD.MM.YYYY"));
            jQuery(".dateFrom").val(moment().subtract(365, "days").format("DD.MM.YYYY"));
         },
         exportCustom: function exportCustom() {
            var dateTo = jQuery(".dateTo").val();
            var dateFrom = jQuery(".dateFrom").val();
            if (this.get("selectedStations").length == 0) {
               noty({
                  layout: "topCenter",
                  theme: "relax",
                  text: "<h5 style=\"margin: 0px;\" class=\"ui header\"><i class=\"info circle icon\"></i><div class=\"content\">Formularz kontaktowy</div></h5> Wybierz przynajmniej jeden czujnik",
                  type: "error",
                  timeout: 5000
               });
               return false;
            }
            var selectedStations = [];
            this.get("selectedStations").forEach(function (item) {
               selectedStations.push(item.get("id"));
            });
            window.open(this.get("application").get("server") + "/technika/stations/export.html?dateFrom=" + dateFrom + "&dateTo=" + dateTo + "&id=" + selectedStations);
            //this.set('checkedSensors',[])
         } }
   });

});