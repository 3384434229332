define('admin/routes/account/password', ['exports', 'admin/routes/edit'], function (exports, Edit) {

  'use strict';

  exports['default'] = Edit['default'].extend({
    config: {
      into: "account/password",
      controller: "account/password" }
  });

});