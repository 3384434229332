define('admin/routes/technika/raport/aggregate', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        templateName: "raport_print",
        config: {
            into: "technika/raport/aggregate",
            controller: "technika/raport/aggregate",
            hasPager: true,
            hasFilter: true,
            hasNavigation: true,
            hasAccordionNavigation: true
        },
        renderTemplate: function renderTemplate() {
            this._super();
            //      (this.get('config'));
            var controller = this.controllerFor(this.get("config.controller"));
            //controller.load();
            this.render(this.get("config.controller"), {
                outlet: "raport",
                into: this.get("config.into"),
                controller: controller
            });
        }
    });

});